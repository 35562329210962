import React, { Component, createRef } from 'react';
import NotificationSystem from 'react-notification-system';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { IS_WEB_APP, RECAPTCHA_MOBILE_APP_CODE } from '../../constants/constants';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Spinner from '../layout/Spinner';
import FormGroup from '../layout/FormGroup';
import TitleContainer from '../layout/TitleContainer';
import { reportIssue, getStore } from '../../api';
import classes from './ReportIssue.module.css';
import { UPDATE_STORE } from '../../actions';

class ReportIssue extends Component {
  notificationSystem = createRef();
  state = {
    store_id: '',
    area: '',
    comments: '',
    greyed: false,
    loading: true,
    store_data: {},
  };

  async componentDidMount() {
    const { store_id } = this.props;
    const searchObj = queryString.parse(this.props.location.search);
    let storeID = -1;
    
    if( store_id > -1 ) {
      this.setState({
        greyed: true,
        store_id
      });
      storeID = store_id;
    } else if ( store_id === -1 && searchObj.store_id !== undefined ) {
      this.setState({
        greyed: true,
        store_id: searchObj.store_id
      });
      storeID = searchObj.store_id;
    }
    // Get Store Details
    if( storeID > -1 ) {
      const res = await getStore({ store_id: storeID });
      this.setState({ store_data: res.data.store });
    }

    this.setState({ loading: false });
  }

  componentWillUnmount() {
    this.props.updateStore(-1);
  }

  clearInputs = () => {
    this.setState({ 
      store_id: '',
      area: '',
      comments: '',
    });
  }

  handleSubmit = async e => {
    e.preventDefault();
    const { store_id, area, comments } = this.state;

    var recaptcha = RECAPTCHA_MOBILE_APP_CODE;
    if( IS_WEB_APP ) {
      recaptcha = await this.props.googleReCaptchaProps.executeRecaptcha('login');
    }
    const res = await reportIssue( store_id, area, comments, recaptcha );
    if( res.data.success && !res.data.error ){
      this.clearInputs();
      this.addNotification(
        'Successfully Submitted',
        res.data.msg,
        'success'
      );
    } else {
      this.addNotification(
        'An Error Occurred',
        res.data.msg ? res.data.msg : 'Could Not Submit',
        'error'
      );
    }
  }

  addNotification = (title, message, type) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title,
      message,
      level: type,
      position: 'tc',
      autoDismiss: 3,
    })
  }

  handleChange = e => this.setState({ [e.target.name] : e.target.value });

  render() {
    const { store_id, area, comments, greyed, loading, store_data } = this.state;

    if( loading ) return <Spinner />;

    return (
      <div className="container padded">
        <NotificationSystem ref={this.notificationSystem} />
        <TitleContainer title="Request Store" />
        <button className="bt-back" onClick={() => this.props.history.goBack()}><i className="fa fa-angle-left"></i> Back</button>
        <div className={classes.ReqStore}>
          <div className={classes.ModalHeader}>
            <h4>Report a Mistake ( {store_data.name} )</h4>
          </div>
          <div className={classes.ModalBody}>
            <form id="report issue">
              {!greyed &&
              <FormGroup name="store_id" label="Store ID" type="number" placeholder="" required={true} value={store_id} onchange={this.handleChange} />}
              <FormGroup name="area" label="Section" placeholder="" required={true} value={area} onchange={this.handleChange} />
              <FormGroup name="comments" label="Comments" input={false} placeholder="" required={true} value={comments} onchange={this.handleChange} />
            </form>
          </div>
          <div className={classes.ModalFooter}>
            <button onClick={() => this.props.history.goBack()}>Cancel</button>
            <button onClick={this.handleSubmit} form="report-issue">Submit</button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  store_id: state.reportMistakeReducer.store_id
});

const mapDispatchToProps = dispatch => ({
  updateStore: (store_id) => dispatch({ type: UPDATE_STORE, store_id })
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withGoogleReCaptcha(ReportIssue)));