import {
  ADD_INPUT,
  CLEAR_INPUT,
  STORE_KEYWORDS,
  SET_FLOOR_COUNT,
  FORM_SUBMITTED,
  CLEAR_STORE,
} from '../actions';

const initialState = {
  keywords: [],
  text: '',
  submitFlag: false,
  floors: -1,
}

const storeReducer = (state = initialState, action) => {
  switch(action.type){
    case ADD_INPUT:
      return { ...state, text: action.payload }
    case CLEAR_INPUT:
      return { ...state, text: '' }
    case STORE_KEYWORDS:
      return { ...state, keywords: action.payload };
    case FORM_SUBMITTED:
      return { ...state, submitFlag: !state.submitFlag };
    case SET_FLOOR_COUNT:
      return { ...state, floors: action.payload };
    case CLEAR_STORE:
      return {
        ...state,
        keywords: [],
        text: '',
        submitFlag: false,
        floors: -1
      }
    default:
      return state;
  }
};

export default storeReducer;

