import React from 'react';
import TextContainer from '../layout/TextContainer';
import ldOverview1 from '../../img/ld_overview_1.png';
import ldOverview2 from '../../img/ld_overview_2.png';
import ldOverview3 from '../../img/ld_overview_3.png';
import classes from './style/Landing.module.css';

const Overview = () => {
  return (
    <section className={classes.Overview}>
      <div className={`ld-container ${classes.OverviewContainer}`}>
        <TextContainer 
          header="Track your grocery trips"
          content="Create, manage, and save different lists for different types of shopping trips"
        />
        <div>
          <img src={ldOverview1} alt="App Overview"/>
        </div>
      </div>
      <div className={`ld-container ${classes.OverviewContainer}`}>
        <div className={classes.MobileOrder}>
          <img src={ldOverview2} alt="App Overview"/>
        </div>
        <TextContainer
          header="Map your items"
          content="Use our hand-crafted indoor maps for more than 100 stores to find your 
                  grocery items even before stepping into the stores"
        />
      </div>
      <div className={`ld-container ${classes.OverviewContainer}`}>
        <TextContainer 
          header="Stay on top of all deals and sales"
          content="We're constantly curating the latest sales at your favorite so you can
                  always count on saving more on your grocery runs."
        />
        <div>
          <img src={ldOverview3} alt="App Overview"/>
        </div>
      </div>
    </section>
  )
}

export default Overview;