import { 
  SHOW_REPORT_MISTAKE,
  HIDE_REPORT_MISTAKE,
  UPDATE_STORE
} from '../actions';

const initialState = {
  show: false,
  store_id: -1
}

const reportMistakeReducer = ( state = initialState, action ) => {
  switch( action.type ) {
    case SHOW_REPORT_MISTAKE:
      return { ...state, show: true };
    case HIDE_REPORT_MISTAKE:
      return { ...state, show: false };
    case UPDATE_STORE:
      return { ...state, store_id: action.store_id };
    default:
      return state;
  }
}

export default reportMistakeReducer;