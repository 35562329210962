import {
  ADD_ITEM,
  REMOVE_ITEM,
  SET_COMPLETED,
  CLEAR_LIST,
  RESET_REMOVE_INDEX,
  SET_FOCUS,
  LOAD_SHOPPING_LIST,
} from '../actions';

const initialState = {
  shopping_list: [],
  loaded_shopping_list: [],
  removeIndex: -1,
  focusIndex: -1,
}

const shopListReducer = (state = initialState, action) => {
  const { type, id, payload } = action;
  switch(type){
    case ADD_ITEM:
      return {
        ...state,
        shopping_list: [...state.shopping_list, payload]
      }
    case REMOVE_ITEM:
      return {
        ...state,
        shopping_list: state.shopping_list.filter((_, index) => index !== id),
        removeIndex: id
      }
    case CLEAR_LIST:
      return { ...state, shopping_list: [] }
    case SET_COMPLETED:
      let array = [...state.shopping_list]
      let completed = array[id].completed;
      array[id].completed = !completed;
      return {
        ...state,
        shopping_list: array
      }
    case RESET_REMOVE_INDEX:
      return { ...state, removeIndex: -1 };
    case SET_FOCUS:
      return { ...state, focusIndex: payload }
    case LOAD_SHOPPING_LIST:
      return { ...state, loaded_shopping_list: payload }
    default:
      return state
  }
}

export default shopListReducer;