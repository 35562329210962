import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TripsDisplay from './TripsDisplay';
import TripsControls from './TripsControls';
import TitleContainer from '../layout/TitleContainer';
import classes from './style/CurrentTrips.module.css';

const CurrentTrips = ({ trips, removeTrip, onclick, displayName, onchange, showEdit }) => {
  const renderTrips = () => {
    if(trips.length === 0 ){
      return (
        <div className={classes.NoTrips}>
          <p><em>You do not have any trips created yet.</em></p>
          <p><em>Create a trip by adding a store.</em></p>
        </div>
      )
    } else {
      return (
        <Fragment>
          <TripsControls onclick={onclick} trip_name={displayName} onchange={onchange} showEdit={showEdit} />
          <TripsDisplay trips={trips} removeTrip={removeTrip} onclick={onclick}/>
        </Fragment>
      )
    }
  }
  return (
    <div className={classes.CurrentTrips}>
      <TitleContainer title="Current Trips" />
      <div className={classes.TripsContainer}>
        {renderTrips()}
      </div>
    </div>
  )
}

CurrentTrips.propTypes = {
  trips: PropTypes.array.isRequired,
  removeTrip: PropTypes.func,
  onclick: PropTypes.func,
}

export default CurrentTrips;
