import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../layout/Spinner';
import NoMatchPage from '../no-match/NoMatchPage';
import RenderStores from './RenderStores';
import TitleContainer from '../layout/TitleContainer';
import classes from './style/FilterByStoreLocation.module.css';
import { getStoreList, getBrandsList } from '../../api';
import { convertToURLFormat } from '../../utils/helpers';
import { STORE_DATA, STORE_BRANDS } from '../../actions';

const FilterByStoreLocation = ({ match, urlParam }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const imp_data = useSelector(state => state.dataReducer.data);
  const imp_brands = useSelector(state => state.dataReducer.brands);

  const [ locObj, setLocation ] = useState({});
  const [ redirect, setRedirect ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ title, setTitle ] = useState('');
  const store_name = match.params.name;
  const local_trips = JSON.parse(localStorage.getItem('trips'));

  useEffect(() => {
    const fetchData = async () => {
      let name = '';
      let data = [];
      let brandsData = {};

      if(imp_data.length === 0){
        const res = await getStoreList();
        data = res.data;
        dispatch({ type: STORE_DATA, data });
      } else { data = imp_data; }

      if(Object.keys(imp_brands).length === 0){
        const res_brands = await getBrandsList();
        brandsData = res_brands.data;
        dispatch({ type: STORE_BRANDS, payload: brandsData });
      } else { brandsData = imp_brands; }
      
      data.forEach(store => {
        if(store.brand_id !== null && store.city !== "" && brandsData[store.brand_id]){
          // get the modified store name (removes whitespace and special characters)
          let modifiedName = convertToURLFormat(brandsData[store.brand_id].name);
          if(store_name === modifiedName) {
            // Set Name of Store in header
            if(title === '') setTitle(brandsData[store.brand_id].name);
            name = brandsData[store.brand_id].name;
            // Get the store object with all details of store
            storeObjects(store, brandsData);
          }
        }
      });
      if( name.length === 0) setRedirect(true);
      const sortedLocation = sortObjects(locObj);
      setLocation(sortedLocation);
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Method that creates object that contain stores filtered by location
  const storeObjects = (store, brandsData) => {
    const item = {
      id: store.store_id,
      city: store.city,
      country: store.country,
      name: store.name,
      postal_code: store.postal_code,
      brand_name: brandsData[store.brand_id].name,
      brand_id: store.brand_id,
      address: store.street_address,
      province: getProvince(store.province),
      trip_id: null,
    }

    if( local_trips !== null ) {
      local_trips.forEach(trip => {
        // if store id exist in local storage
        if(trip.store_id === item.id) item.trip_id = trip.trip_id;
      });
    }

    if(locObj[store.city]) {
      locObj[store.city].push(item);
    } else {
      locObj[store.city] = [item];
    }
  };

  // Method that converts Province Name to province Abbrevitations
  const getProvince = (prov) => {
    const province = {
      'Alberta' : 'AB',
      'British Columbia': 'BC',
      'Manitoba': 'MB',
      'New Brunswick': 'NB',
      'Newfoundland and Labrador' : 'NL',
      'Northwest Territories': 'NT',
      'Nova Scotia' : 'NS',
      'Nunavut': 'NU',
      'Ontario' : 'ON',
      'Prince Edward Island' : 'PE',
      'Quebec' : 'QC',
      'Saskatchewan' : 'SK',
      'Yukon' : 'YT',
    }
    return province[prov];
  }

  // Method for sorting the objects in alphabetical order
  const sortObjects = (obj) => {
    let newObj = {};
    Object.keys(obj).sort().forEach(store => {
      newObj = {
        ...newObj,
        [store]: obj[store]
      }
    });
    return newObj;
  }

  if( loading ) return <Spinner />;
  if(redirect) return <NoMatchPage />;

  return (
    <div className="container padded">
      <TitleContainer title={title} />
      <button className="bt-back" onClick={() => history.goBack()}><i className="fa fa-angle-left"></i> Back</button>
      {/* <Link to={urlParam === 'stores' ? "/stores/brands" : "/sales"} className="bt-back"><i className="fa fa-angle-left"></i> Back</Link> */}
      <div className={classes.FilterStore}>
        <RenderStores location={locObj} store_name={store_name} urlParam={urlParam} />
      </div>
    </div>
  )
}

FilterByStoreLocation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      store_name: PropTypes.string,
    }).isRequired,
  }).isRequired,
  urlParam: PropTypes.string.isRequired,
}

export default FilterByStoreLocation;
