import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SET_REDIRECT } from '../../../actions';
import classes from './style/MyListLogin.module.css';

const MyListLogin = () => {
  const dispatch = useDispatch();
  return (
    <div className={classes.MyList}>
      <p className={classes.LoginList}><em>You need to log in to see your list</em></p>
      <Link to="/login" onClick={() => dispatch({ type: SET_REDIRECT, payload: '/explore' })}>Log In</Link>
    </div>
  )
}

export default MyListLogin;