import React from 'react';
import PropTypes from 'prop-types';
import classes from './style/FormGroup.module.css';

const FormGroup = ({ name, type="text", label, placeholder="", disabled=false, input, required, value, onchange, children, rest }) => {
  return (
    <div className={classes.FormGroup}>
      <label htmlFor={name}>{label}{required ? <span> *</span> : ''}</label>
      
      {input && 
      <input 
        className={classes.FormControl} 
        type={type} 
        name={name}
        value={value}
        placeholder={placeholder}
        required={required}
        onChange={onchange}
        disabled={disabled}
        autoComplete="off"
        {...rest}
      />}

      {!input && 
      <textarea 
        className={classes.FormControl} 
        rows="3" 
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onchange}
        disabled={disabled}
        autoComplete="off"
      >
      </textarea>}

      {children}
    </div>
  )
}

FormGroup.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  input: PropTypes.bool,
  label: PropTypes.string.isRequired,
}

FormGroup.defaultProps = {
  input: true
}

export default FormGroup
