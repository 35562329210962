import axios from 'axios';
import { API_KEY, API_URL, API_VERSION} from '../constants/constants';

export const getStoreList = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: API_URL + `/platform/api/store/list.php`,
      params: {
        apikey: API_KEY,
        apiversion: API_VERSION
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return res;
  } catch(error) {
    return error.response;
  }
}

export const getStore = async ({ store_id, language='en' }) => {
  try {
    const res = await axios({
      method: 'GET',
      url: API_URL + `/platform/api/store/data.php`,
      params: {
        apikey: API_KEY,
        apiversion: API_VERSION,
        language,
        store_id
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return res;
  } catch(error) {
    console.log(error);
  }
}

export const getBrandsList = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: API_URL + '/platform/api/store/brand-list.php',
      params: {
        apikey: API_KEY,
        apiversion: API_VERSION
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return res;
  } catch (err) {
    console.log(err);
  }
}



export const getLatitude = async ({ postal_code }) => {
  try {
    const res = await axios({
      method: 'GET',
      url: API_URL + '/platform/api/postal-code-to-coordinates.php',
      params: {
        postal_code,
        apikey: API_KEY,
        apiversion: API_VERSION
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return res;
  } catch (error) {
    console.log(error);
  }
}

export const requestStore = async ( store, city, province, address, comments, recaptcha ) => {
  try {
    const res = await axios({
      method: 'POST',
      url: API_URL + '/platform/api/store/request.php',
      params: {
        apikey: API_KEY,
        apiversion: API_VERSION,
        store,
        city,
        province,
        address,
        comments,
        recaptcha
      }
    });
    return res;
  } catch (error) {
    console.log(error);
  }
}

export const reportIssue = async ( store_id, area, comments, recaptcha ) => {
  try {
    const res = await axios({
      method: 'POST',
      url: API_URL + '/platform/api/store/report-mistake.php',
      params: {
        apikey: API_KEY,
        apiversion: API_VERSION,
        store_id,
        area,
        comments,
        recaptcha,
      }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

export const applyMapper = async ({ name, email, dob, phone, postal_code, areas_covered, recaptcha }) => {
  try {
    const res = await axios({
      method: 'POST',
      url: API_URL + '/platform/api/mapper-application.php',
      params: {
        apikey: API_KEY,
        apiversion: API_VERSION,
        name,
        email,
        dob,
        phone,
        postal_code,
        areas_covered,
        recaptcha
      }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

// ###############################
// # Authentication
// ###############################

export const createUser = async ({ first_name, last_name, username, email, password, recaptcha }) => {
  try {
    const res = await axios({
      method: 'POST',
      url: API_URL + '/platform/api/user/register.php',
      params: {
        apikey: API_KEY,
        apiversion: API_VERSION,
        first_name,
        last_name,
        email,
        username,
        password,
        recaptcha,
      },
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

export const loginUser = async ({ email, password, recaptcha }) => {
  try {
    const res = await axios({
      method: 'GET',
      url: API_URL + '/platform/api/user/login.php',
      params: {
        apikey: API_KEY,
        email,
        password,
        recaptcha
      }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

export const checkLoggedIn = async (session_id='') => {
  try {
    const res = await axios({
      method: 'GET',
      url: API_URL + '/platform/api/user/login.php',
      params: {
        apikey: API_KEY,
        session_id,
      }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

export const logOutUser = async (session_id='') => {
  try {
    const res = await axios({
      method: 'GET',
      url: API_URL + '/platform/api/user/logout.php',
      params: {
        apikey: API_KEY,
        session_id
      }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

export const forgetPassword = async ({ email, recaptcha }) => {
  try {
    const res = await axios({
      method: 'GET',
      url: API_URL + '/platform/api/user/forgot-password.php',
      params: {
        apikey: API_KEY,
        email,
        recaptcha
      }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

export const resetPassword = async ({ password, token, recaptcha, session_id }) => {
  try {
    const res = await axios({
      method: 'POST',
      url: API_URL + '/platform/api/user/reset-password.php',
      params: {
        apikey: API_KEY,
        apiversion: API_VERSION,
        password,
        token,
        recaptcha,
        session_id,
      }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

export const changePassword = async ({ old_password, new_password, appuser_id, recaptcha, session_id }) => {
  try {
    const res = await axios({
      method: 'POST',
      url: API_URL + '/platform/api/user/change-password.php',
      params: {
        apikey: API_KEY,
        apiversion: API_VERSION,
        old_password,
        new_password,
        appuser_id,
        recaptcha,
        session_id,
      }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

// ###############################
// # LIST
// ###############################
export const getKeywords = async (session_id) => {
  try {
    const res = await axios ({
      method: 'GET',
      url: API_URL + '/platform/api/list/keywords.php',
      params: {
        apikey: API_KEY,
        apiversion: API_VERSION,
        session_id
      }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

export const createRecipe = async ( project, recaptcha, session_id='' ) => {
  try {
    const res = await axios({
      method: 'POST',
      url: API_URL + '/platform/api/list/edit.php',
      data: {
        list_id : project.list_id,
        type_id: project.type_id,
        title: project.title,
        description: project.description,
        servings: project.servings,
        prep_time: project.prep_time,
        ingredients: project.ingredients,
        instructions: project.instructions,
        images: project.images,
        videos: project.videos,
        website_url: project.website_url,
        is_public: project.is_public
      },
      params: {
        apikey: API_KEY,
        apiversion: API_VERSION,
        recaptcha,
        session_id,
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        document.querySelector('#progress-text').innerHTML = `${percentCompleted} %`;
        document.querySelector('#progress-bar').style.width = `${percentCompleted}%`
      }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

export const getProjectLists = async ({ mode, offset = -1, limit = -1, session_id='' }) => {
  try {
    const res = await axios({
      method: 'GET',
      url: API_URL + '/platform/api/list/list.php',
      params: {
        apikey: API_KEY,
        apiversion: API_VERSION,
        mode,
        offset,
        limit,
        session_id
      }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

export const getProjectData = async ( list_id, session_id='' ) => {
  try {
    const res = await axios({
      method: 'GET',
      url: API_URL + '/platform/api/list/data.php',
      params: {
        apikey: API_KEY,
        apiversion: API_VERSION,
        list_id,
        session_id,
      }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

export const deleteProject = async ( list_id, session_id='' ) => {
  try {
    const res = await axios({
      method: 'GET',
      url: API_URL + '/platform/api/list/delete.php',
      params: {
        apikey: API_KEY,
        apiversion: API_VERSION,
        list_id,
        session_id
      }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

export const saveProject = async ( action, list_id, session_id='' ) => {
  try {
    const res = await axios({
      method: 'GET',
      url: API_URL + '/platform/api/list/save.php',
      params: {
        apikey: API_KEY,
        apiversion: API_VERSION,
        action,
        list_id,
        session_id,
      }
    })
    return res;
  } catch (error) {
    return error.response;
  }
}

export const likeProject = async ( action, list_id, session_id='' ) => {
  try {
    const res = axios({
      method: 'GET',
      url: API_URL + '/platform/api/list/like.php',
      params: {
        apikey: API_KEY,
        apiversion: API_VERSION,
        action,
        list_id,
        session_id,
      }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}