import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import classes from './style/Landing.module.css';

const Banner = () => {
  // checks if a user has already dismissed the banner
  const [displayBanner, setDisplayBanner] = useState(
    localStorage.show_banner 
    ? localStorage.getItem('show_banner') 
    : true
  );

  if(displayBanner === true) { return (
    <div className={classes.Banner}>
      <div className="ld-container">
        <span>
          <p>We're looking for people to help us build out the largest collection of indoor maps!</p>{` `}
          <Link to="/mapper-application">Apply now to be a Mapper and help people shop safer and better</Link>
        </span>
        <span onClick={() => {
          setDisplayBanner(!displayBanner);
          localStorage.setItem('show_banner', !displayBanner);
        }} className={classes.CloseBtn} />
      </div>
    </div>
  ) } else {
    return <Fragment/>
  }
}

export default Banner
