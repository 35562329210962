import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import TabsShop from './TabsShop';
import TabsSales from './TabsSales';
import TabsContainer from '../layout/TabsContainer';
import { IsNotMobile, Mobile } from '../layout/MediaQueries';

// Mobile Imports
import StoreHeader from './mobile-store/StoreHeader';
import StoreMap from './StoreMap';
import Draggable from './mobile-store/Draggable';
import StoreTabs from './mobile-store/StoreTabs';

import classes from './style/StoreItem.module.css';
import { 
  ADD_INPUT, 
  FORM_SUBMITTED, 
  QUICKADD_SELECTED, 
  REMOVE_ITEM
} from '../../actions';


const StoreItem = ({ trip_id, tripButton, info: { store_name, store_brand, store_address, id, short_name, logo }, updateButton, addNotification, data, updateUnsaved }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const shoppingList = useSelector(state => state.shopListReducer.shopping_list);
  const quickAdd = useSelector(state => state.quickAddReducer.quickAdd);
  const keywords = useSelector(state => state.storeReducer.keywords);

  const [ inputText, setText ] = useState('');
  const [ showQuickAdd, displayQuickAdd ] = useState(true);
  const [ activeIndex, setActiveIndex ] = useState(0);
  
  const TABS = [ 'Shop', 'Sales' ];

  // Updates stored quick adds item whenever a change is detected
  useEffect(() => {
    localStorage.setItem('quick_adds', JSON.stringify(quickAdd));
  }, [quickAdd]);

  // Method for checking of duplicates
  const checkDuplicate = (text) => {
    let dupe = false;
    shoppingList.forEach(item => {
      if(item.name.toLowerCase() === text.toLowerCase()) dupe = true;
    });
    return dupe;
  }

  // checks if keyword is valid or does not exist
  const checkExists = (text) => {
    let exist = false;
    keywords.forEach(key => {
      if(key.toLowerCase() === text.toLowerCase()) exist = true;
    });
    return exist;
  }

  // Form Submit for TypeAhead
  const handleSubmit = (e) => {
    e.preventDefault();
    let dupe = checkDuplicate(inputText);
    let valid = checkExists(inputText);
    if(dupe){ addNotification('Item Already in Cart', 'This item has already been added', 'error'); }
    else {
      if(!valid){
        addNotification('Unable to find item', `Cannot find aisle number of '${inputText}'`, 'warning');
      }
      dispatch({ type: ADD_INPUT, payload: inputText });
      dispatch({ type: FORM_SUBMITTED });
      setText('');
    }
  }

  const getItem = (item, submitted) => {
    if(item === undefined) return;

    setText(item);
    if( submitted ) {
      let dupe = checkDuplicate(item);
      if(dupe){ addNotification('Item Already in Cart', 'This item has already been added', 'error'); } 
      else {
        dispatch({ type: ADD_INPUT, payload: item });
        dispatch({ type: FORM_SUBMITTED });
        setText('');
      }
    }
  }

  // Handler for clicking on Add To Trip button
  const addClick = (e) => {
    e.preventDefault();
    if( shoppingList.length === 0 ){
      addNotification('Empty Input', 'There is nothing to add', 'error');
    } else {
      // build trip object to save in database
      const trip = {
        trip_id: uuidv4(),
        store_id: id,
        store_brand,
        store_name,
        store_address,
        short_name,
        shopping_list: shoppingList,
        logo,
        trip_name: moment().format('MMMM Do, YYYY'), // default trip name is date
        added: moment().format('MMMM Do, YYYY'), // today's date
        archived: false,
        archivedOn: ''
      }

      // checks to see if overwrites storage or create an entry in storage
      if(localStorage.getItem('trips') !== null){
        const trips = JSON.parse(localStorage.getItem('trips'));
        const newTrip = [ ...trips, trip ];
        localStorage.setItem('trips', JSON.stringify(newTrip));
      } else {
        localStorage.setItem('trips', JSON.stringify([ trip ]));;
      }
      addNotification('Store Successfully Added', `${store_name} has been added to your trip`, 'success');
      // Turn Add Button into Update
      updateButton(false);
      updateUnsaved(false);
    }
  }

  // Handler for clicking on Update Trip button
  const updateClick = (e) => {
    e.preventDefault();

    if(shoppingList.length === 0){
      addNotification(
        'Error',
        'Your shopping list is empty',
        'error'
      );
    } else {
      let trips = JSON.parse(localStorage.getItem('trips'));
      // loop trips until find a match, then update the trip
      trips.forEach(trip => {
        if(trip.trip_id === trip_id){
          trip.shopping_list = shoppingList;
        }
      });

      localStorage.setItem('trips', JSON.stringify(trips));
      addNotification(
        'Trip Successfully Updated',
        `Your trip to ${store_name} has been updated!`,
        'success'
      );
      updateUnsaved(false);
    }
  }

  // Handler for adding to shopping list from quick add
  const handleQuickAdd = (id) => {
    if(!quickAdd[id].selected){
      getItem(quickAdd[id].name, true);
    } else {
      shoppingList.forEach((item, index) => {
        if(item.name.toLowerCase() === quickAdd[id].name.toLowerCase()) dispatch({ type: REMOVE_ITEM, id: index });
      });
    }

    dispatch({ type: QUICKADD_SELECTED, id });
  }

  return (
    <Fragment>
    <IsNotMobile>
      <div className={classes.StoreItem}>
        <button className="bt-back" onClick={() => history.goBack()}><i className="fa fa-angle-left"></i> Back</button>
        <div className={classes.Header}>
          <div>
            <h3>{store_name}</h3>
            <h5>{store_address}</h5>
          </div>
          {tripButton && <button className={classes.AddTrip} onClick={e => addClick(e)}>+ Add to Trip</button>}
          {!tripButton && <button className={classes.UpdateTrip} onClick={e => updateClick(e)}>Update Trip</button>}
        </div>
        <div style={{marginTop: '2rem'}}>
          <TabsContainer tabs={TABS} active={activeIndex} onclick={id => setActiveIndex(id)} />
        </div>
        {activeIndex === 0 && 
        <TabsShop 
          keywords={keywords}
          showQuickAdd={showQuickAdd}
          quickAdd={quickAdd}
          onsubmit={handleSubmit}
          getItem={getItem}
          searchclicked={handleSubmit}
          onclose={() => displayQuickAdd(false)}
          onQuickAdd={handleQuickAdd}
        />}
        {activeIndex === 1 && 
        <TabsSales 
          id={id}
        />}
      </div>
    </IsNotMobile>
    <Mobile>
      <StoreHeader 
        store_name={store_name}
        short_name={short_name}
        tripButton={tripButton}
        mobileAdd={addClick}
        mobileUpdate={updateClick}/>
      <StoreTabs 
        id={id} 
        short_name={short_name} 
        trip_id={trip_id}
        activeTabs={activeIndex}
        onclick={id => setActiveIndex(id)}
      />
      <StoreMap data={data} />
      <Draggable
        store_name={store_name} 
        store_address={store_address}
        id={id}
        activeIndex={activeIndex}
        keywords={keywords}
        getItem={getItem}
        searchClicked={handleSubmit}
        onsubmit={handleSubmit}
      />
      <Link to={`/report-mistake?store_id=${id}`}>
        <div className={classes.Report}>
          <i className="fa fa-exclamation" aria-hidden="true"></i>
        </div>
      </Link>
    </Mobile>
    </Fragment>
  )
}

StoreItem.propTypes = {
  trip_id: PropTypes.string,
  info: PropTypes.object.isRequired,
  tripButton: PropTypes.bool.isRequired,
  updateButton: PropTypes.func.isRequired,
}

export default StoreItem;
