import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import NotificationSystem from 'react-notification-system';
import TitleContainer from '../layout/TitleContainer';
import FormGroup from '../layout/FormGroup';
import { applyMapper } from '../../api';
import { IS_WEB_APP, RECAPTCHA_MOBILE_APP_CODE } from '../../constants/constants';
import classes from '../request-store/RequestStore.module.css';

const MapperForm = ({ googleReCaptchaProps }) => {
  const history = useHistory();
  const notificationSystem = useRef();
  const auth = useSelector(state => state.authReducer);
  const { userInfo, isLoggedIn } = auth;

  const [formData, setFormData] = useState({
    name: isLoggedIn && userInfo ? `${userInfo.first_name} ${userInfo.last_name}` : '',
    email: isLoggedIn && userInfo ? userInfo.email : '',
    phone: '',
    dob: '',
    postal_code: '',
    areas_covered: '',
  });
  const { name, email, phone, dob, postal_code, areas_covered } = formData;

  const handleChange = e => setFormData({ ...formData, [e.target.name] : e.target.value });

  const handleSubmit = async e => {
    e.preventDefault();
    
    if( name === '' || email === '' || phone === '' || dob === '' || postal_code === '' || areas_covered === '' ) {
      addNotification(
        'Empty Input',
        'Required input field cannot be empty',
        'error',
      );
    } else {
      var recaptcha = RECAPTCHA_MOBILE_APP_CODE;
      if( IS_WEB_APP ) {
        recaptcha = await googleReCaptchaProps.executeRecaptcha('login');
      }

      const res = await applyMapper({ name, email, phone, dob, postal_code, areas_covered, recaptcha });
      
      if(res.data.success && !res.data.error) {
        setFormData({
          name: '',
          email: '',
          phone: '',
          dob: '',
          postal_code: '',
          areas_covered: ''
        });

        addNotification(
          'Successfully Submitted',
          'Your submission was successful',
          'success',
        );
      } else {
        addNotification(
          'Error',
          'An error occurred. Submission was not registered',
          'error',
        );
      }
    }
  }

  const addNotification = (title, message, type) => {
    const notification = notificationSystem.current;
    notification.addNotification({
      title,
      message,
      level: type,
      position: 'tc',
      autoDismiss: 3,
    })
  }

  return (
    <div className="container padded">
      <NotificationSystem ref={notificationSystem} />
      <TitleContainer title="Request Store" />
      <button className="bt-back" onClick={() => history.goBack()}><i className="fa fa-angle-left"></i> Back</button>
      <div className={classes.ReqStore}>
        <div className={classes.ModalHeader}>
          <h4>Mapper Application Form</h4>
        </div>
        <div className={classes.ModalBody}>
          <form id="request-form">
            <FormGroup name={'name'} label={'Full Name'} required={true} value={name} onchange={handleChange} />
            <FormGroup name={'email'} type="email" label={'Email'} required={true} value={email} onchange={handleChange} />
            <FormGroup name={'dob'} type="date" label={'Date of Birth'} required={true} value={dob} onchange={handleChange} />
            <FormGroup name={'phone'} type="tel" label={'Phone Number'} required={true} value={phone} onchange={handleChange} rest={{ maxLength: '11' }} />
            <FormGroup name={'postal_code'} label={'Postal Code'} required={true} value={postal_code} onchange={handleChange} rest={{ maxLength: '7', style: { textTransform: 'uppercase' } }} />
            <FormGroup name={'areas_covered'} label={'Cities/Towns Disposed to Map'} input={false} required={true} value={areas_covered} onchange={handleChange} />
          </form>
        </div>
        <div className={classes.ModalFooter}>
          <button onClick={() => history.goBack()}>Cancel</button>
          <button onClick={handleSubmit} form="request-form">Submit</button>
        </div>
      </div>
    </div>
  )
}

MapperForm.propTypes = {
  googleReCaptchaProps: PropTypes.object.isRequired,
}

export default withGoogleReCaptcha(MapperForm);