// Modified code from https://medium.com/@svsh227/create-your-own-type-ahead-dropdown-in-react-599c96bebfa
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from './style/TypeAhead.module.css';

const TypeAhead = ({ items, val, onTextUpdate }) => {
  const inputRef = useRef();
  const [width, setWidth] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [ cursor, setCursor ] = useState(-1);
  const [ hovered, setHovered ] = useState('');

  useEffect(() => {
    function handleResize() {
      const width = document.querySelector('.typeahead').offsetWidth;
      setWidth(`${width}px`);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return function cleanup() {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    if (suggestions.length && hovered) {
      setCursor(suggestions.indexOf(hovered));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered]);

  useEffect(() => {
    if(suggestions.length > 0) onTextUpdate(suggestions[cursor]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor]);

  const onTextChange = (e) => {
    let suggestions = [];
    const value = e.target.value;
    if(value.length > 0) {
      const regex = new RegExp(`^${value}`, `i`);
      suggestions = items.sort().filter(v => regex.test(v));
    }
    setSuggestions(suggestions);
    onTextUpdate(value);
  }

  const suggestionSelected = (item) => {
    setSuggestions([]);
    onTextUpdate(item);
    // Will make input field focus when selection is made
    inputRef.current.focus();
  }

  const handleKeyDown = (e) => {
    // Down Arrow
    if(suggestions.length && e.keyCode === 40){
      setCursor(prevState => 
        prevState < suggestions.length - 1 ? prevState + 1 : prevState  
      )
    } else if(e.keyCode === 38){ // Up Arrow
      if(cursor === 0) setCursor(0);
      else setCursor(prevState => (prevState > -1 ? prevState - 1 : prevState));
    } else if(e.keyCode === 13) { // Handler for Enter keyword
      e.preventDefault();
      setSuggestions([]);
      // Will make input field focus when selection is made
      inputRef.current.focus();
    }
    
  }

  const renderSuggestions = () => {
    if(suggestions.length === 0) return null;

    return (
      <ul style={{width: `${width}`}}>
        {suggestions.map((item, index) => {
          return (
            <li 
              key={index}
              className={`${cursor === index ? classes.Active : ''}`}
              onClick={(e) => suggestionSelected(item)}
              onMouseEnter={() => setHovered(item)}
              onMouseLeave={() => setHovered(undefined)}
            >
                {item}
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <div className={classes.TypeAhead}>
      <input 
        type="text"
        className="typeahead"
        value={val}
        placeholder="Where can you find this in the store?"
        onChange={onTextChange}
        ref={inputRef} onKeyDown={handleKeyDown}/>
      {renderSuggestions()}
    </div>
  )
}

TypeAhead.propTypes = {
  items: PropTypes.array.isRequired,
}

export default TypeAhead;
