// ## STORE ACTIONS ##
// Actions for Input Field
export const ADD_INPUT = 'ADD_INPUT';
export const CLEAR_INPUT = 'CLEAR_INPUT';

// Actions for gathering list of items
export const STORE_KEYWORDS = 'STORE_KEYWORDS';
export const SET_FLOOR_COUNT = 'SET_FLOOR_COUNT';

// Actions for form submitted
export const FORM_SUBMITTED = 'FORM_SUBMITTED';
export const CLEAR_STORE = 'CLEAR_STORE'


// ## SHOPPING LIST ACTIONS ##
// Actions for Adding to shopping list
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SET_COMPLETED = 'SET_COMPLETED';
export const RESET_REMOVE_INDEX = 'RESET_REMOVE_INDEX';
export const CLEAR_LIST = 'CLEAR_LIST';
export const SET_FOCUS = 'SET_FOCUS';
export const LOAD_SHOPPING_LIST = 'LOAD_SHOPPING_LIST';

// ## DATA ACTIONS ##
export const STORE_DATA = 'STORE_DATA';
export const STORE_BRANDS = 'STORE_BRANDS';


// ## TRIP ACTIONS ##
export const ARCHIVED_CLICKED = 'ARCHIVED_CLICKED';


// ## QUICK ADDS ACTIONS ##
export const QUICKADD_SELECTED = 'QUICKADD_SELECTED';
export const RESET_QUICKADD = 'RESET_QUICKADD';
export const RESET_SELECTED_QUICKADD = 'RESET_SELECTED_QUICKADD';
export const QUICKADD_TRUE = 'QUICKADD_TRUE';
export const QUICKADD_DISPLAY_TRUE = 'QUICKADD_DISPLAY_TRUE';


// ## MODAL ACTIONS ##
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

// ## SALES ACTIONS ##
export const STORE_SALES_STATE = 'STORE_SALES_STATE';
export const ADD_SALE_ITEM = 'ADD_SALE_ITEM';

// ## DOWNLOAD ACTIONS ##
export const SHOW_DOWNLOAD = 'SHOW_DOWNLOAD';
export const HIDE_DOWNLOAD = 'HIDE_DOWNLOAD';

// ## REPORT MISTAKE ACTIONS ##
export const SHOW_REPORT_MISTAKE = 'SHOW_REPORT_MISTAKE';
export const HIDE_REPORT_MISTAKE = 'HIDE_REPORT_MISTAKE';
export const UPDATE_STORE = 'UPDATE_STORE';

// ## AUTHENTICATION ACTIONS ##
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const SET_REDIRECT = 'SET_REDIRECT';
export const CLEAR_REDIRECT = 'CLEAR_REDIRECT';
export const SET_SESSION = 'SET_SESSION';
export const CLEAR_SESSION = 'CLEAR_SESSION';

// ## LISTS ACTION ##
export const STORE_MY_LIST = 'STORE_MY_LIST';
export const CLEAR_MY_LIST = 'CLEAR_MY_LIST';

// ## EXPLORE ACTION ##
export const STORE_RECIPES = 'STORE_RECIPES';
export const STORE_SCROLL = 'STORE_SCROLL';
export const UPDATE_HASMORE = 'UPDATE_HASMORE';
export const UPDATE_SORTING = 'UPDATE_SORTING';
export const CLEAR_EXPLORE = 'CLEAR_EXPLORE';