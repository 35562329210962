import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import NotificationSystem from 'react-notification-system';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TitleContainer from '../layout/TitleContainer';
import Spinner from '../layout/Spinner';
import RenderSales from './RenderSales';
import SalesHeader from './SalesHeader';
import { getBrandsList, getStore } from '../../api';
import { getMappingInformation } from '../../utils/helpers';
import classes from './style/SalesStore.module.css';

// import { sales } from './fakedata';
import { STORE_SALES_STATE, STORE_BRANDS } from '../../actions';

const SalesStore = ({ match }) => {
  const history = useHistory();
  const notificationSystem = useRef();
  const dispatch = useDispatch();

  const imp_brands = useSelector(state => state.dataReducer.brands);

  const param_store_name = match.params.name;
  const param_store_id = match.params.id;

  const [ res_data, setData ] = useState({});
  const [ store_name, setStoreName ] = useState('');
  const [ address, setAddress ] = useState('');
  const [ loading, setLoading ] = useState(true);
  const [ salesData, setSalesData ] = useState([]);
  // State that tracks whether to display add button or remove button
  const [ addRemove, setAddRemove ] = useState([]);
  const [ shopping_list, setShopList ] = useState([]);
  const [ info, setInfo ] = useState({});
  const [ tripId, setTripId ] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      let brands_data = {};
      const res = await getStore({ store_id: param_store_id });

      if(Object.keys(imp_brands).length === 0){
        const res_brands = await getBrandsList();
        brands_data = res_brands.data;
        dispatch({ type: STORE_BRANDS, payload: brands_data });
      } else { brands_data = imp_brands; }

      const {
        name,
        street_address,
        city,
        postal_code,
        province,
        brand_id,
      } = res.data.store;
      
      // Set store name
      setStoreName(name);
      setAddress(`${street_address}, ${city}, ${postal_code}, ${province}`);
      setInfo({
        store_name: name,
        store_address: `${street_address}, ${city}, ${postal_code}, ${province}`,
        store_brand: brands_data[brand_id].name,
        logo: brands_data[brand_id].logo,
      })

      // set sales information
      const sales_array = brands_data[brand_id].sales;
      setSalesData(sales_array);
      getButtonStatus(sales_array);
      setData(res.data);
      setLoading(false);

      let maxHeight = -1;
      const sales_list_dom = document.querySelectorAll('li h4');
      for(let i = 0; i < sales_list_dom.length; i++){
        const indexHeight = document.querySelectorAll('li h4')[i].offsetHeight;
        if(indexHeight > maxHeight) maxHeight = indexHeight;
      }

      sales_list_dom.forEach(item => {
        item.style.height = `${maxHeight}px`;
      })
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getButtonStatus = (array) => {
    const trips = JSON.parse(localStorage.getItem('trips'));
    let shopping_list = [];
    let statusArray = [];
    if(trips !== null){
      trips.forEach(trip => {
        if(param_store_id === trip.store_id) {
          shopping_list = trip.shopping_list;
          setTripId(trip.trip_id);
        }
      });
      setShopList(shopping_list);
      array.forEach(item => {
        let status = true; // display add button
        shopping_list.forEach(list => {
          if(item.title === list.name){
            status = false;
          }
        });
        statusArray.push(status);
      });
    } else {
      for(let i = 0; i < array.length; i++ ){
        statusArray.push(true);
      }
    }
    setAddRemove(statusArray);
    dispatch({ type: STORE_SALES_STATE, payload: statusArray });
  }

  // Handler for adding a Sales Item
  const handleAdd = (data, index) => {
    let {aisleNumber, highlightedArea, categoryColor, floor} = getMappingInformation(res_data, data.keyword);
    
    if(aisleNumber === "") aisleNumber = "-";
    const item = {
      name: data.title,
      keyword: data.keyword,
      aisle: aisleNumber,
      completed: false,
      highlighted: highlightedArea,
      color: categoryColor,
      floor,
      sales: true
    }

    shopping_list.push(item);

    const arr = [...addRemove];
    arr[index] = !arr[index];
    setAddRemove(arr);
    dispatch({ type: STORE_SALES_STATE, payload: arr });

    // determine whether it's an Add Trip or Update Trip
    // true is Trip exist
    const tripExist = getValidTrip();
    if(!tripExist){
      const trip_id = uuidv4();
      const trip = {
        trip_id,
        store_id: param_store_id,
        store_brand: info.store_brand,
        store_name: info.store_name,
        store_address: info.store_address,
        logo: info.logo,
        short_name: param_store_name,
        shopping_list,
        trip_name: moment().format('MMMM Do, YYYY'),
        added: moment().format('MMMM Do, YYYY'),
        archived: false,
        archivedOn: '',
      }

      if(localStorage.getItem('trips') !== null){
        const trips = JSON.parse(localStorage.getItem('trips'));
        const newTrip = [ ...trips, trip ];
        localStorage.setItem('trips', JSON.stringify(newTrip));
      } else {
        localStorage.setItem('trips', JSON.stringify([ trip ]));
      }
      addNotification(`${data.title} added!`, `${store_name} has been added to your trip`, 'success');
      setTripId(trip_id)
    } else {
      // if trip already exist
      let newTrip = JSON.parse(localStorage.getItem('trips'));
      newTrip.forEach(trip => {
        if(trip.store_id === param_store_id){
          trip.shopping_list = shopping_list;
        }
      });
      localStorage.setItem('trips', JSON.stringify(newTrip));
      addNotification(
        `${data.title} added!`,
        `Your trip to ${store_name} has been updated`,
        'success'
      );
    }
  }

  // Handler for removing sales item
  const handleRemove = (data, index) => {
    shopping_list.forEach((item, index) => {
      if(item.name === data.title) {
        shopping_list.splice(index, 1);
      }
    });

    const arr = [...addRemove];
    arr[index] = !arr[index];
    setAddRemove(arr);
    dispatch({ type: STORE_SALES_STATE, payload: arr });

    // Remove from local storage
    let newTrip = JSON.parse(localStorage.getItem('trips'));
    newTrip.forEach(trip => {
      if(trip.store_id === param_store_id){
        trip.shopping_list = shopping_list;
      }
    });
    localStorage.setItem('trips', JSON.stringify(newTrip));
    addNotification(
      `${data.title} Removed`,
      `Your trip to ${store_name} has been updated`,
      'error'
    );
  }

  const getValidTrip = () => {
    const trips = JSON.parse(localStorage.getItem('trips'));
    let bool = false;
    if(trips !== null){
      trips.forEach(trip => {
        if(trip.store_id === param_store_id){
          bool = true;
        }
      });
    }
    return bool;
  }

  const addNotification = (title, message, type) => {
    const notification = notificationSystem.current;
    notification.addNotification({
      title,
      message,
      level: type,
      position: 'tc',
      autoDismiss: 3,
    })
  }

  // Handler that determines whether a add or remove was clicked
  const handleClick = (e, action, data, index) => {
    e.preventDefault();
    switch(action){
      case 'add':
        return handleAdd(data, index);
      case 'remove':
        return handleRemove(data, index);
      default:
        return;
    }
  }

  if( loading ) return <Spinner />

  return (
    <div className={`container padded ${classes.SalesStore}`}>
      <TitleContainer title="Sales" />
      <NotificationSystem ref={notificationSystem} className={classes.Notification} />
      <button className="bt-back" onClick={() => history.goBack()}><i className="fa fa-angle-left"></i> Back</button>
      {/* <Link to={`/sales/${param_store_name}`} className="bt-back"><i className="fa fa-angle-left"></i> Back</Link> */}
      <div className={classes.Header}>
        <SalesHeader
          store_name={store_name}
          address={address}
          tripId={tripId}
          name={param_store_name}
          id={param_store_id}
        />
      </div>
      {!loading &&
      <ul className={classes.SalesContainer}>
        <RenderSales data={salesData} addRemove={addRemove} onclick={handleClick} />
      </ul>}
    </div>
  )
}

SalesStore.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired
}

export default SalesStore;