import { fabric } from 'fabric';

export var mapEditor = {
    // constants, so to speak
    MAP_VIEW_CUSTOMER: 1,
    MAP_VIEW_LAYOUT: 2,
    MAP_VIEW_STORE: 3,
    MAP_VIEW_APP: 4,
    
    // other variables
    canvas: null,
    categories: null,
    selectMode: false,
    canvasGridSize: 25,
    labelMaxFontSize: 16,
    labelMinFontSize: 8,
    labelFontFace: 'Helvetica',
    defaultShadow: '2px 2px 10px rgba(0,0,0,0.3)',
    drawObjectType: null,
    twoSidedShelf: false,
    currentlySelectedObject: null,
    showHideAnimationDuriation: 500,
    mouseMoved: null, // intentially set to null, allows distincton between fresh canvas (null) and altered canvas (false)
    currentViewMode: 3,
    currentFloor: 1,
    hiddenObjectOpacity: 0.5,
    lastObjectSelected: null,
    propsToClone: ['top', 'left', 'height', 'width', 'radius', 'floor',
        'x1', 'y1', 'x2', 'y2', 'aisleA', 'aisleB', 'angle', 'keywords', 
        'labelA', 'labelB', 'categoryA', 'categoryB', 'storeId', 'type', 
        'zoneCount', 'zones', 'leftCap', 'rightCap', 'twoSided', 'hidden'],
    
    
    setCategories: function(categories)
    {
        this.categories = categories;
    },
    
    setAllObjectsDirty: function()
    {
        // must set the dirty flag on all customizedShapes in the map so they are forced to re-render
        var objects = this.canvas.getObjects();
        for( var i=0; i < objects.length; i++ )
        {
            var object = objects[i];
            
            // ignore any native FabricJS objects
            if ( !this.isInstanceOfAnyChild(object, customizedShapes) ) continue;
            
            object.set('dirty', true);
        }
    },
    
    setCurrentViewMode: function(mode, button)
    {
        this.currentViewMode = mode;
        
        this.setAllObjectsDirty();
        this.canvas.renderAll();
    },
    
    isInstanceOfAnyChild: function (obj, collection)
    {
        for( var typeName in collection )
        {
            if( obj instanceof collection[typeName] ) return true;
        }
        return false;
    },
    
    isInstanceOfAny: function()
    {
        if( !arguments || !('length' in arguments) || arguments.length < 2 ) return false;
        var obj = arguments[0];
        delete arguments[0];
        return this.isInstanceOfAnyChild(obj, arguments);
    },
    
    getShapeByType: function(type)
    {
        for( var shape in customizedShapes )
        {
            if( customizedShapes[shape].prototype.type == type )
            {
                return customizedShapes[shape];
            }
        }
        return null;
    },

    import: function(data)
    {
        var ret = {maxX: 0, maxY: 0, categoryIds: [], floors: [1]};
        //console.log('import data is: ',data);
        if( !data || !(data instanceof Array) || data.length < 1 ) return;

        var canvasObjIndex = this.canvas.getObjects().length;

        for( var i=0; i < data.length; i++, canvasObjIndex++ )
        {
            var objData = data[i];
            var objProto = this.getShapeByType(objData.type);

            if( !objProto )
            {
                // console.warn('Could not find a suitable object prototype for: ', objData);
                continue;
            }

            objData.radius = objData.width; // just in case it is a circle
            var shape = new objProto(objData, objData);

            if( shape.get('hidden') == 1 ) shape.set('opacity', this.hiddenObjectOpacity);

            //// console.log('shape is ', shape);
            this.canvas.add(shape);

            var canvasObj = this.canvas.getObjects()[canvasObjIndex];
            var objMaxX = 0, objMaxY = 0;
            for( var key in canvasObj.aCoords )
            {
                objMaxX = Math.max(objMaxX, canvasObj.aCoords[key].x);
                objMaxY = Math.max(objMaxY, canvasObj.aCoords[key].y);
            }
            //// console.log('objMaxX: '+objMaxX+' objMaxY:'+objMaxY);

            // update maxX and maxY accordingly
            ret.maxX = Math.max(ret.maxX, objMaxX);
            ret.maxY = Math.max(ret.maxY, objMaxY);

            // update the list of category IDs accordingly
            if( objData.categoryA != null && ret.categoryIds.indexOf(objData.categoryA) < 0 )
            {
                ret.categoryIds.push(objData.categoryA);
            }

            if( objData.categoryB != null && ret.categoryIds.indexOf(objData.categoryB) < 0 )
            {
                ret.categoryIds.push(objData.categoryB);
            }

            // Update list of floors as well
            if( objData.floor > 1 && ret.floors.indexOf(objData.floor) < 0 )
            {
                ret.floors.push(objData.floor);
            }
        }
        //console.log('Import return values are:', ret);
        return ret;
    },
    
    highlightObjects: function( objAry )
    {
        // first, extract object array into distinct two arrays
        var objIds = new Array();
        var sides = new Array();
        var zones = new Array();
        for( var i=0; objAry !== undefined && i < objAry.length; i++ )
        {
            // check for duplicates, which means two sides of a shelf are highlighted
            var objId = parseInt(objAry[i].map_object_id);
            var j = objIds.indexOf(objId);
            if( j < 0 ) // brand new
            {
                objIds.push(objId);
                sides.push(objAry[i].side);
                zones.push(objAry[i].zones);
            } else if( sides[j] != objAry[i].side ) { // pre existing but different side
                sides[j] = 'both';
            }
        }
        //console.log(objAry, ' vs ', objIds, ' and ', sides, ' and ', zones);
        
        // loop through map objects and highlight matching objects
        var objects = this.canvas.getObjects();
        var objectsHighlighted = false;
        var lastFloor = 1;
        for( var i=0; i < objects.length; i++ )
        {
            var object = objects[i];
            
            // ignore non-shelf objects
            if ( !this.isInstanceOfAny(object, customizedShapes.regularShelf, customizedShapes.rectangleShelf, customizedShapes.circleShelf) ) continue;
            
            var index = objIds.indexOf(object.objectId);
            if( index >= 0 ) // must highlight
            {
                //console.log('Highlighting objectId: ' + object.objectId + ', side is: ' + sides[index]);
                object.setHighlighted(true, sides[index], zones[index]);
                lastFloor = object.get('floor');
                objectsHighlighted = true;
            } else {
                object.setHighlighted(false, '');
            }
        }
        
        // last but not least, set the canvas highlighted map accordingly
        this.canvas.set('objectsHighlighted', objectsHighlighted);
        if( objectsHighlighted && lastFloor != this.currentFloor ) 
        {
            this.switchToFloor(lastFloor, true); // switchToFloor will also call renderAll
        } else {
            this.canvas.renderAll();
        }
    },
    
    setContextFont: function(ctx, text, boxWidth)
    {
        ctx.textAlign = 'center';
        var size = this.labelMaxFontSize;
        while( size > this.labelMinFontSize )
        {
            //var textWidth = (text != '' ? text.length:1) * size * 0.415;
            ctx.font = size + 'px ' + this.labelFontFace;
            var textWidth = ctx.measureText(text).width;
            if( textWidth <= (boxWidth - 10) ) return; // -10 to leave some margin
            size--;
        }
        ctx.font = this.labelMinFontSize + 'px ' + this.labelFontFace;
    },
    
    renderLabelText: function(obj, ctx, label, labelX, labelY, width)
    {
        // first, figure out if we should change the color based on the category
        var labelColour = obj.stroke;
        var addTextStroke = false;
        if( label == obj.labelA ) 
        {
            labelColour = this.getCategoryColourByID(obj.categoryA);
            if( obj.highlighted && (obj.highlightedSide == 'a' || obj.highlightedSide == 'both') )
            {
                addTextStroke = true;
            }
        }
        if( label == obj.labelB ) 
        {
            labelColour = this.getCategoryColourByID(obj.categoryB);
            if( obj.highlighted && (obj.highlightedSide == 'b' || obj.highlightedSide == 'both') )
            {
                addTextStroke = true;
            }
        }
        label = label.trim();
        var width = (width !== undefined) ? width:obj.width;
        this.setContextFont(ctx, label, width);
        
        var origStrokeStyle = ctx.strokeStyle;
        ctx.fillStyle = labelColour;
        
        if( addTextStroke && mapEditor.currentViewMode == mapEditor.MAP_VIEW_APP )
        {
            ctx.fillStyle = 'white';
            //ctx.strokeStyle = 'white';
            //ctx.lineWidth = 3;
        }
        
        //if( obj.angle >= 180 ) ctx.rotate(-Math.PI);
        if( obj.angle < 0 ) obj.angle = obj.angle + 360; // so -90 becomes 270

        if( (obj.angle > 90 && obj.angle <= 180) || (obj.angle >= 180 && obj.angle <= 270) )
        {
            ctx.save();
            ctx.translate(labelX, labelY);
            ctx.rotate(-Math.PI);
            
            if( addTextStroke && this.currentViewMode == mapEditor.MAP_VIEW_APP )
            {
                //ctx.strokeText(label, labelX, +10);
            }
            ctx.fillText(label, labelX, +10);
            ctx.restore();
            
        } else {
            if( addTextStroke && this.currentViewMode == mapEditor.MAP_VIEW_APP )
            {
                //ctx.strokeText(label, labelX, labelY);
            }
            
            ctx.fillText(label, labelX, labelY);
        }
        ctx.fillStyle = obj.stroke;
        ctx.strokeStyle = origStrokeStyle;
    },
    
    getCategoryColourByID: function(categoryId)
    {
        if( this.categories != '' && categoryId in this.categories )
        {
            return this.categories[categoryId].colour;
        }
        return '';
    },
    
    adjustHiddenObject: function(obj, ctx)
    {
        if( obj.get('hidden') != 1 ) return true;
        
        if( this.currentViewMode == this.MAP_VIEW_CUSTOMER || this.currentViewMode == this.MAP_VIEW_LAYOUT ) {
            return false;
        } else {
            obj.set('opacity', this.hiddenObjectOpacity);
            return true;
        }
    },
    
    switchToFloor: function(newFloor, rerender)
    {
        this.currentFloor = newFloor;
        if( rerender ) 
        {
            // this.canvas.deactivateAll();
            this.setAllObjectsDirty();
            this.canvas.renderAll();
        }
    },
};

/*
 * Extend Fabric's Rect class to have a categorized Rectangle (ie one linked to a category)
 */
var customizedShapes = {
    
    regularShelf: fabric.util.createClass(fabric.Rect, {

        type: 'regularShelf',
        fill: 'rgba(0,0,0,0)',
        highlightedFill: '#FFAD33',
        // allows for side A and B to have different highlight colours - useful for heat maps
        highlightedFillA: '#FFAD33', 
        highlightedFillB: '#FFAD33',
        highlightedZone: '#FF0000',
        opacity: 1,
        stroke: 'black',
        strokeWidth: 4,
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
        cornerStyle: 'circle',
        hasRotatingPoint: true,
        snapAngle: 5,
        transparentCorners: false,
        //capWidth: mapEditor.canvasGridSize,
        capWidth: 5,


        initialize: function(options) {
            options || (options = { });

            this.callSuper('initialize', options);
            this.set('objectId', options.objectId || 0);
            this.set('floor', options.floor || 1);
            this.set('keywords', options.keywords || '');
            this.set('aisleA', options.aisleA || 1);
            this.set('aisleB', options.aisleB || 1);
            this.set('labelA', options.labelA || '');
            this.set('labelB', options.labelB || '');
            this.set('hidden', options.hidden || 0);
            this.set('categoryA', options.categoryA || '');
            this.set('categoryB', options.categoryB || '');
            this.set('zones', options.zones || []);
            this.set('zoneCount', options.zoneCount || 1);
            this.set('leftCap', options.leftCap || false);
            this.set('rightCap', options.rightCap || false);
            this.set('twoSided', options.twoSided || false);
            this.on('modified', mapEditor.onModified);
            
            this.setShadow(mapEditor.defaultShadow);
        },

        toObject: function() {
            return fabric.util.object.extend(this.callSuper('toObject'), {
                objectId: this.get('objectId'),
                floor: this.get('floor'),
                keywords: this.get('keywords'),
                aisleA: this.get('aisleA'),
                aisleB: this.get('aisleB'),
                labelA: this.get('labelA'),
                labelB: this.get('labelB'),
                hidden: this.get('hidden'),
                categoryA: this.get('categoryA'),
                categoryB: this.get('categoryB'),
                zones: this.get('zones'),
                zoneCount: this.get('zoneCount'),
                leftCap: this.get('leftCap'),
                rightCap: this.get('rightCap'),
                twoSided: this.get('twoSided'),
            });
        },
        
        setHighlighted: function(highlighted, side, zones) {
            this.set('highlighted', highlighted);
            this.set('highlightedSide', side);
            this.set('highlightedZones', zones);
            this.set('dirty', true);
        },
        
        _render: function(ctx, noTransform) {
            
            // if this object belong to another floor than what's currently being displayed
            if( this.get('floor') != mapEditor.currentFloor ) return;
            
            // If this object is hidden
            if( !mapEditor.adjustHiddenObject(this, ctx) ) return;
            
            var labelA = this.get('labelA');
            var labelB = this.get('labelB');
            var twoSided = this.get('twoSided');
            var highlighted = this.get('highlighted');
            var highlightedSide = this.get('highlightedSide');
            var highlightedFill = this.get('highlightedFill');
            var highlightedZones = this.get('highlightedZones');
            var w = this.width,
                h = this.height,
                halfWidth = w/2,
                halfHeight = h/2,
                x = noTransform ? this.left : -this.width / 2,
                y = noTransform ? this.top : -this.height / 2,
                x2 = x + w,
                y2 = y + h,
                midX = x + w / 2,
                midY = y + h / 2,
                capWidth = this.capWidth,
                zoneWidthIncrements = (w - ((this.leftCap == true) ? capWidth:0) - ((this.rightCap == true) ? capWidth:0)) / this.zoneCount;
        
            // ZONE HIGHLIGHTING MATH
            var totalZoneCount = this.zoneCount * (twoSided ? 2:1);
            totalZoneCount += this.leftCap ? 1:0;
            totalZoneCount += this.rightCap ? 1:0;
            var midWayZone = this.zoneCount + (this.leftCap ? 1:0);
            //console.log('totalZoneCount=', totalZoneCount, 'midWayZone=', midWayZone);
            
            for( var i=0; i < totalZoneCount && highlightedZones instanceof Array && highlightedZones.length > 0; i++ )
            {
                if( highlightedZones.indexOf(i+1) < 0 ) continue; // this zone is not highlighted
                
                // if it isn't two side OR either cap is highlighted
                if( !twoSided || (i==0 && this.leftCap == true) || (i==(totalZoneCount - 1) && this.rightCap == true) ) 
                {
                    // highlight the entire shelf
                    highlighted = true;
                    highlightedSide = 'both';
                    break;
                }
                else
                {
                    highlighted = true;
                    var newSide = (i < midWayZone) ? 'a':'b';
                    //console.log('highlightedSide=',highlightedSide);
                    if( !highlightedSide || highlightedSide == '' )
                    {
                        //console.log('highlightedSide is empty');
                        highlightedSide = newSide;
                    }
                    else if( highlightedSide != newSide )
                    {
                        //console.log('highlightedSide is ELSE');
                        highlightedSide = 'both';
                        break;
                    }
                }
            }
                
            // if this object is highlighted, draw the highlighted background
            if( highlighted === true )
            {
                var highlightedFillA = this.get('highlightedFillA');
                var highlightedFillB = this.get('highlightedFillB');
                var highlightX = x;
                var highlightY = y;
                var highlightW = w;
                var highlightH = h;
                
                if( this.leftCap == true )
                {
                    highlightX += capWidth;
                    highlightW -= capWidth;
                }
                if( this.rightCap == true )
                {
                    highlightW -= capWidth;
                }
                
                if( twoSided && highlightedSide != 'both' )
                {
                    highlightH = halfHeight;
                    if( highlightedSide == 'b' )
                    {
                        highlightY = midY;
                    }
                }
                
                if( highlightedSide && highlightedFillA == highlightedFillB && mapEditor.currentViewMode != mapEditor.MAP_VIEW_APP )
                {
                    ctx.beginPath();
                    ctx.rect(highlightX, highlightY, highlightW, highlightH);
                    ctx.fillStyle = highlightedFill;
                    ctx.fill();
                    ctx.closePath();
                } 
                else // if the two sides have different highlight colours (e.g. heat map) OR we're in the APP view
                {
                    if( mapEditor.currentViewMode == mapEditor.MAP_VIEW_APP ) // highlight shelves by their caegory colour
                    {
                        highlightedFillA = mapEditor.getCategoryColourByID(this.categoryA);
                        highlightedFillB = mapEditor.getCategoryColourByID(this.categoryB);
                    }
                    // draw side A
                    if( highlightedSide == 'a' || highlightedSide == 'both' || (highlightedSide != '' && twoSided == false) )
                    {
                        ctx.beginPath();
                        ctx.rect(highlightX, highlightY, highlightW, halfHeight);
                        ctx.fillStyle = highlightedFillA;
                        ctx.fill();
                        ctx.closePath();
                    }
                    
                    // draw side B
                    if( highlightedSide == 'b' || highlightedSide == 'both' || (highlightedSide != '' && twoSided == false) )
                    {
                        ctx.beginPath();
                        ctx.rect(highlightX, midY, highlightW, halfHeight);
                        ctx.fillStyle = highlightedFillB;
                        ctx.fill();
                        ctx.closePath();
                    }
                }
            } // end if highlighted
            
            
            // HANDLE ZONE HIGHLIGHTING
            for( var i=0; i < totalZoneCount && highlightedZones instanceof Array && highlightedZones.length > 0; i++ )
            {
                if( highlightedZones.indexOf(i+1) < 0 ) continue; // this zone is not highlighted
                
                ctx.beginPath();
                
                if( i==0 && this.leftCap == true ) // special case for the left cap
                {
                    ctx.rect(x, y, capWidth, h);
                    //// console.log('about to highlight: ', x , y, capWidth, h);
                }
                else if( i==(totalZoneCount - 1) && this.rightCap == true ) // special case for the right cap
                {
                    ctx.rect(x + w - capWidth, y, capWidth, h);
                    //// console.log('about to highlight: ', x + w - capWidth , y, capWidth, h);
                }
                else
                {
                    //var xOffset = (zoneWidthIncrements * ((i - (this.leftCap == true) ? 1:0) % (midWayZone - 1))) + ((this.leftCap == true) ? capWidth:0);
                    var xOffset = (zoneWidthIncrements * ((i-(this.leftCap == true ? 1:0)) % this.zoneCount)) + ((this.leftCap == true) ? capWidth:0);
                    if( twoSided )
                    {
                        ctx.rect(x + xOffset, (i < midWayZone) ? y:midY, zoneWidthIncrements, halfHeight);
                        //// console.log('about to highlight: ', x + xOffset, (i < midWayZone) ? y:midY, zoneWidthIncrements, halfHeight);
                    } else {
                        ctx.rect(x + xOffset, y, zoneWidthIncrements, h);
                        //// console.log('about to highlight: ', x + xOffset, y, zoneWidthIncrements, h);
                    }
                }
                ctx.fillStyle = this.get('highlightedZone');
                ctx.fill();
                ctx.closePath();
            }
            
            ctx.beginPath();
            
            ctx.fillStyle = this.stroke;
            
            // draw the middle line
            var middleLineY = (twoSided == true) ? midY:y2;
            ctx.moveTo(x + ((this.leftCap == true) ? capWidth:0), middleLineY);
            ctx.lineTo(x2 - ((this.rightCap == true) ? capWidth:0), middleLineY);
            
            // draw the left cap
            ctx.moveTo(x + ((this.leftCap == true) ? capWidth:0), y);
            ctx.lineTo(x + ((this.leftCap == true) ? capWidth:0), y2);
            
            if( this.leftCap == true )
            {
                if( mapEditor.currentViewMode == mapEditor.MAP_VIEW_STORE && this.active )
                {
                    ctx.rotate(-Math.PI / 2);
                    ctx.fillText('1', -3, -halfWidth + 15);
                    ctx.rotate(Math.PI / 2);
                }
                ctx.moveTo(x, y);
                ctx.lineTo(x + capWidth, y);
                
                ctx.moveTo(x, y2);
                ctx.lineTo(x + capWidth, y2);
            }
            
            // draw the right cap
            ctx.moveTo(x2 - ((this.rightCap == true) ? capWidth:0), y);
            ctx.lineTo(x2 - ((this.rightCap == true) ? capWidth:0), y + h);
            
            if( this.rightCap == true )
            {
                if( mapEditor.currentViewMode == mapEditor.MAP_VIEW_STORE && this.active )
                {
                    var labelIndex = ((this.leftCap == true) ? 1:0) + (this.zoneCount * ((twoSided == true) ? 2:1));
                    var labelX = (labelIndex < 9) ? -2:-5;
                    ctx.rotate(Math.PI / 2);
                    ctx.fillText(String(labelIndex + 1), labelX, -halfWidth + 15);
                    ctx.rotate(-Math.PI / 2);
                }
                
                ctx.moveTo(x2, y);
                ctx.lineTo(x2 - capWidth, y);
                
                ctx.moveTo(x2, y2);
                ctx.lineTo(x2 - capWidth, y2);

            }
            
            // if it has more than one zone, draw the vertical lines
            for( var i=1; i < this.zoneCount && mapEditor.currentViewMode == mapEditor.MAP_VIEW_STORE; i++ )
            {
                var lineX = (zoneWidthIncrements * i) + ((this.leftCap == true) ? capWidth:0);
                ctx.moveTo(x + lineX, y);
                ctx.lineTo(x + lineX, y2);
            }
            

            // draw the labels of the individual zones
            for( var i=0; i < this.zoneCount && mapEditor.currentViewMode == mapEditor.MAP_VIEW_STORE && this.active; i++ )
            {
                var labelIndex = ((this.leftCap == true) ? 1:0) + i;
                var x = (-halfWidth) + (zoneWidthIncrements * i) + ((this.leftCap == true) ? capWidth:0) + (zoneWidthIncrements / 2) - 2;
                var y = (-halfHeight / 2) + 3;
                
                if( twoSided == true )
                {
                    ctx.fillText(String(labelIndex + 1), x, y);
                    ctx.fillText(String(labelIndex + 1 + this.zoneCount), x, y + halfHeight + 3);
                    //mapEditor.renderLabelText(this, ctx, String(labelIndex + 1), x, midY - 2, zoneWidthIncrements);
                    //mapEditor.renderLabelText(this, ctx, String(labelIndex + 1 + this.zoneCount), x, midY + 17, zoneWidthIncrements);
                }
                else
                {
                    ctx.fillText(String(labelIndex + 1), x, y + (halfHeight / 2));
                    //mapEditor.renderLabelText(this, ctx, String(labelIndex + 1), x, 2, zoneWidthIncrements);
                }
            }
            
            
            // render the label(s) only if (We're NOT in customer view AND no objects on the map are highlighted) OR we're in APP view
            if( (mapEditor.currentViewMode == mapEditor.MAP_VIEW_LAYOUT && !this.canvas.objectsHighlighted) || mapEditor.currentViewMode == mapEditor.MAP_VIEW_APP )
            {
                var labelWidth = w - (this.leftCap == true ? capWidth:0) - (this.rightCap == true ? capWidth:0);
                //var labelXPoint = -halfWidth + 5 + (this.leftCap == true ? capWidth:0);
                var labelXPoint = 0;
                if( (labelA != ''  && labelB != '') || twoSided == true )
                {
                    mapEditor.renderLabelText(this, ctx, labelA, labelXPoint, midY - 5, labelWidth);
                    if( labelB != '' ) {
                        mapEditor.renderLabelText(this, ctx, labelB, labelXPoint, midY + 16, labelWidth);
                    }
                } 
                else if( labelA != '' ) 
                {
                    mapEditor.renderLabelText(this, ctx, labelA, labelXPoint, 2, labelWidth);
                }
            }
            
            ctx.closePath();

            this._renderFill(ctx);
            this._renderStroke(ctx);
            
        }
    
    }),
    
    rectangleShelf: fabric.util.createClass(fabric.Rect, {

        type: 'rectangularShelf',
        fill: 'rgba(0,0,0,0)',
        originalfill: 'rgba(0,0,0,0)',
        highlightedFill: '#FFAD33',
        opacity: 1,
        stroke: 'black',
        strokeWidth: 4,
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
        rx: 2,
        ry: 2,
        cornerStyle: 'circle',
        hasRotatingPoint: true,
        snapAngle: 5,
        transparentCorners: false,
        font: '12px Helvetica',


        initialize: function(options) {
            options || (options = { });

            this.callSuper('initialize', options);
            this.set('objectId', options.objectId || 0);
            this.set('floor', options.floor || 1);
            this.set('keywords', options.keywords || '');
            this.set('aisleA', options.aisleA || 1);
            this.set('aisleB', options.aisleB || 1);
            this.set('labelA', options.labelA || '');
            this.set('labelB', options.labelB || '');
            this.set('hidden', options.hidden || 0);
            this.set('categoryA', options.categoryA || '');
            this.set('categoryB', options.categoryB || '');
            this.set('zones', options.zones || []);
            this.on('modified', mapEditor.onModified);
            
            this.setShadow(mapEditor.defaultShadow)
        },
        
        toObject: function() {
            return fabric.util.object.extend(this.callSuper('toObject'), {
                objectId: this.get('objectId'),
                floor: this.get('floor'),
                keywords: this.get('keywords'),
                aisleA: this.get('aisleA'),
                aisleB: this.get('aisleB'),
                labelA: this.get('labelA'),
                labelB: this.get('labelB'),
                hidden: this.get('hidden'),
                categoryA: this.get('categoryA'),
                categoryB: this.get('categoryB'),
                zones: this.get('zones')
            });
        },
        
        setHighlighted: function(highlighted, side) {
            this.set('highlighted', highlighted);
            if( highlighted === true && mapEditor.currentViewMode == mapEditor.MAP_VIEW_APP ) // highlight shelves by their category colour
            {
                this.highlightedFill = mapEditor.getCategoryColourByID(this.categoryA);
            }
            this.set('fill', this.get( (highlighted === true) ? 'highlightedFill':'originalfill'));
            this.set('dirty', true);
        },
        
        _render: function(ctx, noTransform) {
            
            // if this object belong to another floor than what's currently being displayed
            if( this.get('floor') != mapEditor.currentFloor ) return;
            
            // If this object is hidden
            if( !mapEditor.adjustHiddenObject(this, ctx) ) return;
            
            this.callSuper('_render', ctx);
            
            ctx.fillStyle = this.stroke;
            
            var labelA = this.get('labelA');
            var labelB = this.get('labelB');
            var w = this.width,
                h = this.height,
                halfWidth = w/2,
                halfHeight = h/2;
        
            // render the label(s) only if A) We're NOT in customer view AND B) no objects on the map are highlighted
            if( (mapEditor.currentViewMode == mapEditor.MAP_VIEW_LAYOUT && !this.canvas.objectsHighlighted) || mapEditor.currentViewMode == mapEditor.MAP_VIEW_APP )
            {
                if( labelA != ''  && labelB != '' )
                {
                    mapEditor.renderLabelText(this, ctx, labelA, 0, -halfHeight + 20);
                    mapEditor.renderLabelText(this, ctx, labelB, 0, 20);
                } 
                else if( labelA != '' ) 
                {
                    mapEditor.renderLabelText(this, ctx, labelA, 0, 5);
                }
            }
            if( mapEditor.currentViewMode == mapEditor.MAP_VIEW_STORE && this.active )
            {
                mapEditor.renderLabelText(this, ctx, '1', 0, 5);
            }
        }
    }),
    
    circleShelf: fabric.util.createClass(fabric.Circle, {

        type: 'circularShelf',
        fill: 'rgba(0,0,0,0)',
        originalfill: 'rgba(0,0,0,0)',
        highlightedFill: '#FFAD33',
        opacity: 1,
        stroke: 'black',
        strokeWidth: 4,
        cornerStyle: 'circle',
        hasRotatingPoint: true,
        snapAngle: 5,
        transparentCorners: false,
        font: '12px Helvetica',


        initialize: function(options) {
            options || (options = { });

            this.callSuper('initialize', options);
            this.set('objectId', options.objectId || 0);
            this.set('floor', options.floor || 1);
            this.set('keywords', options.keywords || '');
            this.set('aisleA', options.aisleA || 1);
            this.set('aisleB', options.aisleB || 1);
            this.set('labelA', options.labelA || '');
            this.set('labelB', options.labelB || '');
            this.set('hidden', options.hidden || 0);
            this.set('categoryA', options.categoryA || '');
            this.set('categoryB', options.categoryB || '');
            this.set('zones', options.zones || []);
            this.on('modified', mapEditor.onModified);
            
            this.setShadow(mapEditor.defaultShadow)
        },

        toObject: function() {
            return fabric.util.object.extend(this.callSuper('toObject'), {
                objectId: this.get('objectId'),
                floor: this.get('floor'),
                keywords: this.get('keywords'),
                aisleA: this.get('aisleA'),
                aisleB: this.get('aisleB'),
                labelA: this.get('labelA'),
                labelB: this.get('labelB'),
                hidden: this.get('hidden'),
                categoryA: this.get('categoryA'),
                categoryB: this.get('categoryB'),
                zones: this.get('zones')
            });
        },
        
        setHighlighted: function(highlighted, side) {
            this.set('highlighted', highlighted);
            if( highlighted === true && mapEditor.currentViewMode == mapEditor.MAP_VIEW_APP ) // highlight shelves by their category colour
            {
                this.highlightedFill = mapEditor.getCategoryColourByID(this.categoryA);
            }
            this.set('fill', this.get( (highlighted === true) ? 'highlightedFill':'originalfill'));
            this.set('dirty', true);
        },
        
        _render: function(ctx, noTransform) {
            
            // if this object belong to another floor than what's currently being displayed
            if( this.get('floor') != mapEditor.currentFloor ) return;
            
            // If this object is hidden
            if( !mapEditor.adjustHiddenObject(this, ctx) ) return;
            
            this.callSuper('_render', ctx);
            
            ctx.fillStyle = this.stroke;
            
            var labelA = this.get('labelA');
            var labelB = this.get('labelB');
            var w = this.width,
                h = this.height,
                halfWidth = w/2,
                halfHeight = h/2;
        
            // render the label(s) only if A) We're NOT in customer view AND B) no objects on the map are highlighted
            if( (mapEditor.currentViewMode == mapEditor.MAP_VIEW_LAYOUT && !this.canvas.objectsHighlighted) || mapEditor.currentViewMode == mapEditor.MAP_VIEW_APP )
            {
                if( labelA != ''  && labelB != '' )
                {
                    mapEditor.renderLabelText(this, ctx, labelA, 0, -halfHeight + 20);
                    mapEditor.renderLabelText(this, ctx, labelB, 0, 20);
                } 
                else if( labelA != '' ) 
                {
                    mapEditor.renderLabelText(this, ctx, labelA, 0, 5);
                }
            }
            
            if( mapEditor.currentViewMode == mapEditor.MAP_VIEW_STORE && this.active )
            {
                mapEditor.renderLabelText(this, ctx, '1', 0, 5);
            }
        }
    }),
    
    line: fabric.util.createClass(fabric.Rect, {
        type: 'line',
        fill: 'black',
        opacity: 1,
        stroke: 'black',
        strokeWidth: 4,
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
        cornerStyle: 'circle',
        hasRotatingPoint: true,
        snapAngle: 5,
        transparentCorners: false,
        
        initialize: function(options) {
            options || (options = { });

            this.callSuper('initialize', options);
            this.set('objectId', options.objectId || 0);
            this.set('floor', options.floor || 1);
            this.set('hidden', options.hidden || 0);
            this.on('modified', mapEditor.onModified);
            
            this.setShadow(mapEditor.defaultShadow)
        },

        toObject: function() {
            return fabric.util.object.extend(this.callSuper('toObject'), {
                objectId: this.get('objectId'),
                floor: this.get('floor'),
                hidden: this.get('hidden'),
            });
        },
        
        _render: function(ctx, noTransform) {
            
            // if this object belong to another floor than what's currently being displayed
            if( this.get('floor') != mapEditor.currentFloor ) return;
            
            // If this object is hidden
            if( !mapEditor.adjustHiddenObject(this, ctx) ) return;
            
            this.callSuper('_render', ctx);
        },
    }),
    
    rectangle: fabric.util.createClass(fabric.Rect, {
        type: 'rectangle',
        fill: 'black',
        opacity: 1,
        stroke: 'black',
        strokeWidth: 4,
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
        rx: 2,
        ry: 2,
        cornerStyle: 'circle',
        hasRotatingPoint: true,
        snapAngle: 5,
        transparentCorners: false,
        
        initialize: function(options) {
            options || (options = { });

            this.callSuper('initialize', options);
            this.set('objectId', options.objectId || 0);
            this.set('floor', options.floor || 1);
            this.set('hidden', options.hidden || 0);
            this.on('modified', mapEditor.onModified);
            
            this.setShadow(mapEditor.defaultShadow)
        },

        toObject: function() {
            return fabric.util.object.extend(this.callSuper('toObject'), {
                objectId: this.get('objectId'),
                floor: this.get('floor'),
                hidden: this.get('hidden'),
            });
        },
        
        _render: function(ctx, noTransform) {
            
            // if this object belong to another floor than what's currently being displayed
            if( this.get('floor') != mapEditor.currentFloor ) return;
            
            // If this object is hidden
            if( !mapEditor.adjustHiddenObject(this, ctx) ) return;
            
            this.callSuper('_render', ctx);
        },
    }),
    
    circle: fabric.util.createClass(fabric.Circle, {
        type: 'circle',
        fill: 'black',
        opacity: 1,
        stroke: 'black',
        strokeWidth: 4,
        cornerStyle: 'circle',
        hasRotatingPoint: true,
        snapAngle: 5,
        transparentCorners: false,
        
        initialize: function(options) {
            options || (options = { });

            this.callSuper('initialize', options);
            this.set('objectId', options.objectId || 0);
            this.set('floor', options.floor || 1);
            this.on('modified', mapEditor.onModified);
            
            this.setShadow(mapEditor.defaultShadow);
        },

        toObject: function() {
            return fabric.util.object.extend(this.callSuper('toObject'), {
                objectId: this.get('objectId'),
                floor: this.get('floor'),
                hidden: this.get('hidden'),
            });
        },
        
        _render: function(ctx, noTransform) {
            
            // if this object belong to another floor than what's currently being displayed
            if( this.get('floor') != mapEditor.currentFloor ) return;
            
            // If this object is hidden
            if( !mapEditor.adjustHiddenObject(this, ctx) ) return;
            
            this.callSuper('_render', ctx);
        },
    }),
    
    triangle: fabric.util.createClass(fabric.Triangle, {
        type: 'triangle',
        fill: 'black',
        opacity: 1,
        stroke: 'black',
        strokeWidth: 4,
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
        cornerStyle: 'circle',
        hasRotatingPoint: true,
        snapAngle: 5,
        transparentCorners: false,
        
        initialize: function(options) {
            options || (options = { });

            this.callSuper('initialize', options);
            this.set('objectId', options.objectId || 0);
            this.set('floor', options.floor || 1);
            this.set('hidden', options.hidden || 0);
            this.on('modified', mapEditor.onModified);
            
            this.setShadow(mapEditor.defaultShadow);
        },

        toObject: function() {
            return fabric.util.object.extend(this.callSuper('toObject'), {
                objectId: this.get('objectId'),
                floor: this.get('floor'),
                hidden: this.get('hidden'),
            });
        },
        
        _render: function(ctx, noTransform) {
            
            // if this object belong to another floor than what's currently being displayed
            if( this.get('floor') != mapEditor.currentFloor ) return;
            
            // If this object is hidden
            if( !mapEditor.adjustHiddenObject(this, ctx) ) return;
            
            this.callSuper('_render', ctx);
        },
    }),
    
    arrow: fabric.util.createClass(fabric.Polyline, {
        type: 'arrow',
        fill: 'black',
        opacity: 1,
        stroke: 'black',
        strokeWidth: 2,
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
        cornerStyle: 'circle',
        hasRotatingPoint: true,
        snapAngle: 5,
        transparentCorners: false,
        
        initialize: function(options) {
            options || (options = { });
            
            var fromx = this.left;
            var fromy = this.top;
            var tox = this.left;
            var toy = this.top + 50;
            var angle = Math.atan2(toy - fromy, tox - fromx);
            var headlen = 10;  // arrow head size

            // bring the line end back some to account for arrow head.
            tox = tox - (headlen) * Math.cos(angle);
            toy = toy - (headlen) * Math.sin(angle);

            // calculate the points.
            var points = [
                    {
                            x: fromx,  // start point
                            y: fromy
                    }, {
                            x: fromx - (headlen / 4) * Math.cos(angle - Math.PI / 2), 
                            y: fromy - (headlen / 4) * Math.sin(angle - Math.PI / 2)
                    },{
                            x: tox - (headlen / 4) * Math.cos(angle - Math.PI / 2), 
                            y: toy - (headlen / 4) * Math.sin(angle - Math.PI / 2)
                    }, {
                            x: tox - (headlen) * Math.cos(angle - Math.PI / 2),
                            y: toy - (headlen) * Math.sin(angle - Math.PI / 2)
                    },{
                            x: tox + (headlen) * Math.cos(angle),  // tip
                            y: toy + (headlen) * Math.sin(angle)
                    }, {
                            x: tox - (headlen) * Math.cos(angle + Math.PI / 2),
                            y: toy - (headlen) * Math.sin(angle + Math.PI / 2)
                    }, {
                            x: tox - (headlen / 4) * Math.cos(angle + Math.PI / 2),
                            y: toy - (headlen / 4) * Math.sin(angle + Math.PI / 2)
                    }, {
                            x: fromx - (headlen / 4) * Math.cos(angle + Math.PI / 2),
                            y: fromy - (headlen / 4) * Math.sin(angle + Math.PI / 2)
                    },{
                            x: fromx,
                            y: fromy
                    }
            ];

            this.callSuper('initialize', points, options);
            this.set('objectId', options.objectId || 0);
            this.set('floor', options.floor || 1);
            this.set('hidden', options.hidden || 0);
            this.on('modified', mapEditor.onModified);
            
            this.setShadow(mapEditor.defaultShadow);
        },

        toObject: function() {
            return fabric.util.object.extend(this.callSuper('toObject'), {
                objectId: this.get('objectId'),
                floor: this.get('floor'),
                hidden: this.get('hidden'),
            });
        },
        
        _render: function(ctx, noTransform) {
            
            // if this object belong to another floor than what's currently being displayed
            if( this.get('floor') != mapEditor.currentFloor ) return;
            
            // If this object is hidden
            if( !mapEditor.adjustHiddenObject(this, ctx) ) return;
            
            this.callSuper('_render', ctx);
        },
    }),
    
    bathroomIcon: fabric.util.createClass(fabric.Image, {
        type: 'bathroomIcon',
        fill: 'black',
        opacity: 1,
        stroke: 'black',
        strokeWidth: 0,
        cornerStyle: 'circle',
        hasRotatingPoint: true,
        snapAngle: 5,
        transparentCorners: false,
        
        initialize: function(options) {
            options || (options = { });

            this.callSuper('initialize', document.getElementById('bathroomIcon'), options);
            this.set('objectId', options.objectId || 0);
            this.set('floor', options.floor || 1);
            this.set('hidden', options.hidden || 0);
            this.on('modified', mapEditor.onModified);
        },

        toObject: function() {
            return fabric.util.object.extend(this.callSuper('toObject'), {
                objectId: this.get('objectId'),
                floor: this.get('floor'),
                hidden: this.get('hidden'),
            });
        },
        
        _render: function(ctx, noTransform) {
            
            // if this object belong to another floor than what's currently being displayed
            if( this.get('floor') != mapEditor.currentFloor ) return;
            
            // If this object is hidden
            if( !mapEditor.adjustHiddenObject(this, ctx) ) return;
            
            this.callSuper('_render', ctx);
        },
    }),
    
    cashRegisterIcon: fabric.util.createClass(fabric.Image, {
        type: 'cashRegisterIcon',
        fill: 'black',
        opacity: 1,
        stroke: 'black',
        strokeWidth: 0,
        cornerStyle: 'circle',
        hasRotatingPoint: true,
        snapAngle: 5,
        transparentCorners: false,
        
        initialize: function(options) {
            options || (options = { });

            this.callSuper('initialize', document.getElementById('cashRegisterIcon'), options);
            this.set('objectId', options.objectId || 0);
            this.set('floor', options.floor || 1);
            this.set('hidden', options.hidden || 0);
            this.on('modified', mapEditor.onModified);
        },

        toObject: function() {
            return fabric.util.object.extend(this.callSuper('toObject'), {
                objectId: this.get('objectId'),
                floor: this.get('floor'),
                hidden: this.get('hidden'),
            });
        },
        
        _render: function(ctx, noTransform) {
            
            // if this object belong to another floor than what's currently being displayed
            if( this.get('floor') != mapEditor.currentFloor ) return;
            
            // If this object is hidden
            if( !mapEditor.adjustHiddenObject(this, ctx) ) return;
            
            this.callSuper('_render', ctx);
        },
    }),
    
    deskIcon1: fabric.util.createClass(fabric.Image, {
        type: 'deskIcon1',
        fill: 'black',
        opacity: 1,
        stroke: 'black',
        strokeWidth: 0,
        cornerStyle: 'circle',
        hasRotatingPoint: true,
        snapAngle: 5,
        transparentCorners: false,
        
        initialize: function(options) {
            options || (options = { });

            this.callSuper('initialize', document.getElementById('deskIcon1'), options);
            this.set('objectId', options.objectId || 0);
            this.set('floor', options.floor || 1);
            this.set('hidden', options.hidden || 0);
            this.on('modified', mapEditor.onModified);
        },

        toObject: function() {
            return fabric.util.object.extend(this.callSuper('toObject'), {
                objectId: this.get('objectId'),
                floor: this.get('floor'),
                hidden: this.get('hidden'),
            });
        },
        
        _render: function(ctx, noTransform) {
            
            // if this object belong to another floor than what's currently being displayed
            if( this.get('floor') != mapEditor.currentFloor ) return;
            
            // If this object is hidden
            if( !mapEditor.adjustHiddenObject(this, ctx) ) return;
            
            this.callSuper('_render', ctx);
        },
    }),
    
    deskIcon2: fabric.util.createClass(fabric.Image, {
        type: 'deskIcon2',
        fill: 'black',
        opacity: 1,
        stroke: 'black',
        strokeWidth: 0,
        cornerStyle: 'circle',
        hasRotatingPoint: true,
        snapAngle: 5,
        transparentCorners: false,
        
        initialize: function(options) {
            options || (options = { });

            this.callSuper('initialize', document.getElementById('deskIcon2'), options);
            this.set('objectId', options.objectId || 0);
            this.set('floor', options.floor || 1);
            this.set('hidden', options.hidden || 0);
            this.on('modified', mapEditor.onModified);
        },

        toObject: function() {
            return fabric.util.object.extend(this.callSuper('toObject'), {
                objectId: this.get('objectId'),
                floor: this.get('floor'),
                hidden: this.get('hidden'),
            });
        },
        
        _render: function(ctx, noTransform) {
            
            // if this object belong to another floor than what's currently being displayed
            if( this.get('floor') != mapEditor.currentFloor ) return;
            
            // If this object is hidden
            if( !mapEditor.adjustHiddenObject(this, ctx) ) return;
            
            this.callSuper('_render', ctx);
        },
    })
};