import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LocationContainer from './LocationContainer';
import classes from './style/SearchByCity.module.css';
import { convertToURLFormat } from '../../utils/helpers';

const RenderLocations = ({ data }) => {
  return (
    Object.keys(data).map(prov => {
      return (
        <div key={prov} className={classes.ProvinceContainer}>
          <h3>{prov}</h3>
          <div className={classes.LocationContainer}>
            {Object.keys(data[prov]).map(location => {
              let modifiedURL = convertToURLFormat(location);
              return (
                <Link key={location} to={`/stores/city/${modifiedURL}`} className={`no-link ${classes.LocationWrapper}`}>
                  <LocationContainer>
                    <h4>{location}</h4>
                    <p>{`${data[prov][location]} locations`}</p>
                  </LocationContainer>
                </Link>
              )
            })}
          </div>
        </div>
      )
    })
  )
}

RenderLocations.propTypes = {
  data: PropTypes.object.isRequired,
}

export default RenderLocations;
