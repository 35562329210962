import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string'
import NotificationSystem from 'react-notification-system';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { IS_WEB_APP, RECAPTCHA_MOBILE_APP_CODE } from '../../constants/constants';
import ProjectForm from './ProjectForm';
import Spinner from '../layout/Spinner';
import ProgressBar from '../layout/ProgressBar';
import TitleContainer from '../layout/TitleContainer';
import classes from './style/CreateProject.module.css';
import { getKeywords, createRecipe, getProjectData, checkLoggedIn } from '../../api';

class CreateProject extends Component {
  state = {
    keywords: [],
    loading: true,
    dataLoading: false,
    focusCoverIndex: -1,
    video_og: [],
    title: '',
    project_url: '',
    changesPresent: '',
    checkboxState: false,
    project: {
      type_id: -1,
      list_id: 0,
      title: '',
      description: '',
      servings: 2,
      prep_time: '',
      ingredients: [
        { label: '', keyword: '' },
      ],
      instructions: '',
      images: [],
      videos: [],
      website_url: '',
      is_public: 0,
    }
  }

  coverRef = createRef();
  notificationSystem = createRef();
  type_obj = {
    1: 'recipe',
    2: 'homeproject',
    3: 'checklist'
  }

  async componentDidMount() {
    // get what project is being created
    let project_url = window.location.href.substring(window.location.href.lastIndexOf('/')+1, window.location.href.length);

    if(project_url.indexOf('?') > -1) project_url = project_url.substring(0, project_url.indexOf('?'));
    let title = "";
    if(project_url === "checklist") title="Checklist";
    else if (project_url === "homeproject") title="Home Project";
    else if (project_url === "recipe") title="Recipe";

    // Gets list_id from URL (for Edit mode)
    const searchObj = queryString.parse(this.props.location.search);
    const param_list_id = searchObj.list_id;

    // Sets Type ID
    const type_id_obj = {
      "recipe" : 1,
      "homeproject" : 2,
      "checklist": 3,
    };
    const type_id = type_id_obj[project_url];
    const res = await getKeywords(this.props.session_id);
    if( res.data.appuser_id !== null ) {
      let project = {};
      if(param_list_id === undefined){
        if( sessionStorage.getItem(`${project_url}_${type_id}`) !== null){
          project = {
            ...JSON.parse(sessionStorage.getItem(`${project_url}_${type_id}`)),
            images: [],
            videos: [],
          };
        } else {
          project = { ...this.state.project, type_id };
        }
      } else {
        // EDIT MODE
        const { session_id } = this.props;
        const res_auth = await checkLoggedIn(session_id);
        const res_list = await getProjectData(param_list_id, session_id);
        const { list, photos, videos, components } = res_list.data;

        // Check if user logged in matches with author of list;
        if(list.appuser_id !== res_auth.data.appuser_id) {
          alert('You are not authorized to edit this project');
          this.props.history.push('/');
        } else {
          // format images to be resubmitted
          const images = photos.map(photo => (
            {
              is_main: photo.is_main,
              img: photo.photo,
            }
          ));
          title = title + ` - ${list.title}`;
          project = {
            type_id: list.type_id,
            list_id: list.list_id,
            title: list.title,
            description: list.description,
            servings: list.servings,
            prep_time: list.prep_time,
            ingredients: components,
            instructions: list.instructions,
            images: images,
            videos: videos,
            is_public: list.is_public,
          }
        }
      }

      this.setState({ 
        keywords: res.data,
        loading: false,
        project_url: project_url,
        project,
        title,
      });
      
    } else {
      alert(res.data.msg);
    }
    document.addEventListener('mousedown', this.handleOutsideCoverClick);
  }

  componentDidUpdate(prevProps, prevState) {
    const { title, description, servings, prep_time, ingredients, instructions, is_public, type_id } = this.state.project;
    if( prevState.project.title !== title 
      || prevState.project.description !== description
      || prevState.project.servings !== servings
      || prevState.project.prep_time !== prep_time
      || prevState.project.ingredients.length !== ingredients.length
      || prevState.project.instructions !== instructions
      || prevState.project.is_public !== is_public
    ) {
      const label = this.type_obj[type_id] + '_' + type_id;
      let toStore = {...this.state.project};
      // not storing images into session storage
      delete toStore.images;
      delete toStore.videos;
      sessionStorage.setItem(label, JSON.stringify(toStore));
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideCoverClick);
  }

  // Handler for when Cover Photo settings is opened and outside click is detected
  handleOutsideCoverClick = (event) => {
    if(this.coverRef.current && !this.coverRef.current.contains(event.target)) {
      this.setState({ focusCoverIndex: -1 });
    }
  }

  // Handler for clicking on Add Ingredient button
  handleAddIngredient = e => {
    e.preventDefault();
    const newElement = { label: '', keyword: '' };
    this.setState({
      project: {
        ...this.state.project,
        ingredients: [
          ...this.state.project.ingredients,
          newElement,
        ]
      }
    });
  }

  // Handler for removing indredient
  handleRemoveIngredient = index => {
    const igArray = [...this.state.project.ingredients];
    igArray.splice(index, 1);
    this.setState({
      project: {
        ...this.state.project,
        ingredients: igArray
      }
    });
  }

  // Handler for getting shopping keywork when lost focus on input field
  handleFetchKeyword = id => {
    const { keywords } = this.state;
    const ingredient = this.state.project.ingredients[id].label;
    // if ingredients is not empty
    if( ingredient !== '' ) {
      const igArray = ingredient.split(' ');
      igArray.forEach((ig, index) => {
        // also checks for two worded keywords
        let double = '';
        if( index > 0 ) double = igArray[index-1] + ' ' + ig;
        keywords.forEach(key => {
          // if there is a keyword match, adds it
          if( index > 0 && double.toLowerCase() === key.toLowerCase() ) {
            const newArray = [ ...this.state.project.ingredients ];
            newArray[id].keyword = key;
            this.setState({
              project: {
                ...this.state.project,
                ingredients: newArray,
              }
            });
            this.setState({ ingredients: newArray });
          }else if( ig.toLowerCase() === key.toLowerCase()) {
            const newArray = [ ...this.state.project.ingredients ];
            newArray[id].keyword = key;
            this.setState({
              project: {
                ...this.state.project,
                ingredients: newArray,
              }
            });
          }
        });
      });
    }
  }

  // Handler for manually adding keyword
  handleTypeAheadTextChange = ( index, value ) => {
    const ingredients = [...this.state.project.ingredients];
    ingredients[index].keyword = value;
    this.setState({
      project: {
        ...this.state.project,
        ingredients
      }
    });
  }

  // Handler for uploading an image
  // Image submission
  handleImageUpload = async e => {
    const file = [ ...e.target.files ];
    const existingImg = [ ...this.state.project.images ];
    const base64 = await Promise.all( file.map( file => this.convertBase64( file ) ) );
    // sets is_main for first image as 1 (cover photo);
    const imgArray = existingImg.length > 0 
      ? base64.map( img => ( { img, is_main: 0 } ))
      : base64.map(( img, id ) => ( { img, is_main: id === 0 ? 1 : 0 } ) );
    const images = [...existingImg, ...imgArray];
    this.setState({ 
      project: {
        ...this.state.project,
        images,
      }
    });
  }

  // Function that converts image into a base64 encoded
  convertBase64 = file => {
    return new Promise(( resolve, reject ) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.onerror = error => reject(error);
    });
  }

  handleRemoveImage = index => {
    const images = [ ...this.state.project.images ];
    if (images.length > 1 && images[index].is_main === 1) {
      this.addNotification(
        'Cannot Remove Cover Photo',
        'Cover photo cannot be removed. Please select another cover photo before deleting',
        'error'
      )
    } else images.splice(index, 1);
    this.setState({
      project: {
        ...this.state.project,
        images,
      },
      focusCoverIndex: -1,
    })
  }

  handleRemoveAllImage = () => {
    this.setState({
      project: {
        ...this.state.project,
        images: []
      },
      focusCoverIndex: -1,
    })
  }

  // Handler for setting a new cover photo
  handleSetCover = index => {
    const images = [ ...this.state.project.images ];
    images.forEach(( img, id ) => {
      if(img.is_main === 1 && id !== index) img.is_main = 0;
      else if ( id === index ) img.is_main = 1;
    });
    this.setState({
      project: {
        ...this.state.project,
        images
      },
      focusCoverIndex: -1,
    })
  }

  handleImageClick = index => this.setState({ focusCoverIndex: index });

  // Handler for removing video id
  handleRemoveVideoInput = index => {
    const video = [ ...this.state.video_og ];
    video.splice(index, 1);
    this.setState({ video_og: video });
    const video_id = [...this.state.project.videos];
    video_id.splice(index, 1);
    this.setState({
      project: {
        ...this.state.project,
        videos: video_id
      }
    })
  }

  // Handler for updating video id for previews
  updateVideoPreview = (e, index) => {
    const video_url = this.state.video_og[index];
    const video_id_arr = [...this.state.project.videos];

    const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
    const match = video_url.match(regExp);
    let dupe;
    if(match && match[1].length === 11){
      dupe = video_id_arr.filter(vid => vid.video === match[1]);
    } else {
      video_id_arr[index] = false
      this.setState({
        project: {
          ...this.state.project,
          videos: video_id_arr,
        }
      });
      this.addNotification(
        'Invalid URL',
        'You have entered an invalid URL. Please enter a valid Youtube URL',
        'error'
      );
      return;
    }
    if( dupe.length === 0 ){
      video_id_arr[index] = { video: match[1] };
      this.setState({
        project: {
          ...this.state.project,
          videos: video_id_arr
        }
      });
    } else {
      this.addNotification(
        'Duplicate Video',
        'You have entered a URL that has already been added. It will only be added to the database once',
        'error'
      );
    }
  }

  handleVideoChange = (e, index) => {
    const video_url = [ ...this.state.video_og ];
    video_url[index] = e.target.value;
    this.setState({ video_og: video_url });
  }

  // Handler for clicking on an option in the cover photo options
  handleOptionClick = (option, value) => {
    switch(option) {
      case 'removeImg':
        return this.handleRemoveImage(value);
      case 'removeAllImg':
        return this.handleRemoveAllImage();
      case 'setCover':
        return this.handleSetCover(value);
      case 'add_video_input':
        return this.setState(prevState => ({ video_og: [...prevState.video_og, ''] }));
      case 'remove_video_input':
        return this.handleRemoveVideoInput(value);
      default:
        break;
    }
  }

  handleInstructionChange = value => {
    this.setState({
      project: {
        ...this.state.project,
        instructions: value,
      }
    })
  }

  // Handler for options change on Input Field in Ingredients list
  handleOptionChange = ( e, index ) => {
    const ingredients = [...this.state.project.ingredients];
    ingredients[index][e.target.name] = e.target.value;
    this.setState({
      project: {
        ...this.state.project,
        ingredients
      }
    });
  }

  // Handler for editing ingredients list
  handleChange = e => {
    // converts to int if change coming from privacy option
    if( e.target.name === 'is_public' ) {
      this.setState({
        project: {
          ...this.state.project,
          [ e.target.name ] : parseInt( e.target.value )
        }
      });
    } else {
      this.setState({
        project: {
          ...this.state.project,
          [ e.target.name ] : e.target.value
        }
      });
    }
  }

  // Handler for submitting the form
  handleFormSubmit = async e => {
    e.preventDefault();
    const { 
      project: {
        title,
        description,
        servings,
        prep_time,
        ingredients,
        instructions,
        type_id,
        videos,
      },
      project,
      project_url,
    } = this.state;

    var recaptcha = RECAPTCHA_MOBILE_APP_CODE;
    if( IS_WEB_APP ) {
      recaptcha = await this.props.googleReCaptchaProps.executeRecaptcha('login');
    }

    if( title.length === 0 
      || description.length === 0 
      || (servings === '' && project_url !== 'checklist') 
      || (prep_time === '' && project_url !== 'checklist')
      || ingredients.length === 0 
      || (instructions.length === 0 && project_url !== 'checklist'))
    {
      this.addNotification(
        'Invalid Input Parameters',
        'Mandatory input fields cannot be empty',
        'error',
      );
    } else if( videos.length > 0 && videos.includes(false)) {
      this.addNotification(
        'Invalid YouTube Video URL',
        'You cannot submit a form with a non-youtube video',
        'error'
      );
    } else {
      this.setState({ dataLoading: true });
      const res = await createRecipe( project, recaptcha, this.props.session_id );
      this.setState({ dataLoading: false });

      if( res.status === 200 ) {
        this.addNotification(
          'Project Successfully created',
          `The project for ${title} has been created`,
          'success'
        );
        // get key from value;
        const obj_type_value = this.type_obj[type_id];
        const obj_type_id = Object.keys(this.type_obj).find(key => this.type_obj[key] === this.type_obj[type_id]);
        const label = `${obj_type_value}_${obj_type_id}`; // e.g. recipe_1
        if( sessionStorage.getItem(label) !== null ){
          sessionStorage.removeItem(label);
        }

        setTimeout(() => {
          this.props.history.push('/explore/my-lists');
        }, 2000);
      } else {
        this.addNotification(
          'Error',
          res.data.msg,
          'error'
        );
      }
    }
  }

  handleCheckboxChange = e => this.setState({ checkboxState: e.target.checked })

  addNotification = (title, message, type) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title,
      message,
      level: type,
      position: 'tc',
      autoDismiss: 3,
    })
  }

  render() {
    const {
      loading,
      checkboxState,
      title,
      project,
      keywords,
      project_url,
      focusCoverIndex,
      video_og,
      dataLoading,
    } = this.state;

    if( loading ) return <Spinner />

    return (
      <div className={`container padded ${classes.CreateProject}`}>
        <TitleContainer title="Create Recipe" />
        <NotificationSystem ref={this.notificationSystem} />
        
        <button className="bt-back" onClick={() => this.props.history.goBack()}><i className="fa fa-angle-left"></i> Back</button>
        {dataLoading && <div className={classes.DataSpinner}><ProgressBar /></div>}
        <ProjectForm
          project={project}
          title={title}
          keywords={keywords}
          focusCoverIndex={focusCoverIndex}
          video_og={video_og}
          project_url={project_url}
          checkboxState={checkboxState}
          coverRef={this.coverRef}
          onchange={this.handleChange}
          onOptionChange={this.handleOptionChange}
          onAddIngredient={this.handleAddIngredient}
          onRemoveIngredient={this.handleRemoveIngredient}
          onFetchKeyword={this.handleFetchKeyword}
          onTextUpdate={this.handleTypeAheadTextChange}
          onImageChange={this.handleImageUpload}
          onImageClick={this.handleImageClick}
          onRemoveImage={this.handleRemoveImage}
          onFormSubmit={this.handleFormSubmit}
          onInstructionChange={this.handleInstructionChange}
          onOptionClick={this.handleOptionClick}
          onRemoveVideoFocus={this.updateVideoPreview}
          onVideoChange={this.handleVideoChange}
          onCheckboxChange={this.handleCheckboxChange}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  session_id: state.authReducer.session_id,
})

export default connect(mapStateToProps)(withGoogleReCaptcha(CreateProject));