import React from 'react';
import { Link } from 'react-router-dom';
import bgImage from '../../img/bg-mapper.png';
import classes from './style/Landing.module.css';

const Mapper = () => {
  return (
    <div className={classes.MapperApplication}>
      <img src={bgImage} alt=""/>
      <div className={classes.Wrapper}>
        <div>
          <h2><em>Get paid to be a Mapper</em></h2>
          <p>Apply to join Spot It's growing network of store mappers and help people shop safer</p>
          <Link to="mapper-application">Apply Now</Link>
        </div>
      </div>
    </div>
  )
}

export default Mapper;