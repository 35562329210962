import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classes from './style/TripsContainer.module.css';
import { IS_WEB_APP } from '../../constants/constants';

const Advertisement = ({ click }) => {
  return (
    <div className={classes.AdContainer}>
      <div className={classes.CloseButton} onClick={click}></div>
      <h3>Plan safer and faster shopping trips with Spot it</h3>
      <p>Download the FREE app now on Android and iOS</p>
      {IS_WEB_APP && 
      <Fragment>
        <a href="https://play.google.com/store/apps/details?id=com.spotitmapping.mobileapp" target="_blank" rel="noopener noreferrer"><img src={require('../../img/android_logo.png')} alt="android.png"/></a>
        <a href="https://apps.apple.com/ca/app/spot-it/id1529187675" target="_blank" rel="noopener noreferrer"><img src={require('../../img/ios_logo.png')} alt="ios.png"/></a>
      </Fragment>}
    </div>
  )
}

Advertisement.propTypes = {
  click: PropTypes.func,
}

export default Advertisement;
