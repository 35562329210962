import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import BrandContainer from '../layout/BrandContainer';
import classes from './style/SearchByBrand.module.css';
import { convertToURLFormat } from '../../utils/helpers';

const RenderStores = ({ data, logos, urlParam }) => {
  if( Object.keys(data).length === 0 && urlParam === 'sales') {
    return (
      <p><em>No Sales Found</em></p>
    )
  }
  return (
    Object.keys(data).map(store => {
      // modify store name to remove special characters and whitespaces
      let modifiedURL = convertToURLFormat(store);
      return (
        <Link className={`${classes.BrandContainer} no-link`} key={store} to={`/${urlParam}/${modifiedURL}`}>
          <BrandContainer>
            <img src={logos[store]} alt="logo"/>
            <div>
              <h4>{store}</h4>
              <p>{`${data[store]} locations`}</p>
            </div>
          </BrandContainer>
        </Link>
      )
    })
  )
}

RenderStores.propTypes = {
  data: PropTypes.object.isRequired,
  logos: PropTypes.object,
  urlParam: PropTypes.string.isRequired,
}

export default RenderStores;
