import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { IS_WEB_APP, RECAPTCHA_MOBILE_APP_CODE } from '../../constants/constants';
import NotificationSystem from 'react-notification-system';
import TitleContainer from '../layout/TitleContainer';
import Spinner from '../layout/Spinner';
import FormGroup from '../layout/FormGroup';
import Acknowlegement from './Acknowlegement';
import PrivacyPolicy from './PrivacyPolicy';
import TermsConditions from './TermsConditions';
import Modal from '../layout/modal/Modal';
import { createUser } from '../../api';
import { SHOW_MODAL, HIDE_MODAL } from '../../actions';
import classes from './style/Login.module.css';

class SignUpForm extends Component {
  state = {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    password2: '',
    loading: false,
    isPassVisible: false,
    privacyState: false,
    termsConditionsState: false,
    checkBoxState: false,
  }

  notificationSystem = createRef();

  componentDidUpdate(prevProps, prevState) {
    // On Modal Hide, un-check checkbox
    if(prevProps.modal && !this.props.modal) {
      this.setState({ checkBoxState: false })
    }
  }

  handleSubmit = async () => {
    const { firstName, lastName, username, email, password } = this.state;

    var recaptcha = RECAPTCHA_MOBILE_APP_CODE;
    if( IS_WEB_APP ) {
      recaptcha = await this.props.googleReCaptchaProps.executeRecaptcha('login');
    }
    const res = await createUser({
      first_name: firstName,
      last_name: lastName,
      username,
      email,
      password,
      recaptcha
    });
    if(res.data.success && !res.data.error) {
      this.setState({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
      });
      this.addNotification(
        'Successfully Created',
        res.data.msg,
        'success',
      );
      setTimeout(() => {
        this.props.history.push('/login');
      }, 1000);
    } else {
      this.addNotification(
        'Error',
        res.data.msg,
        'error',
      );
    }
  }

  showModal = (e) => {
    e.preventDefault();
    const { firstName, lastName, username, email, password, password2 } = this.state;

    if( firstName === '' || lastName === '' || username === '' || email === '' ) {
      this.addNotification(
        'Empty Input',
        'Required input field cannot be empty',
        'error',
      );
    } else if (password !== password2 || (password === '' || password2 === '')) {
      this.addNotification(
        'Password Mismatch',
        'The passwords did not match',
        'error'
      );
    } else {
      this.setState({
        privacyState: true,
        termsConditionsState: false,
      });
      this.props.showModal();
      document.body.classList.add('modal-open');
    }
  }

  addNotification = (title, message, type) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title,
      message,
      level: type,
      position: 'tc',
      autoDismiss: 3,
    });
  }

  handleAcceptTerms = (e, currentFocus) => {
    e.preventDefault();
    if(currentFocus === 'privacy') {
      this.setState({
        checkBoxState: false,
        termsConditionsState: true,
        privacyState: false,
      });
    } else {
      this.props.hideModal();
      document.body.classList.remove('modal-open');
      this.setState({
        checkBoxState: false,
        termsConditionsState: false,
        privacyState: false,
      });
      this.handleSubmit();
    }
  }

  handleCancel = (focus) => {
    if(focus === "privacy") {
      this.props.hideModal();
      document.body.classList.remove('modal-open');
    } else {
      this.setState({
        privacyState: true,
        termsConditionsState: false
      })
    }
  }

  handleCheckboxChange = e => this.setState({ checkBoxState: e.target.checked });
  toggleVisiblePassword = () => this.setState(prevState => ({ isPassVisible: !prevState.isPassVisible }));
  handleChange = e => this.setState({ [e.target.name] : e.target.value });

  render(){
    const { 
      firstName,
      lastName,
      username,
      email,
      password,
      password2,
      loading,
      isPassVisible,
      privacyState,
      termsConditionsState,
      checkBoxState,
    } = this.state;

    if(loading) return <Spinner />;

    let focus = '';
    if(privacyState) focus = "privacy";
    else if (termsConditionsState) focus = "termsconditions";
    else focus = "";

    return (
      <div className={classes.FormContainer}>
        <TitleContainer title="Sign Up" />
        <NotificationSystem ref={this.notificationSystem} />
        <Modal>
          <Acknowlegement 
            focus={focus}
            checkBoxState={checkBoxState}
            oncancel={this.handleCancel} 
            onchange={this.handleCheckboxChange}
            onaccept={this.handleAcceptTerms}
          >
            {privacyState && !termsConditionsState && <PrivacyPolicy />}
            {termsConditionsState && !privacyState && <TermsConditions />}
          </Acknowlegement>
        </Modal>
        <div className={classes.FormBody}>
          <form id="signup-form">
            <div className={classes.NameContainer}>
              <FormGroup name="firstName" label="First Name" placeholder="" required={true} value={firstName} onchange={this.handleChange} />
              <FormGroup name="lastName" label="Last Name" placeholder="" required={true} value={lastName} onchange={this.handleChange} />
            </div>
            <FormGroup name="username" label="Username" placeholder="" required={true} value={username} onchange={this.handleChange} />
            <FormGroup name="email" label="Email Address" type="email" placeholder="" required={true} value={email} onchange={this.handleChange} />
            <FormGroup name="password" label="Password" type={isPassVisible ? "text" : "password"} placeholder="" required={true} value={password} onchange={this.handleChange} >
              <i onClick={this.toggleVisiblePassword} className={isPassVisible ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true"></i>
            </FormGroup>
            <FormGroup name="password2" label="Confirm Password" type={isPassVisible ? "text" : "password"} placeholder="" required={true} value={password2} onchange={this.handleChange} >
              <i onClick={this.toggleVisiblePassword} className={isPassVisible ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true"></i>
            </FormGroup>
          </form>
        </div>
        <div className={classes.FormFooter}>
          <button onClick={() => this.props.history.goBack()}>Cancel</button>
          <button onClick={this.showModal} form="signup-form">Sign Up</button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  modal: state.modalReducer.displayModal,
});

const mapDispatchToProps = dispatch => ({
  showModal: () => dispatch({ type: SHOW_MODAL }),
  hideModal: () => dispatch({ type: HIDE_MODAL }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withGoogleReCaptcha(SignUpForm)));