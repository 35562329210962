import React from 'react';
import { Link } from 'react-router-dom';
import { Mobile } from './MediaQueries';
import classes from './style/SubMenu.module.css';

const SubMenu = ({ onclick }) => (
  <div className={classes.SubMenu}>
    <Mobile>
      <Link to="/request-store">Request Store</Link>
    </Mobile>
    <Link to="/mapper-application">Apply to be a Mapper</Link>
    <Link to="/change-password">Change Password</Link>
    <p onClick={() => onclick('logout')}>Log Out</p>
  </div>
)

export default SubMenu;