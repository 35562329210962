// Modified code from https://medium.com/@svsh227/create-your-own-type-ahead-dropdown-in-react-599c96bebfa
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from './style/TypeAhead.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_SUBMITTED } from '../../actions';

const TypeAhead = ({ items, getItem, searchClicked, onfocus }) => {
  const inputRef = useRef();
  const [text, setText] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [width, setWidth] = useState('');
  const [ cursor, setCursor ] = useState(-1);
  const [ hovered, setHovered ] = useState('');
  const submitted = useSelector(state => state.storeReducer.submitFlag);
  const dispatch = useDispatch();

  useEffect(() => {
    function handleResize() {
      const width = document.querySelector('.typeahead').parentNode.offsetWidth;
      setWidth(`${width}px`);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return function cleanup() {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    if (suggestions.length && hovered) {
      setCursor(suggestions.indexOf(hovered));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered]);

  useEffect(() => {
    if(suggestions.length > 0) setText(suggestions[cursor]);
    // update parent text state
    getItem(suggestions[cursor]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor]);

  useEffect(() => {
    if(submitted) {
      setText('');
      setSuggestions([]);
      setCursor(-1);
      dispatch({ type: FORM_SUBMITTED });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted]);

  const onTextChange = (e) => {
    let suggestions = [];
    const value = e.target.value;
    if(value.length > 0) {
      const regex = new RegExp(`^${value}`, `i`);
      suggestions = items.sort().filter(v => regex.test(v));
    }
    setSuggestions(suggestions);
    setText(value);
    getItem(value, false);
  }

  const suggestionSelected = (item) => {
    setText(item);
    setSuggestions([]);
    getItem(item, true);
    // Will make input field focus when selection is made
    inputRef.current.focus();
  }

  const handleKeyDown = (e) => {
    // Down Arrow
    if(suggestions.length && e.keyCode === 40){
      setCursor(prevState => 
        prevState < suggestions.length - 1 ? prevState + 1 : prevState  
      )
    } else if(e.keyCode === 38){ // Up Arrow
      if(cursor === 0) setCursor(0);
      else setCursor(prevState => (prevState > -1 ? prevState - 1 : prevState));
    }
  }

  const renderSuggestions = () => {
    if(suggestions.length === 0) return null;

    return (
      <ul style={{width: `${width}`}}>
        {suggestions.map((item, index) => {
          return (
            <li 
              key={index}
              className={`${cursor === index ? classes.Active : ''}`}
              onClick={(e) => suggestionSelected(item)}
              onMouseEnter={() => setHovered(item)}
              onMouseLeave={() => setHovered(undefined)}
            >
                {item}
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <div className={classes.TypeAheadDropDown}>
      <div style={{display: 'flex'}}>
        <input className="typeahead" type="text" value={text} placeholder="Search Item" onChange={onTextChange} ref={inputRef} onKeyDown={handleKeyDown} onFocus={() => onfocus(true)} onBlur={() => onfocus(false)} />
        <i className="fa fa-search" onClick={searchClicked}></i>
      </div>
      {renderSuggestions()}
    </div>
  )
}

TypeAhead.propTypes = {
  searchClicked: PropTypes.func.isRequired,
  getItem: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
}

export default TypeAhead;
