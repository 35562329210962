import React, { useEffect, useState, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Prompt } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import StoreItem from './StoreItem';
import StoreMap from './StoreMap';
import Spinner from '../layout/Spinner';
import TitleContainer from '../layout/TitleContainer';
import NoMatchPage from '../no-match/NoMatchPage';
import NotificationSystem from 'react-notification-system';
import { IsNotMobile, Mobile } from '../layout/MediaQueries';
import { getStore, getBrandsList } from '../../api';
import { convertToURLFormat } from '../../utils/helpers';
import classes from './style/Store.module.css';
import { 
  CLEAR_LIST, 
  CLEAR_STORE, 
  LOAD_SHOPPING_LIST,
  QUICKADD_DISPLAY_TRUE,
  QUICKADD_TRUE,
  RESET_QUICKADD,
  RESET_SELECTED_QUICKADD,
  STORE_BRANDS,
  STORE_KEYWORDS,
  SHOW_REPORT_MISTAKE,
  UPDATE_STORE,
  HIDE_REPORT_MISTAKE,
} from '../../actions';

const Store = ({ match, location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notificationSystem = useRef();

  const shopping_list = useSelector(state => state.shopListReducer.shopping_list);
  const imp_brands = useSelector(state => state.dataReducer.brands);
  const quickadds = useSelector(state => state.quickAddReducer.quickAdd);

  const [ data, setData ] = useState({});
  const [ redirect, setRedirect ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  // state that determines to determine Add Button (true) or Update Button (false)
  const [ addUpdate, setAddUpdate ] = useState(true);
  // stores all information that will be passed down
  const [ infoProps, setProps ] = useState({});
  const [ tripId, setTripId ] = useState('');

  // Keeps track of shopping list that is initially loaded and whether there are unsaved changes
  const [ loadedShopList, setLoadedShopList ] = useState([]);
  const [ unsavedChanges, setUnsavedChanges ] = useState(false);

  const store_id = match.params.id;
  const store_name = match.params.name;
  const searchObj = queryString.parse(location.search);
  
  useEffect(() => {
    // Fetches store data from server
    const fetchStoreData = async () => {
      let language = localStorage.getItem('language');

      // calls to api
      const {data} = await getStore({ store_id, language });
      setData(data);

      let brandsData = {};
      if(Object.keys(imp_brands).length === 0) {
        const res_brands = await getBrandsList();
        brandsData = res_brands.data;
        dispatch({ type: STORE_BRANDS, payload: brandsData });
      } else { brandsData = imp_brands; }
      
      // Stores logo of the store
      const logo = brandsData[data.store.brand_id].logo;
      
      const imp_store_name = convertToURLFormat(brandsData[data.store.brand_id].name);
      if(store_name.toLowerCase() !== imp_store_name ){
        setRedirect(true);
      }
      
      // Get Keywords for items (list of items)
      let keywords = fetchKeywords(data);
      dispatch({ type: STORE_KEYWORDS, payload: keywords });

      getTripList();
      displayQuickAdd(keywords);

      // List of props/information that will be passed to child component
      setProps({
        store_name: data.store.name,
        store_brand: brandsData[data.store.brand_id].name,
        store_address: `${data.store.street_address}, ${data.store.city}, ${data.store.province} ${data.store.postal_code}`,
        id: store_id,
        short_name: store_name,
        logo
      });

      dispatch({ type: SHOW_REPORT_MISTAKE });
      dispatch({ type: UPDATE_STORE, store_id })

      setLoading(false);
    }
    fetchStoreData();
    // Clears Store and Shopping List on unmounting
    // Cleanup function
    return () => {
      dispatch({ type: CLEAR_LIST });
      dispatch({ type: CLEAR_STORE });
      dispatch({ type: RESET_QUICKADD });
      dispatch({ type: LOAD_SHOPPING_LIST, payload: [] });
      dispatch({ type: HIDE_REPORT_MISTAKE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    for(let i = 0; i < shopping_list.length; i++){
      if(loadedShopList.length !== shopping_list.length ||
      loadedShopList[i].name !== shopping_list[i].name ||
      loadedShopList[i].completed !== shopping_list[i].completed) {
        setUnsavedChanges(true);
        break;
      } else if (loadedShopList.length === shopping_list.length && 
      loadedShopList[i].name === shopping_list[i].name && 
      loadedShopList[i].completed === shopping_list[i].completed) {
        setUnsavedChanges(false);
      }
    }
    dispatch({ type: RESET_SELECTED_QUICKADD });
    getQuickAdd(shopping_list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopping_list]);

  // function that determines if there's a trip that was already created for this store
  const getTripList = () => {
    const trips = JSON.parse(localStorage.getItem('trips'));
    if(trips === null) return;

    // checks if a trip can be found for current store
    let myTrip = trips.find(trip => trip.store_id === store_id);

    if(myTrip) {
      if(searchObj.id === undefined) {
        history.push(`/stores/${store_name}/${store_id}?id=${myTrip.trip_id}`);
      }
      setTripId(myTrip.trip_id);
      setAddUpdate(false);
      setLoadedShopList([...myTrip.shopping_list]);
      dispatch({ type: LOAD_SHOPPING_LIST, payload: myTrip.shopping_list });
      getQuickAdd(myTrip.shopping_list);
    }    
  }

  // Function that determines whether a quickadd should be selected or not
  const getQuickAdd = (shop_list) => {
    quickadds.forEach((qaItem, index) => {
      shop_list.forEach(list => {
        if( qaItem.name.toLowerCase() === list.name.toLowerCase() || 
        qaItem.name.toLowerCase() === list.keyword.toLowerCase() ) {
          dispatch({ type: QUICKADD_TRUE, id: index });
        }
      });
    });
  }

  // Determines which quick adds to display
  const displayQuickAdd = (keywords) => {
    quickadds.forEach((qaItem, index) => {
      keywords.forEach(keyItem => {
        if(qaItem.name.toLowerCase() === keyItem.toLowerCase()){
          dispatch({ type: QUICKADD_DISPLAY_TRUE, id: index });
        }
      });
    });
  }

  // Fetches all the keywords of the store
  const fetchKeywords = (data) => {
    let keywords = [];
    for( var i=0; i < data.mapObjects.length; i++ ) {
      var item = data.mapObjects[i];
      if( 'keywordsA' in item && Array.isArray(item['keywordsA']) && item['keywordsA'].length > 0 ) {
          keywords = keywords.concat(item['keywordsA']);
      }
      if( 'keywordsB' in item && Array.isArray(item['keywordsB']) && item['keywordsB'].length > 0 ) {
          keywords = keywords.concat(item['keywordsB']);
      }
    }

    // Add keywords for synonyms
    Object.keys(data.synonyms).forEach(keyword => {
      keywords.push(keyword);
    });

    // removes duplicates and sort
    keywords = [...new Set(keywords)].sort();
    
    return keywords;
  }

  const handleUpdateUnsaved = (bool) => {
    setLoadedShopList([ ...shopping_list ]);
    setUnsavedChanges(bool);
  }

  const addNotification = (title, message, type) => {
    const notification = notificationSystem.current;
    notification.addNotification({
      title,
      message,
      level: type,
      position: 'tc', // top-center
      autoDismiss: 3,
    })
  }

  if(loading) return <Spinner />
  if(!loading && redirect) return <NoMatchPage />
  
  return (
    <Fragment>
      <Prompt
        when={unsavedChanges}
        message="You have unsaved changes. Are you sure you want to leave the page?"
      />
      <IsNotMobile>
        <div className={`${classes.Store} container`} id="store">
          <TitleContainer title={`${infoProps.store_brand} - ${store_id}`} />
          <NotificationSystem ref={notificationSystem} className={classes.Notification} />
          <StoreItem 
            trip_id={tripId} 
            tripButton={addUpdate} 
            info={infoProps} 
            updateButton={flag => setAddUpdate(flag)} 
            addNotification={addNotification} 
            updateUnsaved={unsaved => handleUpdateUnsaved(unsaved)}/>
          <StoreMap data={data} />
        </div>
      </IsNotMobile>
      <Mobile>
        <StoreItem 
          data={data}
          trip_id={tripId}
          tripButton={addUpdate}
          info={infoProps}
          unsavedChanges={unsavedChanges}
          updateButton={flag => setAddUpdate(flag)} 
          addNotification={addNotification}
          updateUnsaved={(unsaved) => handleUpdateUnsaved(unsaved)}
        />
        <NotificationSystem ref={notificationSystem} className={classes.Notification} />
      </Mobile>
    </Fragment>
  )
}

Store.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired
}

export default Store;