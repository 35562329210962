import React, { Fragment } from 'react';
import spinner from './spinner.gif';

const Spinner = ({ children }) => {
    return (
        <Fragment>
            <div style={spinnerStyle}>
                <img src={spinner} alt="Loading..."/>
                {children}
            </div>
        </Fragment>
    )
}

const spinnerStyle = {
    width: '200px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '1000',
}

export default Spinner;