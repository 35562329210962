import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import BrandContainer from '../layout/BrandContainer';
import classes from './style/SearchByMyLocation.module.css';
import { convertToURLFormat } from '../../utils/helpers';

const RenderProximityStores = ({ stores }) => {
  return (
    stores.map(store => {
      let modifiedURL = convertToURLFormat(store.brand_name);
      return (
        <Link key={store.store_id} to={`/stores/${modifiedURL}/${store.store_id}`} className={`no-link ${classes.LocationWrapper}`}>
          <BrandContainer>
            <img src={store.logo} alt="store_logo"/>
            <div>
              <h4>{store.name}</h4>
              <p>{`${Math.round(store.distance_km*2)/2} km (${Math.round(store.distance_miles*2)/2} miles)`}</p>
            </div>
          </BrandContainer>
        </Link>
      )
    })
  )
}

RenderProximityStores.propTypes = {
  stores: PropTypes.array.isRequired,
}

export default RenderProximityStores;
