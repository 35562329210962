import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ShoppingList from './ShoppingList';
import TypeAhead from './TypeAhead';
import QuickAdd from './QuickAdd';
import { HIDE_DOWNLOAD, SHOW_DOWNLOAD } from '../../actions';

const TabsShop = ({
  onsubmit,
  keywords,
  getItem,
  searchclicked,
  quickAdd,
  onclose,
  onQuickAdd,
  showQuickAdd
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: SHOW_DOWNLOAD });
    return () => dispatch({ type: HIDE_DOWNLOAD });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFocus = () => {}
  return (
    <Fragment>
      <form onSubmit={onsubmit}>
        <TypeAhead items={keywords} getItem={getItem} searchClicked={searchclicked} onfocus={()=>handleFocus()}/>
      </form>
      {showQuickAdd &&
      <QuickAdd
        quickAdd={quickAdd}
        onclose={onclose} 
        onQuickAdd={onQuickAdd}
      />}
      <ShoppingList />
    </Fragment>
  )
}

TabsShop.propTypes = {
  items: PropTypes.array,
  quickAdd: PropTypes.array.isRequired,
  onclose: PropTypes.func.isRequired,
  onQuickAdd: PropTypes.func.isRequired,
  showQuickAdd: PropTypes.bool.isRequired,
  searchClicked: PropTypes.func,
  getItem: PropTypes.func.isRequired,
  onsubmit: PropTypes.func.isRequired,

}

export default TabsShop;