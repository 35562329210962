import React from 'react';
import { useDispatch } from 'react-redux';
import { HIDE_MODAL } from '../../../actions';
import classes from './style/ModalHeader.module.css';

const ModalHeader = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch({ type: HIDE_MODAL });
    document.body.classList.remove('modal-open');
  }
  return (
    <div className={classes.ModalHeader}>
      <h4>Select a Store to add the Recipe</h4>
      <span onClick={handleClick}></span>
    </div>
  )
}

export default ModalHeader;