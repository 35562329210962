import React, { useState, Fragment } from 'react';
import { SlideDown } from 'react-slidedown';
import PropTypes from 'prop-types';
import classes from './style/QuickAdd.module.css';
import 'react-slidedown/lib/slidedown.css';

const QuickAdd = ({ quickAdd, onclose, onQuickAdd }) => {
  const [ show, setShow ] = useState(true);
  
  return (
    <div className={classes.QuickAdd}>
      <div className={classes.CloseBtn} onClick={onclose}></div>
      <h4>Quick add <span className={show ? '' : classes.Rotated} onClick={() => setShow(!show)}></span></h4>
      <SlideDown closed={show} transitionOnAppear={false}>
        {quickAdd.map((list, index) => {
          return (
            <Fragment key={index}>
              {list.display && 
              <div className={classes.ItemContainer} onClick={() => onQuickAdd(index)}>
                <i className={`${list.selected ? 'fas' : 'far'} fa-plus-square`} aria-hidden="true"></i>
                <p>{list.name}</p>
            </div>}
            </Fragment>
          )
        })}
      </SlideDown>
    </div>
  )
}

QuickAdd.propTypes = {
  quickAdd: PropTypes.array.isRequired,
  onclose: PropTypes.func.isRequired,
  onQuickAdd: PropTypes.func.isRequired
}

export default QuickAdd;

