import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Mobile, IsNotMobile } from '../layout/MediaQueries';
import classes from '../explore/style/ExploreHeader.module.css';


const TripsHeader = () => {
  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn);
  const userInfo = useSelector(state => state.authReducer.userInfo);
  return (
    <div className={classes.ExploreHeader}>
      {isLoggedIn ? <h1>{`${userInfo.first_name}'s Trips`}</h1> : <h1>My Trips</h1>}
      <IsNotMobile>
        <Link to="/stores">+ Add Store</Link>
      </IsNotMobile>
      <Mobile>
        <Link to="/stores"><p>+</p><p>Add</p></Link>
      </Mobile>
    </div>
  )
}

export default TripsHeader;