import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Mobile, IsNotMobile } from '../layout/MediaQueries';
import BrandContainer from '../layout/BrandContainer';
import classes from './style/StoreByCity.module.css';

const RenderStores = ({ stores }) => {
  return (
    Object.keys(stores).map(brand => {
      return (
        <div key={brand} className={classes.StoreContainer}>
          <h3>{brand}</h3>
          {stores[brand].map((store, index) => {
            const { short_name, id } = store;
            return (
              <Link key={`${brand}_${index}`} to={`/stores/${short_name}/${id}`} className={`${classes.StoreWrapper} no-link`}>
                <BrandContainer>
                  <img src={store.logo} alt="logo"/>
                  <div>
                    <IsNotMobile><h4>{store.name}</h4></IsNotMobile>
                    <Mobile><h4>{store.name.length > 20 ? `${store.name.substr(0, 20)}...` : store.name}</h4></Mobile>
                    <p>{store.address}</p>
                  </div>                    
                </BrandContainer>
              </Link>
            );
          })}
        </div>
      );
    })
  );
}

RenderStores.propTypes = {
  stores: PropTypes.object.isRequired
}

export default RenderStores
