import React from 'react';
import PropTypes from 'prop-types';
import classes from './style/Landing.module.css';
import bgLanding from '../../img/img_landing.jpg';

const FindStores = ({ postal_code, onsubmit, onchange, onclick }) => {
  return (
    <section className={`ld-container ${classes.FindStores}`}>
      <div className={classes.LocationContainer}>
        <h2>Plan better. Shop safer. Save more.</h2>
        <h4>View indoor maps, track grocery trips, and stay updated on sales at your favourite stores</h4  >
        <form onSubmit={onsubmit}>
          <input type="text" maxLength="7" onChange={onchange} value={postal_code} placeholder="Enter your Postal Code"/>
          <button>
            <i className="fa fa-search" aria-hidden="true"></i>
            <span>Find Stores</span>
          </button>
        </form>
        <div className={classes.FindMyLoc}>
          <p>OR</p>
          <button onClick={onclick}>
            <i className="fa fa-compass" aria-hidden="true"></i>
            <span>Use My Location</span>
          </button>
        </div>
      </div>
      <img src={bgLanding} alt="veggies"/>
    </section>
  )
}

FindStores.propTypes = {
  postal_code: PropTypes.string.isRequired,
  onsubmit: PropTypes.func.isRequired,
  onchange: PropTypes.func.isRequired,
  onclick: PropTypes.func.isRequired,
}

export default FindStores;