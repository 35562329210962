import React from 'react';
import classes from './style/MyListLogin.module.css';

const NoList = () => {
  return (
    <div className={classes.MyList}>
      <p style={{margin: '3rem 0 0 0'}}>You do not have any lists yet</p>
      <p style={{margin: '2rem 0 0 0'}}>Click on the <strong>Create List</strong> button to create your first list</p>
    </div>
  )
}

export default NoList
