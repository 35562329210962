import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import { STORE_MY_LIST } from '../../../actions';
import { getProjectLists, deleteProject } from '../../../api';
import Spinner from '../../layout/Spinner';
import MyListLogin from './MyListLogin';
import ListContainer from './ListContainer';
import TitleContainer from '../../layout/TitleContainer';
import NoList from './NoList';
import classes from './style/MyList.module.css';

const ExploreMyList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notificationSystem = useRef();

  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn);
  const session_id = useSelector(state => state.authReducer.session_id);

  const [ loading, setLoading ] = useState(false);
  const [ lists, setLists ] = useState([]);
  const [ types, setTypes ] = useState({});
  const fetchMyProjectsList = async () => {
    setLoading(true);
    let data_lists = [];
    let data_types = [];
    const res = await getProjectLists({ mode: 'my-lists', session_id });
    const res_saved = await getProjectLists({ mode: 'my-saves', session_id });
    if(res.status === 200){
      dispatch({ type: STORE_MY_LIST, payload: res.data });
    } else {
      alert('Could not retrieve information');
    }
    data_lists = [...res.data.lists, ...res_saved.data.lists];
    data_types = res.data.types;
    // construct Types object
    let typesObj = {}
    for(let i = 0; i < data_types.length; i++) {
      typesObj[data_types[i].type_id] = {
        type_id: data_types[i].type_id,
        type: data_types[i].type
      }
    }

    // construct list URL
    data_lists = data_lists.map(list => {
      //creates and format new url for each list
      const formatted_url = list.title
                        .toLowerCase()
                        .replace(/-/g, '')
                        .replace(/\s+/g, '-')
                        .replace(/[^1-9a-zA-Z-]/g, '')
                        .trim() + `-${list.list_id}`;
      const formatted_type = typesObj[list.type_id].type
                              .toLowerCase()
                              .replace(/\s+/g, '-')
      return { ...list, formatted_url, formatted_type };
    });

    setLists( data_lists );
    setTypes( typesObj );
    setLoading(false);
  }
  
  useEffect(() => {
    const fetchLists = async () => {
      await fetchMyProjectsList();
    }
    
    if(isLoggedIn) fetchLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemove = async ( list ) => {
    const validate = window.confirm(`Are you sure you want to remove the project ${list.title}? This action will permanently delete this project.`);
    if(validate) {
      const res = await deleteProject( list.list_id, session_id );

      if(res.status === 200) {
        await fetchMyProjectsList();
        addNotification(
          res.data.msg,
          `Successfully removed ${list.title}`,
          'success'
        );
      } else {
        addNotification(
          'An Error Occurred',
          res.data.msg ? res.data.msg : 'Operation could not complete',
          'error'
        )
      }
    }
  }

  const handleEdit = (list) => {
    const redirect = `/explore/create/${list.type.toLowerCase().replace(/\s+/g, '')}`;
    const queryString = `?list_id=${list.list_id}`;
    history.push(redirect + queryString);
  }

  const handleClick = (e, action, list) => {
    e.preventDefault();
    e.stopPropagation();
    switch(action){
      case 'remove':
        return handleRemove(list);
      case 'edit':
        return handleEdit(list);
      default:
        break;
    }
  }

  const addNotification = (title, message, type) => {
    const notification = notificationSystem.current;
    notification.addNotification({
      title,
      message,
      level: type,
      position: 'tc',
      autoDismiss: 3,
    })
  }

  if ( loading ) return <Spinner />;
  if ( !isLoggedIn ) return <MyListLogin />
  if ( lists.length === 0 ) return <NoList />;
  else return (
    <div className={classes.MyList}>
      <TitleContainer title="My List" />
      <NotificationSystem ref={notificationSystem} />
      {lists.length > 0 && lists.map(list => (
        <Link key={list.list_id} to={`/explore/${list.formatted_type}/${list.formatted_url}`} >
          <ListContainer list={list} types={types} onclick={handleClick} />
        </Link>
      ))}
    </div>
  )
}

export default ExploreMyList
