import React, { useEffect, useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProjectLists, saveProject } from '../../api';
import Spinner from '../layout/Spinner';
import SortOption from './SortOption';
import { STORE_SCROLL, STORE_RECIPES, UPDATE_HASMORE, UPDATE_SORTING, CLEAR_EXPLORE } from '../../actions';
import classes from './style/ExploreTab.module.css';

const ExploreTab = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user_info, session_id, isLoggedIn } = useSelector(state => state.authReducer);
  const { recipes, y_pos, hasMore } = useSelector(state => state.feedExplore);

  const [ loading, setLoading ] = useState(true);
  const [ imgLoading, setImgLoading ] = useState(true);
  const [ init, setInit ] = useState(true);

  const counter = useRef(0);

  const fetchLists = async ({ offset = -1, limit = -1, mode, push=true }) => {
    if(init || !push) setLoading(true);
    setInit(false);
    const res = await getProjectLists({ mode, limit, offset, session_id });
    if( res.status === 200 ) {
      // create formatted url for the project type and the url project page
      const formatted_list = res.data.lists.map(list => {
        const project_type = list.type.toLowerCase().replace(/\s+/g, '-');
        const project_url = list.title.toLowerCase()
                                      .replace(/-/g, '')
                                      .replace(/\s+/g, '-')
                                      .replace(/[^1-9a-zA-Z-]/g, '')
                                      .trim() + `-${list.list_id}`;
        return { ...list, project_type, project_url };
      });
      let list;
      if(push) {
        list = recipes ? [...recipes, ...formatted_list] : formatted_list;
        if( list.length >= res.data.total_count - 1 ) {
          dispatch({ type: UPDATE_HASMORE, payload: false });
        }
      } else {
        list = formatted_list
      }
      
      dispatch({ type: STORE_RECIPES, payload: list });
    } else {
      alert(res.data.response);
    }
    setLoading(false);
  } 

  useEffect(() => {
    if(recipes === null) fetchLists({ mode: 'default', offset: 0 });
    else setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Scroll to position after returning to page
  useEffect(() => {
    if(!imgLoading) {      
      scrollToView();
      dispatch({ type: CLEAR_EXPLORE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgLoading]);

  // Function that scrolls to previous position on page load
  const scrollToView = () => {
    const promise = new Promise((resolve, reject) => {
      document.querySelector('#root').scrollTo(0, y_pos);
      resolve();
    });
    return promise;
  }

  const handleSave = async (e, index, list_id) => {
    e.preventDefault();
    e.stopPropagation();
    let list = [...recipes];
    const isSaved = list[index].my_saves;
    if( isSaved === 1 ) {
      // if saved, call remove to unsave the project;
      const res = await saveProject('remove', list_id, session_id);
      if(res.status === 200) list[index].my_saves = 0;
      else alert('Could not complete operation');
    } else {
      const res = await saveProject('add', list_id, session_id);
      if(res.status === 200) list[index].my_saves = 1;
      else alert('Could not complete operation');
    }
    dispatch({ type: STORE_RECIPES, payload: list });
  }

  const handleEditProject = (e, list) => {
    e.preventDefault();
    e.stopPropagation();
    const { project_type, list_id } = list;
    history.push(`/explore/create/${project_type}?list_id=${list_id}`);
  }

  const handleImageLoaded = () => {
    counter.current += 1;
    if(counter.current >= recipes.length) setImgLoading(false);
  }

  const handleOptionChange = e => {
    dispatch({ type: UPDATE_SORTING, payload: e.target.value });
    fetchLists({ mode: e.target.value, push: false });
  }

  const getScrollPosition = () => {
    const scrollPos = document.querySelector('#root').scrollTop;
    dispatch({ type: STORE_SCROLL, payload: scrollPos });
  }

  if( loading ) return <Spinner />

  return (
    <div>
      <SortOption onchange={handleOptionChange} />
      <div className={classes.ExploreTab}>
        {imgLoading && <Spinner />}
        <InfiniteScroll
          dataLength={recipes.length}
          next={() => fetchLists({ offset: recipes.length + 1, mode: 'default'})}
          hasMore={hasMore}
          loader={<Spinner />}
          className={`${imgLoading ? classes.Hide : classes.Show} ${classes.InfiniteScroll}`}
        >
          {recipes.map((list ,index) => (
            <Link 
              className={`no-link ${classes.Project}`}
              to={`/explore/${list.project_type}/${list.project_url}`}
              onClick={getScrollPosition}
              key={index}
            >
              <div>
                {user_info && user_info.appuser_id !== list.appuser_id && isLoggedIn &&
                <div className={classes.SaveBox} onClick={(e) => handleSave(e, index, list.list_id)}>
                  <i className={list.my_saves===1 ? 'fa fa-heart' : 'fa fa-heart-o'}/>
                  <p>{list.my_saves===1 ? 'Saved' : 'Save'}</p>
                </div>}
                {user_info && user_info.appuser_id === list.appuser_id && isLoggedIn &&
                <i onClick={e => handleEditProject(e, list)} className={`fa fa-pencil-square-o ${classes.EditBox}`} aria-hidden="true"></i>}
                <img src={list.photo} onLoad={handleImageLoaded} alt="display-img" />
                <div className={classes.ProjectMeta}>
                  <div>
                    <span>{list.type}</span>
                    {user_info && user_info.appuser_id === list.appuser_id && isLoggedIn && <span>Created</span>}
                  </div>
                  <p>{list.title}</p>
                </div>
              </div>
            </Link>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default ExploreTab
