import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import ListContainer from './ListContainer';
import classes from './style/ExploreCreate.module.css';

const ExploreCreate = () => {
  const history = useHistory();
  const LISTS = [
    { url: '/explore/create/recipe', name: 'Recipe' },
    { url: '/explore/create/homeproject', name: 'Home Project' },
    { url: '/explore/create/checklist', name: 'Check List' },
  ];
  return (
    <div className={`container padded ${classes.ExploreCreate}`}>
      <button className="bt-back" onClick={() => history.goBack()}> Cancel</button>
      <h3>What are you making?</h3>
      {LISTS.map(( list, index ) => (
        <Link key={index} to={list.url} className={`no-link ${classes.ListContainer}`}>
          <ListContainer>{list.name}</ListContainer>
        </Link>
      ))}
    </div>
  )
}

export default ExploreCreate;