import { 
  QUICKADD_SELECTED,
  QUICKADD_TRUE,
  QUICKADD_DISPLAY_TRUE,
  RESET_QUICKADD,
  RESET_SELECTED_QUICKADD
} from '../actions';

const initialState = {
  quickAdd: [
    {
      name: 'milk',
      selected: false,
      display: false,
    },
    {
      name: 'bread',
      selected: false,
      display: false,
    },
    {
      name: 'ice cream',
      selected: false,
      display: false,
    },
    {
      name: 'frozen pizza',
      selected: false,
      display: false,
    },
    {
      name: 'cereal',
      selected: false,
      display: false,
    },
    {
      name: 'toilet paper',
      selected: false,
      display: false,
    },
    {
      name: 'power tools',
      selected: false,
      display: false,
    },
    {
      name: 'paint brushes',
      selected: false,
      display: false,
    },
    {
      name: 'garden tools',
      selected: false,
      display: false,
    },
    {
      name: 'caulking',
      selected: false,
      display: false,
    },
    {
      name: 'lumber',
      selected: false,
      display: false,
    }
  ],
}

const quickAddReducer = (state = initialState, action) => {
  switch(action.type){
    case QUICKADD_SELECTED:
      let qaArray = [...state.quickAdd];
      const selected = state.quickAdd[action.id].selected;
      qaArray[action.id].selected = !selected;
      return {
        ...state,
        quickAdd: qaArray
      }
    case RESET_QUICKADD:
      return {
        ...state,
        quickAdd: state.quickAdd.map(item => {
          item.selected = false;
          item.display = false;
          return item;
        })
      }
    case RESET_SELECTED_QUICKADD:
      return {
        ...state,
        quickAdd: state.quickAdd.map(item => {
          item.selected = false;
          return item;
        })
      }
    case QUICKADD_TRUE:
      let newArray = [...state.quickAdd];
      newArray[action.id].selected = true;
      return {
        ...state,
        quickAdd: newArray
      }
    case QUICKADD_DISPLAY_TRUE:
      let displayArray = [...state.quickAdd];
      displayArray[action.id].display = true;
      return {
        ...state,
        quickAdd: displayArray
      }
    default:
      return state; 
  }
}

export default quickAddReducer;