// API Credential
var apiUrl = '';
if(typeof(cordova) == "object" || window.location.toString().indexOf("file://") === 0){
    apiUrl = 'https://app.spotitmapping.com/';
}
export const API_URL = apiUrl;
export const API_KEY = '048c6a8c-b880-4e57-ba22-f611eef37daf';
export const API_VERSION = 2;

export const RECAPTCHA_PUBLIC_KEY = '6Ldjse0UAAAAAKBzqwO5cDt1bEpRf2XfHG0AI_Ve' // use in HTML/JS code
export const RECAPTCHA_MOBILE_APP_CODE = "0b4c19c6-47b7-4307-bc0b-e156b6c40003-5295b299-3e84-47f5-8dbe-3d772aad07cc-a7f151a2-88b5-40fc-b273-8d78a11af373";

export const IS_WEB_APP = true;

const MODE = 'DEV';
export const prefix = MODE === 'DEV' ? '' : '/platform';

