import React from 'react';
import TitleContainer from '../layout/TitleContainer';

const NoMatchPage = () => {
  return (
    <div className="container padded">
      <TitleContainer title="Error 404" />
      <h3>404 - Page Not Found</h3>
    </div>
  )
}

export default NoMatchPage;
