import React from 'react';
import classes from './style/ProjectInstructions.module.css';

const ProjectInstructions = ({ list }) => {
  return (
    <div className={classes.ProjectInstructions}>
      <h4>Instructions</h4>
      <div dangerouslySetInnerHTML={{ __html: list.instructions }}></div>
    </div>
  )
}

export default ProjectInstructions;