import { STORE_RECIPES, STORE_SCROLL, UPDATE_HASMORE, UPDATE_SORTING, CLEAR_EXPLORE } from '../actions';

const intitialState = {
  recipes: null,
  hasMore: true,
  y_pos: 0,
  sortOption: 'default'
}

const feedExplore = (state=intitialState, action) => {
  const { payload, type } = action;

  switch(type) {
    case STORE_RECIPES:
      return { ...state, recipes: payload }
    case STORE_SCROLL:
      return { ...state, y_pos: payload, keep_y_pos: true }
    case UPDATE_SORTING:
      return { ...state, sortOption: payload }
    case UPDATE_HASMORE:
      return { ...state, hasMore: payload };  
    case CLEAR_EXPLORE:
      return {
        ...state,
        y_pos: 0,
      }
    default:
      return state;
  }
}

export default feedExplore;