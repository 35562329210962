import React from 'react';
import ProjectImages from './ProjectImages';
import ProjectTitle from './ProjectTitle';
import ProjectActions from './ProjectActions';
import classes from './style/ProjectHeader.module.css';
import { IsNotMobile } from '../../layout/MediaQueries';

const ProjectHeader = ({ 
  list_data: { 
    list, 
    photos,
    videos,
  },
  user_saved,
  user_liked,
  likes_count,
  types,
  img_display,
  onImageClick,
  onAction
}) => {
  return (
    <div className={classes.ProjectHeader}>
      <ProjectImages photos={photos} videos={videos} img_display={img_display} onImageClick={onImageClick} />
      <ProjectTitle list={list} types={types} />
      <IsNotMobile>
        <ProjectActions
          list={list}
          user_saved={user_saved}
          user_liked={user_liked}
          likes_count={likes_count}
          onAction={onAction}
        />
      </IsNotMobile>
    </div>
  )
}

export default ProjectHeader;