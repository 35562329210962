import React from 'react';
import classes from './style/ProjectTitle.module.css';

const ProjectTitle = ({ list, types }) => {
  return (
    <div className={classes.HeaderTitle}>
      <h1>{list.title}</h1>
      {list.type_id === 1 && <p><em>{`Serves ${list.servings}`}</em></p>}
      {list.type_id === 2 && <p><em>{`Makes ${list.servings}`}</em></p>}
      <p>{`Time: ${list.prep_time}`}</p>
      <p>{`Posted by: ${list.author}`}</p>
      <a href={list.website_url} target="_blank" rel="noopener noreferrer">{list.website_url}</a>
      <div className={classes.MetaTags}>
        <span>
          <i className="fa fa-globe" aria-hidden="true"/>
          {list.is_public === 1 ? "Public" : "Private"}
        </span>
        <span>{types[list.type_id].replace(/-/g, ' ')}</span>
      </div>
    </div>
  )
}

export default ProjectTitle;