import React, { Component, createRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { IS_WEB_APP, RECAPTCHA_MOBILE_APP_CODE } from '../../constants/constants';
import NotificationSystem from 'react-notification-system';
import TitleContainer from '../layout/TitleContainer';
import { forgetPassword } from '../../api';
import classes from './style/ForgetPassword.module.css';

export class ForgetPassword extends Component {
  state = {
    email: '',
    submitted: false,
  }

  notificationSystem = createRef();

  submitForm = async (e) => {
    e.preventDefault();
    const { email } = this.state;

    if( email === '' ){
      this.addNotification(
        'Empty Input',
        'Required input field cannot be empty',
        'error',
      );
    } else {
      var recaptcha = RECAPTCHA_MOBILE_APP_CODE;
      if( IS_WEB_APP ) {
        recaptcha = await this.props.googleReCaptchaProps.executeRecaptcha('login');
      }
      const res = await forgetPassword({ email, recaptcha });

      if(res.data.success && !res.data.error) {
        this.addNotification(
          'Email Sent',
          res.data.msg,
          'success'
        );
        this.setState({ submitted: true });
      } else {
        this.addNotification(
          'Error',
          res.data.msg,
          'error',
        );
      }
    }
  }

  addNotification = (title, message, type) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title,
      message,
      level: type,
      position: 'tc',
      autoDismiss: 3,
    });
  }

  handleChange = e => this.setState({ [e.target.name] : e.target.value });

  render() {
    const { email, submitted } = this.state;
    return (
      <div className={classes.ForgetPassword}>
        <TitleContainer title="Reset Password" />
        <NotificationSystem ref={this.notificationSystem} />
        <div className={classes.ContentContainer}>
          <h2>Forgot Your Password?</h2>
          {!submitted && 
          <Fragment>
            <p>A reset link will be sent to your email.</p>
            <form onSubmit={this.submitForm}>
              <input type="email" autoComplete="off" name="email" placeholder="Enter your email here" value={email} onChange={this.handleChange}/>
              <button>Send Reset Link</button>
            </form>
          </Fragment>}
          {submitted && 
          <Fragment>
            <p>A reset link was successfully sent!</p><br/>
            <Link className="no-link" to="/login">Log In</Link>
          </Fragment>}
        </div>
      </div>
    )
  }
}

export default withGoogleReCaptcha(ForgetPassword);