import React from 'react';
import ReactQuill from 'react-quill';
import IngredientsContainer from './IngredientsContainer';
import ImageUpload from './ImageUpload';
import VideoEmbed from './VideoEmbed';
import WebsiteBox from './WebsiteBox';
import classes from './style/ProjectForm.module.css';
import 'react-quill/dist/quill.snow.css';
import './style/TextEditor.css';

const ProjectForm = ({
  keywords,
  project,
  project_url,
  title,
  video_og,
  checkboxState,
  onchange,
  coverRef,
  focusCoverIndex,
  onOptionChange,
  onAddIngredient,
  onRemoveIngredient,
  onImageClick,
  onFetchKeyword,
  onTextUpdate,
  onImageChange,
  onFormSubmit,
  onInstructionChange,
  onOptionClick,
  onRemoveVideoFocus,
  onVideoChange,
  onCheckboxChange,
}) => {
  const modules = {
    toolbar: [
      [{ 'header': [false, 1, 2, 3] }],
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline',
    'list', 'bullet',
  ];

  return (
    <form className={classes.ProjectForm} onSubmit={onFormSubmit}>
      <h3>{title}</h3>
      <input className={classes.FormControl1} type="text" name="title" value={project.title} placeholder="Title" onChange={onchange}/>
      <input className={classes.FormControl1} autoComplete="off" type="text" name="description" value={project.description} placeholder="Description" onChange={onchange}/>
      {project_url !== 'checklist' && <div className={classes.Servings}>
        {project_url === 'homeproject' && <p>Makes</p> }
        <input className={classes.FormControl2} type="number" name="servings" value={project.servings} onChange={onchange} />
        {project_url === 'recipe' && <p style={{marginLeft: '0.5rem'}}>Servings</p>}
      </div>}
      {project_url !== 'checklist' && <input className={classes.FormControl3} type="text" name="prep_time" placeholder="Preparation Time" value={project.prep_time} onChange={onchange} />}
      <IngredientsContainer 
        project={project}
        keywords={keywords}
        title={project_url === 'recipe' ? 'Ingredients' : 'Items'}
        pl={project_url === 'recipe' ? 'Ingredient' : 'Item'}
        onOptionChange={onOptionChange} 
        onAddIngredient={onAddIngredient}
        onRemoveIngredient={onRemoveIngredient}
        onFetchKeyword={onFetchKeyword}
        onTextUpdate={onTextUpdate}
      />
      {project_url !== 'checklist' && <div className={classes.InstructionsContainer}>
        <h4>Instructions</h4>
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          className="text-editor"
          placeholder="Add your instructions here"
          value={project.instructions}
          onChange={onInstructionChange}
        />
      </div>}
      <div className={classes.SharingContainer}>
        <h3>Sharing</h3>
        <ImageUpload 
          project={project}
          focusCoverIndex={focusCoverIndex}
          coverRef={coverRef}
          onImageChange={onImageChange} 
          onImageClick={onImageClick}
          onOptionClick={onOptionClick}
        />
        <VideoEmbed project={project} onOptionClick={onOptionClick} video_og={video_og} onRemoveVideoFocus={onRemoveVideoFocus} onVideoChange={onVideoChange} />
        <WebsiteBox project={project} onchange={onchange} />
        <div className={classes.Privacy}>
          <i className="fa fa-globe" aria-hidden="true"></i>
          <select name="is_public" value={project.is_public} onChange={onchange}>
            <option value="1">Public</option>
            <option value="0">Private</option>
          </select>
        </div>
      </div>
      <div className={classes.Disclaimer}>
        <div>
          <input id="checkbox" type="checkbox" checked={checkboxState} onChange={onCheckboxChange} />
          <label htmlFor="checkbox">By clicking accept, you acknowledge you have authorization as the creator or permission from the creator to post this content to the public.</label>
        </div>
        <p>Spot It is not responsible for any content that is posted to its properties without the permission of the rightful owner and will do everything within its ability to remove any copyright violation</p>
      </div>
      <div className={classes.ButtonContainer}>
        <button disabled={!checkboxState}>Create</button>
      </div>
    </form>
  )
}

export default ProjectForm;