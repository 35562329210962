import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { checkLoggedIn } from './api';
import { LOG_IN, SET_SESSION } from './actions';
import { useDispatch } from 'react-redux';
import { routes } from './routes';
import { IS_WEB_APP } from './constants/constants';
import queryString from 'query-string';
import './App.css';

import NoMatchPage from './components/no-match/NoMatchPage';
import PrivateRoute from './components/layout/PrivateRoute';
import Spinner from './components/layout/Spinner';

import imgDesk1 from './img/desk1.jpg';
import imgDesk2 from './img/desk2.jpg';
import imgCashRegister from './img/cash-register.jpg';
import imgBathroom from './img/bathroom.jpg';

const App = () => {
  const dispatch = useDispatch();
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    const getLanguage = async () => {
      let hash = window.location.hash
      const searchObj = queryString.parse(hash.substr(hash.indexOf('?')));
      let language = localStorage.getItem('language');
      if( !language || language == '' ) {
        if( navigator.language.startsWith('fr') )
        {
          language = 'fr';
        } else {
          language = 'en';
        }
      }
      if( 'switchLanguage' in searchObj && searchObj.switchLanguage != '' ) {
        language = searchObj.switchLanguage;
      }
      localStorage.setItem('language', language);
      return language;
    }
    const getLoggedIn = async () => {
      const session_id = JSON.parse(localStorage.getItem('session_id'));
      if(session_id !== null) {
        const res = await checkLoggedIn(session_id);
        const userInfo = {
          appuser_id: res.data.appuser_id,
          username: res.data.username,
          first_name: res.data.first_name,
          last_name: res.data.last_name,
          email: res.data.email
        }
        if(res.data.appuser_id !== null && res.status === 200){
          dispatch({ type: SET_SESSION, payload: session_id });
          dispatch({ type: LOG_IN, payload: userInfo });
        }
      }
      setLoading(false);
    }
    getLoggedIn();
    getLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if( loading ) return <Spinner />

  return (
    <div className="App">
      <Switch>
        {routes.map((route, index) => (
          <Route 
            key={index}
            path={route.path}
            exact={route.exact}
            render={route.sidebar}
          />
        ))}
      </Switch>
      <Switch>
        {!IS_WEB_APP && <Redirect exact from="/" to="/trips" />}
        {routes.map((route, index) => (
          route.protected
          ? <PrivateRoute 
              key={index}
              path={route.path}
              exact={route.exact}
              render={route.main}
            />
          : <Route 
              key={index}
              path={route.path}
              exact={route.exact}
              render={route.main}
            />
        ))}
        <Route component={NoMatchPage} />
      </Switch>
      <div id="imagePreloadContainer">
        <img id="bathroomIcon" src={imgBathroom} alt="bathroom" />
        <img id="cashRegisterIcon" src={imgCashRegister} alt="cash-register" />
        <img id="deskIcon1" src={imgDesk1} alt="desk-icon" />
        <img id="deskIcon2" src={imgDesk2} alt="desk-icon" />
      </div>
    </div>
  );
}

export default App;

