import React, { useRef } from 'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import { logOutUser } from '../../api';
import { LOG_OUT, CLEAR_MY_LIST, CLEAR_SESSION } from '../../actions';
import classes from './style/LandingNavbar.module.css';
import logo from '../../img/spot-it-logo.jpg';

const LandingSidebar = () => {
  const dispatch = useDispatch();
  const notificationSystem = useRef();

  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn);
  const userInfo = useSelector(state => state.authReducer.userInfo);

  const handleClick = async () => {
    await logOutUser();
    localStorage.removeItem('session_id');
    dispatch({ type: LOG_OUT });
    dispatch({ type: CLEAR_MY_LIST });
    dispatch({ type: CLEAR_SESSION });
    addNotification(
      'Log Out Successful',
      'You have successfully logged out!',
      'success'
    );
  }

  const addNotification = (title, message, type) => {
    const notification = notificationSystem.current;
    notification.addNotification({
      title,
      message,
      level: type,
      position: 'tc', // top-center
      autoDismiss: 3
    })
  }

  return (
    <div className={classes.LandingNavbar}>
      <NotificationSystem ref={notificationSystem} />
      <div className={classes.LdContainer}>
        <div className={classes.NavbarOptions}>
          <div>
            <Link to="/">
              <img src={logo} alt="Logo"/>
            </Link>
          </div>
          <div className={classes.Options}>
            <div className={classes.MenuOptions}>
              <Link to="/trips">Trips</Link>
              <Link to="/sales">Sales</Link>
              <Link to="/stores">Stores</Link>
              <Link to="/explore">Explore</Link>
              <Link to="/request-store">Request a Store</Link>
              <Link to="/mapper-application">Become a Mapper</Link>
            </div>
            {!isLoggedIn 
              ? <Link to="/login" className={classes.LoginBtn}>Log In</Link>
              : <div>
                <p>Welcome back <span>{userInfo.first_name}</span></p>
                <p onClick={handleClick} className={classes.LogOutBtn}>Logout</p>
              </div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingSidebar;