import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import StoreContainer from './StoreContainer';
import Spinner from '../layout/Spinner';
import PostalCodeContainer from './PostalCodeContainer';
import TitleContainer from '../layout/TitleContainer';
import { getStoreList, getBrandsList } from '../../api';
import classes from './style/Search.module.css';
import { STORE_DATA, STORE_BRANDS } from '../../actions';

class Search extends Component {
  state = {
    city: {},
    brands: {},
    loading: true,
    postal: ''
  };
  
  async componentDidMount() {
    const { imp_data, imp_brands, storeData, storeBrands } = this.props;
    let data = [];
    let brandsData = {};

    if( imp_data.length === 0 ) {
      const res = await getStoreList();
      data = res.data;
      storeData(data);
    } else { data = imp_data; }

    if( Object.keys(imp_brands).length === 0 ) {
      const res_brands = await getBrandsList();
      brandsData = res_brands.data;
      storeBrands(brandsData);
    } else { brandsData = imp_brands; }

    this.getAmountCity(data, brandsData);
    this.getAmountBrands(data, brandsData);
  }

  getAmountCity = (data, brandsData) => {
    let cityObj = {};
    data.forEach(store => {
      const { city, brand_id } = store;
      // Checks for empty object
      if(city !== '' && brandsData[brand_id]){
        // Creates a library with amount of objects
        if(cityObj[city]){ cityObj[city] += 1 }
        else { cityObj[city] = 1 }
      }
    });
    this.setState({ city: cityObj });
  }

  getAmountBrands = (data, brandsData) => {
    let brands = {};
    data.forEach(store => {
      const { brand_id, city } = store;
      // Checks for empty object
      if(city !== '' && brandsData[brand_id] ){
        // Creates a library with amount of objects
        if(brands[brandsData[brand_id].name]){ brands[brandsData[brand_id].name] += 1 }
        else { brands[brandsData[brand_id].name] = 1 };
      }
    });
    this.setState({ 
      brands: brands,
      loading: false
    });
  }

  handleClick = (e) => {
    e.preventDefault();
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        this.props.history.push('/stores/proximity');
      }, (error) => {
        alert('Unable to access your location. Please ensure you have location services turned on, and that this application has permissions to use them');
      },
      {enableHighAccuracy: false, timeout: Infinity, maximumAge: 86400000});
    } else {
      alert('Your Browser is not supported');
    }
  }

  handlePostalSubmit = async (e) => {
    e.preventDefault();
    const { postal } = this.state;
    // checks whether postal code is valid
    const validPostal = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(postal);
    if(!validPostal) alert('Invalid Postal Code Format');
    else {
      this.props.history.push(`/stores/proximity?postal_code=${postal}`)
    }
  }

  render(){
    const { loading, city, brands, postal } = this.state;
    if(loading) return <Spinner />
    return (
      <div className={`container padded ${classes.SearchStore}`}>
        <TitleContainer title="Stores" />
        <button className="bt-back" onClick={() => this.props.history.goBack()}><i className="fa fa-angle-left"></i> Back</button>
        {/* <Link to="/" className="bt-back"><i className="fa fa-angle-left"></i> Back</Link> */}
        <Link to="/stores/city" className={`${classes.StoreContainer} no-link`}>
          <StoreContainer>
            <h4>Find By City / Province</h4>
            <p>{Object.keys(city).length} cities / towns</p>
          </StoreContainer>
        </Link>
        <Link to="/stores/brands" className={`${classes.StoreContainer} no-link`}>
          <StoreContainer>
            <h4>Find By Store Brand</h4>
            <p>{Object.keys(brands).length} brands</p>
          </StoreContainer>
        </Link>
        <form className={classes.StoreContainer} onSubmit={this.handlePostalSubmit}>
          <PostalCodeContainer postal={postal} onchange={(e) => this.setState({ postal : e.target.value })} onclick={this.handleClick} />
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  imp_data: state.dataReducer.data,
  imp_brands: state.dataReducer.brands,
});

const mapDispatchToProps = dispatch => ({
  storeData: data => dispatch({ type: STORE_DATA, data }),
  storeBrands: brands => dispatch({ type: STORE_BRANDS, payload: brands }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
