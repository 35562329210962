import React from 'react';
import PropTypes from 'prop-types';
import classes from './Modal.module.css';

const Backdrop = ({ show, clicked }) => {
  return show ? <div className={classes.Backdrop} onClick={clicked}></div> : null
}

Backdrop.propTypes = {
  show: PropTypes.bool.isRequired,
  clicked: PropTypes.func.isRequired,
}

export default Backdrop
