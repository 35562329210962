import React from 'react';

import classes from './style/Acknowlegement.module.css';

const Acknowlegement = ({ focus, checkBoxState, children, oncancel, onchange, onaccept }) => {
  return (
    <div className={classes.Acknowlegement}>
      <h2>{focus === "privacy" ? "Privacy Policy" : "Terms and Conditions"}</h2>
      <div className={classes.PolicyBox}>
        {children}
      </div>
      <div className={classes.Checkbox}>
        <input id="checkbox" type="checkbox" onChange={onchange} checked={checkBoxState} />
        <label htmlFor="checkbox">{`I have read and agree to the ${focus === "privacy" ? "privacy policy" : "terms and conditions"}.`}</label>
      </div>
      <div className={classes.SubmitButtons}>
        <button onClick={() => oncancel(focus)}>Back</button>
        <button disabled={!checkBoxState} onClick={e => onaccept(e, focus)}>{focus === "privacy" ? "Accept" : "Submit"}</button>
      </div>
    </div>
  )
}

export default Acknowlegement;