import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Backdrop from './Backdrop';
import { HIDE_MODAL } from '../../../actions';
import classes from './Modal.module.css';

const Modal = ({ children }) => {
  const dispatch = useDispatch();
  const displayModal = useSelector(state => state.modalReducer.displayModal);

  const hideModal = () => {
    dispatch({ type: HIDE_MODAL });
    document.body.classList.remove('modal-open');
  }

  return (
    <div>
      <Backdrop show={displayModal} clicked={hideModal}/>
      <div className={classes.Modal}
        style={{
          transform: displayModal
            ? "translateY(0)"
            : "translateY(-100vh))",
          opacity: displayModal ? "1" : "0",
          display: displayModal ? "block" : "none"
        }}>
        {children}
      </div>
    </div>
  )
}

export default Modal;