import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REMOVE_ITEM, SET_COMPLETED, SET_FOCUS } from '../../actions';
import './style/ShoppingList.css';

const ShoppingList = () => {
  const dispatch = useDispatch();
  const [onFocusIndex, setFocusIndex] = useState(-1);
  const { shopping_list } = useSelector(state => state.shopListReducer);
  const { floors } = useSelector(state => state.storeReducer);

  const handleSetFocus = (id) => {
    let newFocusIndex;
    onFocusIndex === id ? newFocusIndex = -1 : newFocusIndex = id;
    setFocusIndex(newFocusIndex);
    dispatch({ type: SET_FOCUS, payload: newFocusIndex });
  }

  // Function handling clicking on checkmark
  const handleComplete = (e, index) => {
    e.stopPropagation();
    dispatch({ type: SET_COMPLETED, id: index });
  }

  // function handling clicking on delete button
  const removeItem = (e, index) => {
    e.stopPropagation();
    dispatch({ type: REMOVE_ITEM, id: index });
  }

  // Checks whether an item is completed. Completed items gets strikethrough
  const isCompleted = isItemCompleted => (isItemCompleted ? 'shopping-list-strikeout' : '');
  return shopping_list.length > 0 && (
    <table className="shopping-list">
      <thead>
        <tr>
          <th></th>
          <th>item</th>
          <th className="shopping-list-header">aisle</th>
          {floors > 1 && <th className="shopping-list-header">level</th>}
          <th>cat</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {shopping_list.map((item, index) => (
        <tr key={index} className={onFocusIndex === index ? 'shopping-list-focus' : ''} onClick={() => handleSetFocus(index)}>
          <td><span>{index+1}</span></td>
          <td className={isCompleted(item.completed)}>{item.name}</td>
          <td id="shopping-list-aisle" className={isCompleted(item.completed)}>{item.aisle}</td>
          {floors > 1 && <td id="shopping-list-level" className={isCompleted(item.completed)}>{item.floor}</td>}
          <td id="shopping-list-category" className={isCompleted(item.completed)}><span style={{backgroundColor: item.color}}>{item.color === "" ? "-" : ""}</span></td>
          <td id="shopping-list-completed">
            <i 
              className={`${item.completed ? 'fas' : 'far'} fa-check-circle`} 
              onClick={e => handleComplete(e, index)} 
              aria-hidden="true" 
            />
          </td>
          <td id="shopping-list-trash">
            <i className="far fa-trash-alt" onClick={e => removeItem(e, index)} aria-hidden="true"></i></td>
        </tr>
      ))}
      </tbody>
    </table>
  )
}

export default ShoppingList