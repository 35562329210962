import React, { useEffect, useState } from 'react';
import Spinner from './../layout/Spinner';
import RenderLocations from './RenderLocations';
import TitleContainer from '../layout/TitleContainer';
import { getStoreList, getBrandsList } from '../../api';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classes from './style/SearchByCity.module.css';
import { STORE_DATA, STORE_BRANDS } from '../../actions';

const SearchByCity = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const imp_data = useSelector(state => state.dataReducer.data);
  const imp_brands = useSelector(state => state.dataReducer.brands);
  const [ data, setData ] = useState({});
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let data = [];
      let brandsData = {};
      let provinceObj = {};
      if(imp_data.length === 0){
        const res = await getStoreList();
        data = res.data;
        dispatch({ type: STORE_DATA, data });
      } else { data = imp_data; }

      if(Object.keys(imp_brands).length === 0){
        const res_brands = await getBrandsList();
        brandsData = res_brands.data;
        dispatch({ type: STORE_BRANDS, payload: brandsData });
      } else { brandsData = imp_brands; }

      // Stores location objection
      data.forEach(store => {
        const { city, brand_id } = store;
        if(city !== "" && brandsData[brand_id]) {
          // object destructuring
          const { province, city } = store;

          if(provinceObj[province]){ // if province has already been added
            // if city has been created, increment count
            if(provinceObj[province][city]) {
              provinceObj[province][city] += 1;
            } else { // else create city with a count of 1
              provinceObj[province][city] = 1; 
            }
          } else { // if province does not exist, add province with city set as 1
            provinceObj = {
              ...provinceObj, 
              [province]: { [city]: 1 }
            }
          }
        }
      });
      provinceObj = sortObjects(provinceObj);
      // Sort Cities
      Object.keys(provinceObj).forEach(province => {
        provinceObj[province] = sortObjects(provinceObj[province]);
      })
      setData(provinceObj);
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortObjects = (obj) => {
    let newObj = {};
    // Converts to array, sort it and then converts back to object
    Object.keys(obj).sort().forEach(item => {
      newObj = {
        ...newObj,
        [item]: obj[item]
      }
    });
    return newObj;
  }

  if(loading) return <Spinner />;

  return (
    <div className="container padded">
      <TitleContainer title="Cities" />
      <button className="bt-back" onClick={() => history.goBack()}><i className="fa fa-angle-left"></i> Back</button>
      {/* <Link to="/stores" className="bt-back"><i className="fa fa-angle-left"></i> Back</Link> */}
      <div className={classes.SearchByCity}>
        {!loading && <RenderLocations data={data} />}
      </div>
    </div>
  )
}

export default SearchByCity
