import React, { useEffect } from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TitleContainer from '../layout/TitleContainer';
import ExploreTab from './ExploreTab';
import ExploreHeader from './ExploreHeader';
import MyList from './my-list/MyList';
import classes from './style/Explore.module.css';
import { CLEAR_MY_LIST } from '../../actions';

const Explore = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_MY_LIST });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`container padded`}>
      <TitleContainer title="Explore" />
      <div className={classes.ExploreContainer}>
        <ExploreHeader />
        <div className={classes.TabsContainer}>
          <NavLink exact to={`/explore`} activeClassName="activeTab" className={`${classes.Tabs}  no-link`}>
            Explore
          </NavLink>
          <NavLink to={`/explore/my-lists`} activeClassName="activeTab" className={`${classes.Tabs} no-link`}>
            My Lists
          </NavLink>
        </div>
        <Switch>
          <Route exact path={`/explore`} render={() => <ExploreTab />}/>
          <Route path={`/explore/my-lists`} render={() => <MyList />} />
        </Switch>
      </div>
    </div>
  )
}

export default Explore;