import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { IS_WEB_APP, RECAPTCHA_MOBILE_APP_CODE } from '../../constants/constants';
import NotificationSystem from 'react-notification-system';
import { changePassword } from '../../api';
import TitleContainer from '../layout/TitleContainer';
import FormGroup from '../layout/FormGroup';
import classes from './style/ResetPassword.module.css';

class ChangePassword extends Component {
  state = {
    old_password: '',
    new_password: '',
    confirm_password: '',
  }
  notificationSystem = createRef();

  handleSubmit = async e => {
    e.preventDefault();

    const { old_password, new_password, confirm_password } = this.state;

    if( new_password !== confirm_password ) {
      this.addNotification(
        'Password Mismatch',
        'The passwords did not match',
        'error'
      );
    } else {
      const session_id = this.props.session_id;
      var recaptcha = RECAPTCHA_MOBILE_APP_CODE;
      if( IS_WEB_APP ) {
        recaptcha = await this.props.googleReCaptchaProps.executeRecaptcha('login');
      }
      const res = await changePassword({ old_password, new_password, recaptcha, session_id });

      if( res.data.success && !res.data.error ){
        this.addNotification(
          'Password Change Successfully',
          res.data.msg,
          'success',
        );
        this.setState({
          old_password: '',
          new_password: '',
          confirm_password: '',
        });
      } else {
        this.addNotification(
          'An Error Occurred',
          res.data.msg,
          'error',
        );
      }
    }
  }

  handleChange = e => this.setState({ [e.target.name] : e.target.value });

  // Handler for creating notification
  addNotification = (title, message, type) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title,
      message,
      level: type,
      position: 'tc',
      autoDismiss: 3,
    })
  }

  render() {
    const { old_password, new_password, confirm_password } = this.state;

    return (
      <div className={'container padded'}>
        <TitleContainer title="Change Password" />
        <NotificationSystem ref={this.notificationSystem} />
        <button className="bt-back" onClick={() => this.props.history.goBack()}><i className="fa fa-angle-left"></i> Back</button>
        <div className={classes.ResetPassword}>
          <div className={classes.ModalHeader}>
            <h4>Change Your Password</h4>
          </div>
          <div className={classes.ModalBody}>
            <form id="change-password-form">
              <FormGroup name="old_password" type="password" label="Old Password" placeholder="" required={true} value={old_password} onchange={this.handleChange} />
              <FormGroup name="new_password" type="password" label="New Password" placeholder="" required={true} value={new_password} onchange={this.handleChange} />
              <FormGroup name="confirm_password" type="password" label="Confirm New Password" placeholder="" required={true} value={confirm_password} onchange={this.handleChange} />
            </form>
          </div>
          <div className={classes.ModalFooter}>
            <button onClick={() => this.props.history.goBack()}>Cancel</button>
            <button onClick={this.handleSubmit} form="change-password-form">Submit</button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  session_id: state.authReducer.session_id,
})

export default connect(mapStateToProps)(withGoogleReCaptcha(withRouter(ChangePassword)));
