import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../layout/Spinner';
import RenderStores from './RenderStores';
import TitleContainer from '../layout/TitleContainer';
import { getStoreList, getBrandsList } from '../../api';
import classes from './style/SearchByBrand.module.css';
import { STORE_DATA, STORE_BRANDS } from '../../actions';

const SearchBrand = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const imp_data = useSelector(state => state.dataReducer.data);
  const imp_brands = useSelector(state => state.dataReducer.brands);
  const [ data, setData ] = useState({});
  const [ logos, setLogos ] = useState({});
  const [ loading, setLoading ] = useState(true);
  
  useEffect(() => {
    const fetchStoreData = async () => {
      let data = [];
      let brandsData = {};
      let logoObj = {};
      let storeObj = {};
      if(imp_data.length === 0){ 
        const res = await getStoreList();
        data = res.data;
        dispatch({ type: STORE_DATA, data });
      } else { data = imp_data; }

      if(Object.keys(imp_brands).length === 0){
        const res_brands = await getBrandsList();
        brandsData = res_brands.data;
        dispatch({ type: STORE_BRANDS, payload: brandsData });
      } else {
        brandsData = imp_brands;
      }

      // Logic to get Store Name and amount of stores
      data.forEach(store => {
        const{ brand_id, city } = store;
        if(brand_id !== null && city !== "" && brandsData[brand_id]){
          const store_name = brandsData[brand_id].name;
          // create library of Stores + Store count
          if(storeObj[store_name]){ storeObj[store_name] += 1; }
          else{ storeObj[store_name] = 1; }

          logoObj[store_name] = brandsData[store.brand_id].logo; 
        }
      });
      storeObj = sortObject(storeObj);
      setLogos(logoObj);
      setData(storeObj);
      setLoading(false);
    }
    fetchStoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortObject = (obj) => {
    let newObj = {};
    Object.keys(obj).sort().forEach(store => {
      newObj = {
        ...newObj,
        [store]: obj[store]
      }
    });
    return newObj;
  }

  if( loading ) return <Spinner />;

  return (
    <div className="container padded">
      <TitleContainer title="Store Brands" />
      <button className="bt-back" onClick={() => history.goBack()}><i className="fa fa-angle-left"></i> Back</button>
      {/* <Link to="/stores" className="bt-back"><i className="fa fa-angle-left"></i> Back</Link> */}
      <div className={classes.SearchBrand}>
        {!loading && <RenderStores data={data} logos={logos} urlParam={'stores'} />}
      </div>
    </div>
  )
}

export default SearchBrand;
