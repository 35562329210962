import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const TitleContainer = ({ title, children}) => {
  const defaultTitle = 'Spot It Mapping'
  return (
    <Helmet>
      <title>{title ? `${defaultTitle} | ${title}` : defaultTitle}</title>
      {children}
    </Helmet>
  )
}

TitleContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

export default TitleContainer;