import React, { Component, createRef } from 'react';
import NotificationSystem from 'react-notification-system';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { IS_WEB_APP, RECAPTCHA_MOBILE_APP_CODE } from '../../constants/constants';
import { withRouter } from 'react-router-dom';
import FormGroup from '../layout/FormGroup';
import TitleContainer from '../layout/TitleContainer';
import { requestStore } from '../../api';
import classes from './RequestStore.module.css';

class RequestStore extends Component {
  state = {
    store: '',
    city: '',
    province: '',
    address: '',
    comments: '',
  }

  notificationSystem = createRef();

  handleSubmit = async (e) => {
    e.preventDefault();
    const { store, city, province, address, comments } = this.state;

    if( store === '' || city === '' || province === '' ) {
      this.addNotification(
        'Empty Input',
        'Required input field cannot be empty',
        'error',
      );
    } else {
      var recaptcha = RECAPTCHA_MOBILE_APP_CODE;
      if( IS_WEB_APP ) {
        recaptcha = await this.props.googleReCaptchaProps.executeRecaptcha('login');
      }
      
      const res = await requestStore(
        store,
        city,
        province,
        address,
        comments,
        recaptcha
      );
      if(res.data.success && !res.data.error){
        this.setState({
          store: '',
          city: '',
          province: '',
          address: '',
          comments: '',
        })
        this.addNotification(
          'Successfully Submitted',
          'Your submission was successful',
          'success',
        );
      } else {
        this.addNotification(
          'Error',
          'An error occurred. Submission was not registered',
          'error',
        );
      }
    }
  }
  
  addNotification = (title, message, type) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title,
      message,
      level: type,
      position: 'tc',
      autoDismiss: 3,
    })
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render(){
    const { store, city, province, address, comments } = this.state;
    return (
      <div className="container padded">
        <NotificationSystem ref={this.notificationSystem} />
        <TitleContainer title="Request Store" />
        <button className="bt-back" onClick={() => this.props.history.goBack()}><i className="fa fa-angle-left"></i> Back</button>
        <div className={classes.ReqStore}>
          <div className={classes.ModalHeader}>
            <h4>Request Store</h4>
          </div>
          <div className={classes.ModalBody}>
            <form id="request-form">
              <FormGroup name={'store'} label={'Store'} placeholder={'Store'} required={true} value={store} onchange={this.handleChange} />
              <FormGroup name={'city'} label={'City'} placeholder={'City'} required={true} value={city} onchange={this.handleChange} />
              <FormGroup name={'province'} label={'Province'} placeholder={'Province'} required={true} value={province} onchange={this.handleChange} />
              <FormGroup name={'address'} label={'Address (if known)'} placeholder={'Address'} required={false} value={address} onchange={this.handleChange} />
              <FormGroup name={'comments'} label={'Comments'} placeholder={'Comments'} input={false} required={false} value={comments} onchange={this.handleChange} />
            </form>
          </div>
          <div className={classes.ModalFooter}>
            <button onClick={() => this.props.history.goBack()}>Cancel</button>
            <button onClick={this.handleSubmit} form="request-form">Submit</button>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(withGoogleReCaptcha(RequestStore));