import React from 'react';
import Spinner from './Spinner';
import classes from './style/ProgressBar.module.css';

const ProgressBar = ({ bgColor }) => {  
  return (
    <div className={classes.ProgressBar}>
      <Spinner />
      <div className={classes.Prog}>
        <div id="progress-bar" ></div>
      </div>
      <span id="progress-text">0%</span>
    </div>
  )
}
export default ProgressBar;