import React from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import classes from './style/Login.module.css';

const Login = () => {
  return (
    <div className="container padded">
      <div className={classes.LoginContainer}>
        <div className={classes.TabsContainer}>
          <NavLink exact to={`/login`} activeClassName="activeTab" className={`${classes.Tabs}  no-link`}>
            Login
          </NavLink>
          <NavLink to={`/login/signup`} activeClassName="activeTab" className={`${classes.Tabs} no-link`}>
            Sign Up
          </NavLink>
        </div>
        <Switch>
            <Route exact path={`/login`} render={() => <LoginForm />}/>
            <Route path={`/login/signup`} render={() => <SignUpForm />} />
          </Switch>
      </div>
    </div>
  )
}

export default Login;