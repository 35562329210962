import React from 'react';
import PropTypes from 'prop-types';
import { IsNotMobile } from '../layout/MediaQueries';
import classes from './style/Controls.module.css';

const ArchiveControl = ({ trip_name, onclick, index }) => {
  return (
    <div className={classes.ControlsContainer}>
      <div className={classes.Date}>
        <h3>{trip_name}</h3>
      </div>
      <div className={classes.Controls}>
        <IsNotMobile><div></div></IsNotMobile>
        <div onClick={() => onclick('revive', index)}>
          <i className="fa fa-floppy-o" aria-hidden="true"></i>
          <p>Revive Trip</p>
        </div>
        <div onClick={() => onclick('delete-archive', index)}>
          <i className="fa fa-trash-o" aria-hidden="true"></i>
          <p>Delete</p>
        </div>
      </div>
    </div>
  )
}

ArchiveControl.propTypes = {
  trip_name: PropTypes.string.isRequired,
  onclick: PropTypes.func,
  index: PropTypes.number.isRequired,
}

export default ArchiveControl;