import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PastTripsDisplay from './PastTripsDisplay';
import ArchiveTripsDisplay from './ArchiveTripsDisplay';
import ArchiveControls from './ArchiveControl';
import TitleContainer from '../layout/TitleContainer';
import mstyle from './style/CurrentTrips.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { ARCHIVED_CLICKED } from '../../actions';

const PastTrips = ({ archived, onclick }) => {
  const showArchive = useSelector(state => state.tripsReducer.showArchive);
  const [ data, setData ] = useState([]);
  const [ id, setId ] = useState(-1);
  const dispatch = useDispatch();

  useEffect(() => {
    return function cleanup(){
      setData([]);
      dispatch({ type: ARCHIVED_CLICKED, payload: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (list, index) => {
    dispatch({ type: ARCHIVED_CLICKED, payload: true });
    setData(list);
    setId(index);
  }
  
  const renderPastTrips = () => {
    if(archived.length === 0) {
      return (
        <div className={mstyle.NoTrips}>
          <p><em>You do not have any trips archived yet.</em></p>
        </div>
      )
    } else {
      return <PastTripsDisplay archived={archived} onclicked={handleClick} />
    }
  }

  const handleBack = () => {
    dispatch({ type: ARCHIVED_CLICKED, payload: false })
    setData([]);
  }

  return (
    <div style={style}>
      <TitleContainer title="Past Trips" />
      {!showArchive && renderPastTrips()}
      {showArchive && 
      <div className={mstyle.CurrentTrips}>
        <div className={mstyle.TripsContainer}>
          <button className="bt-back" onClick={() => handleBack()}><i className="fa fa-angle-left"></i> Back</button>
          <ArchiveControls trip_name={data[0].trip_name} index={id} onclick={onclick} />
          <ArchiveTripsDisplay trips={data} />
        </div>
      </div>}
    </div>
  )
}

PastTrips.propTypes = {
  archived: PropTypes.array.isRequired,
  onclick: PropTypes.func,
}

const style = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '1rem',
}

export default PastTrips;
