import React from 'react';
import classes from '../landing/style/Landing.module.css';

const TextContainer = ({ header, content }) => {
  return (
    <div className={classes.OverviewDescription}>
      <h2><em>{header}</em></h2>
      <p>{content}</p>
    </div>
  )
}

export default TextContainer
