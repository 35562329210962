import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classes from './style/SortOption.module.css';

const SortOption = ({ onchange }) => {
  const { sortOption } = useSelector(state => state.feedExplore);
  return (
    <div className={classes.SortOption}>
      <span>Sort By: </span>
      <div>
        <select value={sortOption} onChange={onchange}>
          <option value="default">Featured</option>
          <option value="most-recent">Most Recent</option>
          <option value="most-liked">Most Liked</option>
        </select>
        <span><i className="fas fa-angle-down"></i></span>
      </div>
    </div>
  )
}

SortOption.propTypes = {
  onchange: PropTypes.func,
}

export default SortOption
