import React from 'react';
import TypeAhead from './TypeAhead';
import classes from './style/IngredientsContainer.module.css';

const IngredientsContainer = ({ project, keywords, onAddIngredient, onOptionChange, onRemoveIngredient, onFetchKeyword, onTextUpdate, title, pl }) => {
  return (
    <div className={classes.IngredientsContainer}>
      <h4>{title}</h4>
      {project.ingredients.map(( ig, index ) => (
        <div key={index} className={classes.IngredientsWrapper}>
          <div className={classes.Ingredients}>
            <input type="text" name="label" value={ig.label} autoComplete="off" onBlur={() => onFetchKeyword(index)} onChange={e => onOptionChange(e, index)} placeholder={`Add your ${pl}`}/>
            <div>
              <label htmlFor="">Shopping Keyword:</label>
              <TypeAhead items={keywords} onTextUpdate={(value) => onTextUpdate(index, value)} val={ig.keyword} />
            </div>
            
          </div>
          <i className="fa fa-times" aria-hidden="true" onClick={() => onRemoveIngredient(index)} />
        </div>
      ))}
      <p onClick={onAddIngredient}><i className="fa fa-plus-square-o" aria-hidden="true"/>{` Add ${pl}`}</p>
    </div>
  )
}

export default IngredientsContainer;