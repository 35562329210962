import React from 'react';
import PropTypes from 'prop-types';
import classes from './style/StoreContainer.module.css';

const StoreContainer = ({ children }) => {
  return (
    <div className={classes.ChildContainer}>
      <div>
        {children}
      </div>
      <i className="fa fa-angle-right"></i>
    </div>
  )
}

StoreContainer.propTypes = {
  children: PropTypes.node.isRequired
}

export default StoreContainer;
