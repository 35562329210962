import React, { useState } from 'react';
import classes from './style/ImageUpload.module.css';

const ImageUpload = ({ project, focusCoverIndex, coverRef, onImageChange, onImageClick, onOptionClick }) => {
  const [ show, setShow ] = useState(false);
  const handleClick = () => setShow(true);

  const handleChange = e => {
    setShow(false);
    onImageChange(e);
  }
  return (
    <div className={classes.ImageUpload}>
        <i className="fa fa-picture-o" aria-hidden="true"></i>
        <div>
        {project.images.length > 0 &&
        <div className={classes.ImagesContainer}>
        {project.images.map(( img, index ) => (
          <div key={index}>
            <img src={img.img} className={img.is_main === 1 ? classes.CoverImg : ''} alt="img-upload" onClick={() => onImageClick(index)}/>
            <p className={img.is_main === 1 ? classes.CoverTxt : ''}>Cover Photo</p>
            {focusCoverIndex === index && 
            <ul ref={coverRef}>
              {img.is_main === 0 && <li onClick={() => onOptionClick('setCover', index)}>Set as cover photo</li>}
              <li onClick={() => onOptionClick('removeImg', index)}>Remove</li>
              <li onClick={() => onOptionClick('removeAllImg')}>Remove All</li>
            </ul>}
          </div>
        ))}
        </div>}
        {!show && <span onClick={handleClick}>Add Photo</span>}
        {show && <input type="file" multiple name="image" onChange={handleChange} accept=".jpeg, .jpg, .png" />}
        </div>
    </div>
  )
}

export default ImageUpload;