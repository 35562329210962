import {
  STORE_SALES_STATE,
  ADD_SALE_ITEM,
} from '../actions';

const initialState = {
  addRemoveStatus: [],
  saleItemAdded: {},
};

const salesReducer = (state = initialState, action) => {
  switch(action.type){
    case STORE_SALES_STATE:
      return { ...state, addRemoveStatus: action.payload }
    case ADD_SALE_ITEM:
      return { ...state, saleItemAdded: action.payload }
    default:
      return state;
  }
}

export default salesReducer;