import React from 'react';
import classes from './style/VideoEmbed.module.css';

const VideoEmbed = ({ project, video_og, onOptionClick, onRemoveVideoFocus, onVideoChange }) => (
  <div className={classes.VideoEmbed}>
    <i className="fa fa-video-camera" aria-hidden="true"></i>
    {video_og.length === 0 && <p onClick={() => onOptionClick('add_video_input')}>Add Video</p>}
    {video_og.length > 0 && 
    <div className={classes.InputContainer}>
      {video_og.map(( vid, index ) => (
      <div className={classes.InputWrapper} key={index}>
        <span>{`${index + 1}.`}</span>
        <input 
          className={project.videos[index] === false ? classes.NotValid : ''} 
          id={index}
          type="text" 
          value={vid} 
          onBlur={(e) => onRemoveVideoFocus(e, index)} 
          name="video_og" 
          onChange={(e) => onVideoChange(e, index)} 
          placeholder="Add YouTube Link"
        />
        <i className="fa fa-plus" onClick={() => onOptionClick('add_video_input')} aria-hidden="true"></i>
        <i className="fa fa-minus" onClick={() => onOptionClick('remove_video_input', index)} aria-hidden="true"></i>
      </div>
      ))}
      {project.videos.length > 0 && 
      <div className={classes.VideoPreviewContainer}>
        {project.videos.map((vid_id, id) => {
          if(vid_id !== false) {
            return (
              <iframe 
                key={id} 
                width="240" 
                height="135" 
                title={id}
                src={`https://youtube.com/embed/${vid_id.video}`} 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
              />
            )
          } else return '';
        })}
      </div>}
    </div>}
  </div>
)

export default VideoEmbed;