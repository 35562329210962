import React from 'react';
import classes from './style/TabsContainer.module.css';

const TabsContainer = ({ tabs, active, onclick }) => {
  return (
    <div className={classes.TabsContainer}>
      {tabs.map(( tab, index ) => (
        <div
          key={index} 
          className={`${classes.Tabs} ${active === index ? 'activeTab' : ''}`} 
          onClick={() => onclick(index)}
        >
          {tab}
        </div>
      ))}
    </div>
  )
}

export default TabsContainer;