import { SHOW_DOWNLOAD, HIDE_DOWNLOAD } from '../actions';

const initialState = {
  show: false,
}

const downloadReducer = ( state = initialState, action ) => {
  switch (action.type){
    case SHOW_DOWNLOAD: 
      return { ...state, show: true };
    case HIDE_DOWNLOAD: 
      return { ...state, show: false };
    default:
      return state;
  }
}

export default downloadReducer;