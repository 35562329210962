import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const SalesHeader = ({ tripId, name, id, store_name, address }) => {
  return (
    <Fragment>
      <div>
        <h3>{store_name}</h3>
        <p>{address}</p>
      </div>
      <div style={{marginTop: '1rem'}}>
        <Link 
          to={{
            pathname: `/stores/${name}/${id}`,
            search: tripId ? `?id=${tripId}` : null,
          }} 
        >
          Go to Store
        </Link>
      </div>
    </Fragment>
  )
}

export default SalesHeader;