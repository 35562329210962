import React from 'react';
import { useSelector } from 'react-redux';
import classes from './style/ListContainer.module.css';

const ListContainer = ({ list, types, onclick }) => {
  const user_id = useSelector(state => state.authReducer.userInfo.appuser_id);
  return (
    <div className={classes.ListContainer}>
      <img src={list.photo} alt="list-img"/>
      <div>
        <div className={classes.ListDetails}>
          <div>
            <span>
              <i className="fa fa-globe" aria-hidden="true"/>
              {list.is_public === 1 ? "Public" : "Private"}
            </span>
            <span>{types[list.type_id].type}</span>
            <span>
              <i className="fa fa-bookmark" aria-hidden="true"/>
              {list.appuser_id === user_id ? 'Created' : 'Saved'}
            </span>
          </div>
          <p>{list.title}</p>
        </div>
        {user_id === list.appuser_id && 
        <div className={classes.ButtonsBox}>
          <button className={classes.EditBtn} onClick={e => onclick(e, 'edit', list)}>Edit</button>
          <button className={classes.RemoveBtn} onClick={e => onclick(e, 'remove', list)}>Remove</button>
        </div>}
        <i className="fa fa-angle-right"></i>
      </div>
    </div>
  )
}

export default ListContainer
