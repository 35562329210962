import { ARCHIVED_CLICKED } from '../actions';

const initialState = {
  showArchive: false
}

const tripsReducer = (state = initialState, action) => {
  switch(action.type){
    case ARCHIVED_CLICKED:
      return {
        ...state,
        showArchive: action.payload
      }
    default: 
      return state;
  }
}

export default tripsReducer;