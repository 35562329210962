import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import TypeAhead from '../TypeAhead';
import ShoppingList from '../ShoppingList';
import classes from './style/Draggable.module.css';
import TabsSales from '../TabsSales';

export class Draggable extends Component {
  state = {
    y_pos: document.documentElement.clientHeight / 2,
    dragging: false,
    rel: null, // position relative to the cursor;
  }

  componentDidUpdate = (props, state) => {
    if(document.querySelector('#sales-mobile')) {
      const screenHeight = window.screen.height;
      const scrollHeight = screenHeight - 70 - 60 - 32 - 20 - 30;
      document.querySelector('#sales-mobile').setAttribute('style', `height:${scrollHeight}px`);
    }

    if(document.querySelector('tbody')){
      const screenHeight = window.screen.height;
      const scrollHeight = screenHeight - 70 - 50 - 50 - 60 - 32 - 20 - 30;
      document.querySelector('tbody').setAttribute('style', `height:${scrollHeight}px`);
    } 

    if(this.state.dragging && !state.dragging){
      document.addEventListener('touchmove', this.onTouchMove, false);
      document.addEventListener('touchend', this.onTouchEnd);
    } else if (!this.state.dragging && state.dragging){
      document.removeEventListener('touchmove', this.onTouchMove);
      document.removeEventListener('touchend', this.onTouchEnd)
    }
  }

  onTouchMove = (e) => {
    if(!this.state.dragging) return;
    const screenHeight = document.documentElement.clientHeight;
    let y = e.touches[0].pageY - this.state.rel;
    if(y <= 60) y = 60;
    else if (y>=screenHeight-110) y = screenHeight-110;
    this.setState({
      y_pos: y
    });
    e.stopPropagation();
    e.preventDefault();
  }

  onTouchEnd = (e) => {
    this.setState({ dragging: false });
    e.stopPropagation();
    e.preventDefault();
  }

  onTouchStart = (e) => {
    const pos = ReactDOM.findDOMNode(this).getBoundingClientRect();
    if(e.touches[0].pageY < pos.top + 50){
      if(document.querySelector('input') !== null) {
        document.querySelector('input').blur();
      }
      this.setState({
        dragging: true,
        rel: e.touches[0].pageY - pos.top
      });
      e.stopPropagation();
      e.preventDefault();
    }
  }

  hideDraggable = (e) => {
    e.preventDefault();
    const screenHeight = document.documentElement.clientHeight;
    this.setState({ y_pos: screenHeight - 110 });
  }

  handleInputFocus = focused => focused ? this.setState({ y_pos: 60 }) : null;

  render() {
    const { keywords, getItem, searchClicked, id, activeIndex } = this.props;
    return (
      <div 
        className={classes.Draggable}
        style={{top: this.state.y_pos + 'px' }}
        onTouchStart={this.onTouchStart}
      >
        <div className={classes.DragDiv}>
          <div></div>
        </div>
        {activeIndex === 0 &&
        <Fragment>
          <form onSubmit={this.props.onsubmit}>
            <TypeAhead items={keywords} getItem={getItem} searchClicked={searchClicked} onfocus={this.handleInputFocus} />
            <p onClick={this.hideDraggable}>Done</p>
          </form>
          <ShoppingList />  
        </Fragment>}
        {activeIndex === 1 &&
        <TabsSales id={id} />}
      </div>
    )
  }
}

export default Draggable;