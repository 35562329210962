import React from 'react';
import PropTypes from 'prop-types';
import classes from './style/Controls.module.css';

const TripsControls = ({ trip_name, onclick, onchange, showEdit }) => (
  <div className={classes.ControlsContainer}>
    <div className={classes.Date}>
      {!showEdit && <h3>{trip_name}</h3>}
      {showEdit && <input  type="text" value={trip_name} onChange={onchange} />}
      <i className="fa fa-pencil" aria-hidden="true" onClick={() => onclick('edit')}></i>
    </div>
    <div className={classes.Controls}>
      <div onClick={() => onclick('archive')}>
        <i className="fa fa-floppy-o" aria-hidden="true"></i>
        <p>Archive Trip</p>
      </div>
      <div onClick={() => onclick('delete')}>
        <i className="fa fa-trash-o" aria-hidden="true"></i>
        <p>Delete</p>
      </div>
    </div>
  </div>
)

TripsControls.propTypes = {
  onclick: PropTypes.func,
  trip_name: PropTypes.string.isRequired,
}

export default TripsControls
