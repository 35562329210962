import React from 'react';
import classes from './style/ProjectImages.module.css';

const ProjectImages = ({ photos, videos, img_display, onImageClick }) => (
  <div className={classes.ProjectImages}>
    {img_display.type === 'image' && <div className={classes.ImageBox}><img src={img_display.src} alt=""/></div>}
    {img_display.type === 'video' &&
    <iframe
      title={img_display.src}
      // src={`https://youtube.com/embed/ZYoYffXWiwk`} 
      src={`https://youtube.com/embed/${img_display.src}`}
      frameBorder="0" 
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope;" 
      allowFullScreen
    />}
    <div className={classes.OtherImgs}>
      {photos.map((img, index) => (
        <img 
          className={img.photo_id === img_display.id ? classes.Focused : ''} 
          key={index} 
          src={img.photo}
          alt="proj-photos"
          onClick={() => onImageClick('image', img.photo, img.photo_id)}
        />
      ))}
      {videos.map(vid => (
        <div key={vid.video_id}>
          <img 
            // src={`https://img.youtube.com/vi/ZYoYffXWiwk/0.jpg`}
            className={vid.video_id === img_display.id ? classes.Focused : ''} 
            src={`https://img.youtube.com/vi/${vid.video}/0.jpg`}
            alt="youtube-thumbnail"
            onClick={() => onImageClick('video', vid.video, vid.video_id)}
          />
          <i className="fa fa-play-circle" aria-hidden="true"></i>
        </div>
      ))}
    </div>
  </div>
)

export default ProjectImages;