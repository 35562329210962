import React from "react";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { RECAPTCHA_PUBLIC_KEY, IS_WEB_APP } from './constants/constants';

// Components Imports
import Sidebar from './components/layout/Sidebar';
import Store from './components/store/Store';
import Trips from './components/trips/Trips';
import Sales from './components/sales/Sales';
import SalesStore from './components/sales/SalesStore';
import Search from './components/search/Search';
import SearchByBrand from './components/search-by-brand/SearchByBrand';
import SearchByMyLocation from './components/search-by-my-location/SearchByMyLoc';
import FilterByStore from './components/filter-by-storelocation/FilterByStoreLocation';
import SearchByCity from './components/search-by-city/SearchByCity';
import StoreByCity from './components/filter-by-city/StoreByCity';
import Landing from './components/landing/Landing';
import LandingSidebar from './components/landing/LandingNavbar';
import RequestStore from './components/request-store/RequestStore';
import ReportIssue from './components/report-issue/ReportIssue';
import MapperForm from './components/forms/MapperForm';
import Auth from './components/auth/Auth';
import ForgetPassword from './components/auth/ForgetPassword';
import ResetPassword from './components/auth/ResetPassword';
import ChangePassword from './components/auth/ChangePassword';
import Explore from './components/explore/Explore';
import ExploreCreate from './components/explore-create/ExploreCreate';
import CreateProject from './components/create-project/CreateProject';
import ProjectDetails from './components/project-details/ProjectDetails';

const LOCAL_RECAPTCHA_KEY = IS_WEB_APP ? RECAPTCHA_PUBLIC_KEY:null;

export const routes = [
  {
    path: '/',
    exact: true,
    protected: false,
    sidebar: () => <LandingSidebar />,
    main: props => <Landing {...props} />
  },
  {
    path: '/trips',
    exact: false,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => <Trips {...props} />
  },
  {
    path: '/sales',
    exact: true,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => <Sales {...props} />
  },
  {
    path: '/sales/:name',
    exact: true,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => <FilterByStore {...props} urlParam={'sales'} />
  },
  {
    path: '/sales/:name/:id',
    exact: false,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => <SalesStore {...props} />
  },
  {
    path: '/stores',
    exact: true,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => <Search {...props} />
  },
  {
    path: '/stores/brands',
    exact: false,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => <SearchByBrand {...props} />
  },
  {
    path: '/stores/city',
    exact: true,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => <SearchByCity {...props} />
  },
  {
    path: '/stores/proximity',
    exact: false,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => <SearchByMyLocation {...props} />
  },
  {
    path: '/stores/city/:city',
    exact: false,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => <StoreByCity {...props} />
  },
  {
    path: '/stores/:name',
    exact: true,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => <FilterByStore {...props} urlParam={'stores'} />
  },
  {
    path: '/stores/:name/:id',
    exact: false,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => <Store {...props} />
  },
  {
    path: '/login',
    exact: false,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => (
      <GoogleReCaptchaProvider
        reCaptchaKey={LOCAL_RECAPTCHA_KEY}
      >
        <Auth {...props} />
      </GoogleReCaptchaProvider>
    )
  },
  {
    path: '/forget-password',
    exact: true,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => (
      <GoogleReCaptchaProvider
        reCaptchaKey={LOCAL_RECAPTCHA_KEY}
      >
        <ForgetPassword {...props} />
      </GoogleReCaptchaProvider>
    )
  },
  {
    path: '/reset-password',
    exact: false,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => (
      <GoogleReCaptchaProvider
        reCaptchaKey={LOCAL_RECAPTCHA_KEY}
      >
        <ResetPassword {...props} />
      </GoogleReCaptchaProvider>
    )
  },
  {
    path: '/change-password',
    exact: false,
    protected: true,
    sidebar: () => <Sidebar />,
    main: props => (
      <GoogleReCaptchaProvider
        reCaptchaKey={LOCAL_RECAPTCHA_KEY}
      >
        <ChangePassword {...props} />
      </GoogleReCaptchaProvider>
    )
  },
  {
    path: '/request-store',
    exact: true,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => (
      <GoogleReCaptchaProvider
        reCaptchaKey={LOCAL_RECAPTCHA_KEY}
      >
        <RequestStore {...props} />
      </GoogleReCaptchaProvider>
    )
  },
  {
    path: '/report-mistake',
    exact: true,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => (
      <GoogleReCaptchaProvider
        reCaptchaKey={LOCAL_RECAPTCHA_KEY}
      >
        <ReportIssue {...props} />
      </GoogleReCaptchaProvider>
    )
  },
  {
    path: '/mapper-application',
    exact: true,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => (
      <GoogleReCaptchaProvider
        reCaptchaKey={LOCAL_RECAPTCHA_KEY}
      >
        <MapperForm />
      </GoogleReCaptchaProvider>
    )
  },
  {
    path: '/explore/create',
    exact: true,
    protected: true,
    sidebar: () => <Sidebar />,
    main: props => <ExploreCreate {...props} />
  },
  {
    path: '/explore/create/recipe',
    exact: true,
    protected: true,
    sidebar: () => <Sidebar />,
    main: props => (
      <GoogleReCaptchaProvider
        reCaptchaKey={LOCAL_RECAPTCHA_KEY}
      >
        <CreateProject {...props} />
      </GoogleReCaptchaProvider>
    )
  },
  {
    path: '/explore/create/homeproject',
    exact: true,
    protected: true,
    sidebar: () => <Sidebar />,
    main: props => (
      <GoogleReCaptchaProvider
        reCaptchaKey={LOCAL_RECAPTCHA_KEY}
      >
        <CreateProject {...props} />
      </GoogleReCaptchaProvider>
    )
  },
  {
    path: '/explore/create/checklist',
    exact: true,
    protected: true,
    sidebar: () => <Sidebar />,
    main: props => (
      <GoogleReCaptchaProvider
        reCaptchaKey={LOCAL_RECAPTCHA_KEY}
      >
        <CreateProject {...props} />
      </GoogleReCaptchaProvider>
    )
  },
  {
    path: '/explore/:project_type/:project',
    exact: true,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => <ProjectDetails {...props} />
  },
  {
    path: '/explore',
    exact: false,
    protected: false,
    sidebar: () => <Sidebar />,
    main: props => <Explore {...props} />
  },
];