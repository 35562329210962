import React, { Component, createRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { IS_WEB_APP, RECAPTCHA_MOBILE_APP_CODE } from '../../constants/constants';
import NotificationSystem from 'react-notification-system';
import { connect } from 'react-redux';
import { CLEAR_REDIRECT, LOG_IN, SET_SESSION, STORE_MY_LIST } from '../../actions';
import TitleContainer from '../layout/TitleContainer';
import Spinner from '../layout/Spinner';
import FormGroup from '../layout/FormGroup';
import { loginUser, getProjectLists } from '../../api';
import classes from './style/Login.module.css';

class LoginForm extends Component {
  state = {
    email: '',
    password: '',
    isPassVisible: false,
    dataLoading: false,
  }

  notificationSystem = createRef();

  handleSubmit = async (e) => {
    e.preventDefault();

    const { email, password } = this.state;

    if(email === '' || password === ''){
      this.addNotification(
        'Empty Input',
        'Required input field cannot be empty',
        'error',
      );
    } else {
      this.setState({ dataLoading: true });
      var recaptcha = RECAPTCHA_MOBILE_APP_CODE;
      if( IS_WEB_APP ) {
        recaptcha = await this.props.googleReCaptchaProps.executeRecaptcha('login');
      }
      const res = await loginUser({ email, password, recaptcha });
      if(res.data.appuser_id !== null && res.status === 200) {
        // Fetches the user's created and saved lists
        const res_list = await getProjectLists({ mode: 'my-lists' });
        const res_saved = await getProjectLists({ mode: 'my-saves' });
        const data = {
          types: res_list.data.types,
          lists: [...res_list.data.lists, ...res_saved.data.lists]
        }
        this.props.storeMyList(data);
        const userInfo = {
          appuser_id: res.data.appuser_id,
          username: res.data.username,
          first_name: res.data.first_name,
          last_name: res.data.last_name,
          email: res.data.email
        }

        // store session in local storage
        const session_id = res.data.session_id;
        this.props.storeSession(session_id);
        localStorage.setItem('session_id', JSON.stringify(session_id));

        this.addNotification(
          'Login Successful',
          `Welcome back ${res.data.first_name}`,
          'success',
        );
        this.props.setUserLoggedIn(userInfo);
        
        setTimeout(() => {
          if( this.props.redirect === '' ) this.props.history.push('/trips');
          else {
            this.props.history.push(this.props.redirect);
            this.props.clearRedirect();
          }
        }, 1000);
      } else {
        this.addNotification(
          'Error',
          res.data.msg,
          'error',
        );
      }
      this.setState({ dataLoading: false });
    }
    // this.props.history.push('/trips');
  }

  addNotification = (title, message, type) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title,
      message,
      level: type,
      position: 'tc',
      autoDismiss: 3,
    });
  }

  handleChange = e => this.setState({ [e.target.name] : e.target.value });
  
  toggleVisiblePassword = () => this.setState(prevState => ({ isPassVisible: !prevState.isPassVisible }));

  render(){
    const { email, password, isPassVisible, dataLoading } = this.state;

    return (
      <div className={classes.FormContainer}>
        <TitleContainer title="Log In" />
        <NotificationSystem ref={this.notificationSystem} />
        {dataLoading && <div className={classes.DataSpinner}><Spinner/></div>}
        <div className={classes.FormBody}>
          <form id="login-form">
            <FormGroup name="email" label="Email" type="email" placeholder="" required={true} value={email} onchange={this.handleChange} />
            <FormGroup name="password" label="Password" type={isPassVisible ? "text" : "password"} placeholder="" required={true} value={password} onchange={this.handleChange}>
              <i onClick={this.toggleVisiblePassword} className={isPassVisible ? 'fa fa-eye' : `fa fa-eye-slash`} aria-hidden="true"></i>
            </FormGroup>
          </form>
        </div>
        <div className={classes.FormFooter} style={{justifyContent: 'space-between'}}>
          <Link to="/forget-password">Forgot Password?</Link>
          <div>
            <button onClick={() => this.props.history.goBack()}>Cancel</button>
            <button onClick={this.handleSubmit} form="login-form">Log In</button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  redirect: state.authReducer.redirect,
})

const mapDispatchToProps = dispatch => ({
  setUserLoggedIn: userInfo => dispatch({ type: LOG_IN, payload: userInfo }),
  clearRedirect: () => dispatch({ type: CLEAR_REDIRECT }),
  storeMyList: (data) => dispatch({ type: STORE_MY_LIST, payload: data }),
  storeSession: (session) => dispatch({ type: SET_SESSION, payload: session }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withGoogleReCaptcha(LoginForm)));