// Function that fetches and get shelf information for an item in a store
export const getMappingInformation = (storeData, keyword) => {
  let hg = '';
  let aisleNumber = '';
  let categoryColor = '';
  let floor = -1;

  var objs = storeData.mapObjects;
  for( let i=0; i < objs.length; i++ )
  {
    var obj = objs[i];
    let j;
    for(j=0; Array.isArray(obj.keywordsA) && j < obj.keywordsA.length; j++ ) {   
      if( obj.keywordsA[j].toLowerCase() === keyword.toLowerCase() ) {
        hg = {'map_object_id':obj.objectId, 'side':'a'};
        if( aisleNumber !== '' && aisleNumber !== obj.aisleA ) { aisleNumber += '/' + obj.aisleA; }
        else { aisleNumber = obj.aisleA; }
        categoryColor = getCategoryColor(storeData, obj, 'a');
        floor = obj.floor;
        break;
      }
    }
    // If an item is found, break and stop loop
    if(Array.isArray(obj.keywordsA) && j < obj.keywordsA.length) break;

    // Keywords B is the other side of aisle
    for(j=0; Array.isArray(obj.keywordsB) && j < obj.keywordsB.length; j++ ) {
      if( obj.keywordsB[j].toLowerCase() === keyword.toLowerCase() ) {
        hg = {'map_object_id':obj.objectId, 'side':'b'};
        if( aisleNumber !== '' && aisleNumber !== obj.aisleB ) { aisleNumber += '/' + obj.aisleB; }
        else { aisleNumber = obj.aisleB; }
        categoryColor = getCategoryColor(storeData, obj, 'b');
        floor = obj.floor;
        break;
      }
    }
    // If an item is found, break and stop loop
    if(Array.isArray(obj.keywordsB) && j < obj.keywordsB.length) break;
  }
  
  return {
    aisleNumber,
    highlightedArea: hg,
    categoryColor,
    floor
  }
}

const getCategoryColor = (storeData, obj, side) => {
  let color = '';
  Object.keys(storeData.categories).forEach(cat_id => {
    if(side === 'a' && obj.categoryA === cat_id){
      color = storeData.categories[cat_id].colour;
    } else if (side === 'b' && obj.categoryB === cat_id){
      color = storeData.categories[cat_id].colour;
    }
  });
  return color;
}

// Helper function that converts a string into a URL formatted way
// ie. removes all spaces, special characters and converts to lowercase
export const convertToURLFormat = name => {
  return name.replace(/\s/g, "_").replace(/[^a-zA-Z ]/g, "").toLowerCase();
}