import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SHOW_MODAL } from '../../../actions';
import classes from './style/ProjectActions.module.css';

const ProjectActions = ({ list, user_saved, user_liked, likes_count, onAction }) => {
  const dispatch = useDispatch();
  const user_info = useSelector(state => state.authReducer.userInfo);

  const handleClick = () => {
    dispatch({ type: SHOW_MODAL });
    document.body.classList.add('modal-open');
  }

  return (
    <div className={classes.ProjectActions}>
      {user_info && user_info.appuser_id === list.appuser_id &&
      <div className={classes.PrimaryAct}>
        <div onClick={() => onAction('edit')} className={user_liked === 1 ? classes.Selected : ''}>
          <span>Edit</span>
        </div>
        <div onClick={() => onAction('delete')}>
          <span>Delete</span>
        </div>
      </div>}
      <div className={classes.SecondaryAct}>
        <div onClick={() => onAction('like')} className={user_liked === 1 ? classes.Selected : ''}>
          <i className={user_liked === 1 ? 'fa fa-thumbs-up' : 'fa fa-thumbs-o-up' } aria-hidden="true"></i>
          <span>{likes_count}</span>
        </div>
        {user_info && user_info.appuser_id !== list.appuser_id &&
        <div onClick={() => onAction('save')} className={user_saved === 1 ? classes.Selected : ''}>
          <i className={user_saved === 1 ? 'fa fa-heart' : 'fa fa-heart-o'} aria-hidden="true"></i>
          <span>{user_saved === 1 ? "Saved" : "Save"}</span>
        </div>}
        <div onClick={handleClick}>
          <span>Add To Trip</span>
        </div>
      </div>
    </div>
  )
}

export default ProjectActions
