import React from 'react';
import classes from './style/ListContainer.module.css';

const ListContainer = ({ children }) => {
  return (
    <div className={classes.ListContainer}>
      <p>{children}</p>
      <i className="fa fa-angle-right"></i>
    </div>
  )
}

export default ListContainer;