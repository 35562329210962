import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mobile, IsNotMobile } from '../layout/MediaQueries';
import classes from './style/CurrentTrips.module.css';

const ArchiveTripsDisplay = ({ trips }) => {
  return (
    <Fragment>
      <IsNotMobile>
        {trips.map(trip => {
          return (
            <div className={classes.Trips}>
              <div className={classes.TripDetailsArchive}>
                <img src={trip.logo} alt="logo"/>
                <div>
                  <strong>{trip.store_brand} </strong>
                  <IsNotMobile><span>{`. ${trip.store_address}`}</span></IsNotMobile>
                  <Mobile><p>{trip.store_address}</p></Mobile>
                  <p>{`${trip.shopping_list.length} items`}</p>
                </div>
              </div>
            </div>
          )
        })}
      </IsNotMobile>
      <Mobile>
      {trips.map(trip => {
          return (
              <div key={trip.trip_id} className={classes.Trips}>
                <div className={classes.TripDetailsArchive}>
                  <img src={trip.logo} alt="logo"/>
                  <div>
                    <strong>{trip.store_brand} </strong>
                    <IsNotMobile><span>{`. ${trip.store_address}`}</span></IsNotMobile>
                    <Mobile><p>{trip.store_address}</p></Mobile>
                    <p>{`${trip.shopping_list.length} items`}</p>
                  </div>
                </div>
              </div>
          )
        })}
      </Mobile>
    </Fragment>
  )
}

ArchiveTripsDisplay.propTypes = {
  trips: PropTypes.array.isRequired,
}

export default ArchiveTripsDisplay;
