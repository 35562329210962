import { LOG_IN, LOG_OUT, SET_REDIRECT, CLEAR_REDIRECT, SET_SESSION, CLEAR_SESSION } from '../actions';

const initialState = {
  isLoggedIn: false,
  userInfo: null,
  redirect: '',
  session_id: null,
};

const authReducer = ( state = initialState, action ) => {
  switch( action.type ){
    case LOG_IN:
      return {
        ...state,
        isLoggedIn: true,
        userInfo: action.payload,
      };
    case LOG_OUT:
      return { 
        ...state,
        isLoggedIn: false,
        userInfo: {},
      };
    case SET_REDIRECT: 
      return {
        ...state,
        redirect: action.payload,
      }
    case CLEAR_REDIRECT:
      return {
        ...state,
        redirect: '',
      }
    case SET_SESSION:
      return {
        ...state,
        session_id: action.payload,
      }
    case CLEAR_SESSION:
      return {
        ...state,
        session_id: null,
      }
    default:
      return state;
  }
}

export default authReducer;