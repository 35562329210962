import React from 'react';
import PropTypes from 'prop-types';
import classes from './style/RenderSales.module.css';

const RenderSales = ({ data, addRemove, onclick }) => {
  return (
    data.map((item, index) => {
      return (
        <li key={index} className={classes.SalesItem}>
          <div className={classes.SaleImageContainer}>
            <img src={item.photo} alt="sale_item"/>
          </div>
          <h4>{item.title}</h4>
          <p>{item.description}</p>
          {addRemove[index] && 
          <button className={classes.AddToTripBtn} onClick={(e) => onclick(e, 'add', item, index)} >
            + Add
          </button>}
          {!addRemove[index] && 
          <button className={classes.RemoveBtn} onClick={(e) => onclick(e, 'remove', item, index)}>
            - Remove
          </button>}
        </li>
      )
    })
  )
}

RenderSales.propTypes = {
  data: PropTypes.array.isRequired,
  addRemove: PropTypes.array.isRequired,
  onclick: PropTypes.func.isRequired,
}

export default RenderSales;