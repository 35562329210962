import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SET_REDIRECT } from '../../actions';
import { Mobile, IsNotMobile } from '../layout/MediaQueries';
import classes from './style/ExploreHeader.module.css';

const ExploreHeader = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn);

  const handleClick = () => {
    if(!isLoggedIn) {
      alert('You must be logged in to access this page');
      dispatch({ type: SET_REDIRECT, payload: '/explore/create' })
    }
  }
  return (
    <div className={classes.ExploreHeader}>
      <h1>Lists</h1>
      <IsNotMobile>
        <Link to="/explore/create" onClick={handleClick}>+ Create List</Link>
      </IsNotMobile>
      <Mobile>
      <Link to="/explore/create" onClick={handleClick}><p>+</p><p>New</p></Link>
      </Mobile>
    </div>
  )
}


export default ExploreHeader;