import React, { Fragment } from 'react';
import StoreMapImg from '../../img/ld_storemap.png';
import classes from './style/Landing.module.css';
import { IS_WEB_APP } from '../../constants/constants';

const MobileApp = () => {
  return (
    <div className={classes.MobileApp} id={'mobile'} >
      <div className={`ld-container ${classes.MobileWrapper}`}>
        <div className={classes.InnerCircleContainer}>
          <div className={classes.InnerCircle}>
            <img className={classes.InnerMap} src={StoreMapImg} alt="Storemap" />
          </div>
        </div>
        <div className={classes.Description}>
          <h1>spot it on the go</h1>
          <p>For the best experience when you're on the move, download the FREE app now on Android and iOS</p>
          <div>
            {IS_WEB_APP &&
            <Fragment>
              <a href="https://play.google.com/store/apps/details?id=com.spotitmapping.mobileapp" target="_blank" rel="noopener noreferrer">
                <img src={require('../../img/android_logo.png')} alt="android"/>
              </a>
              <a href="https://apps.apple.com/ca/app/spot-it/id1529187675" target="_blank" rel="noopener noreferrer">
              <img src={require('../../img/ios_logo.png')} alt="ios"/>
              </a>
            </Fragment>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileApp;