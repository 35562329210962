import { STORE_DATA, STORE_BRANDS } from '../actions';

const initialState = {
  data: [],
  brands: {}
}

const dataReducer = (state = initialState, action) => {
  switch(action.type){
    case STORE_DATA:
      return { ...state, data: action.data };
    case STORE_BRANDS:
      return { ...state, brands: action.payload }
    default:
      return state;
  }
};

export default dataReducer;