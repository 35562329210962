import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logOutUser } from '../../api';
import { Mobile, IsNotMobile } from './MediaQueries';
import { LOG_OUT, CLEAR_MY_LIST, CLEAR_SESSION } from '../../actions';
import SubMenu from './SubMenu';
import classes from './style/Sidebar.module.css';
import logo from '../../img/spot-it-logo.jpg';

const Sidebar = () => {
  const dispatch = useDispatch();
  const showDownload = useSelector(state => state.downloadReducer.show);
  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn);
  const session_id = useSelector(state => state.authReducer.session_id);
  const userInfo = useSelector(state => state.authReducer.userInfo);
  const showReportMistake = useSelector(state => state.reportMistakeReducer.show);
  const store_id = useSelector(state => state.reportMistakeReducer.store_id);

  const [ settings, setSettings ] = useState(false);

  // Sidebar Items Variable
  const MENU = [
    { index: 0, link: '/trips', icon: 'fa-shopping-cart', name: 'My Trips' },
    { index: 1, link: '/sales', icon: 'fa-percent', name: 'Sales'},
    { index: 2, link: '/stores', icon: 'fa-store', name: 'Stores'},
    { index: 3, link: '/explore', icon: 'fa-map-marker-alt', name: 'Explore'},
    { index: 4, link: '/request-store', icon: 'fa-question-circle', name: 'Request Store'}
  ];

  const handleDownload = (e) => {
    e.preventDefault();
    window.print();
  }

  const handleLogOut = async () => {
    await logOutUser(session_id);
    localStorage.removeItem('session_id');
    dispatch({ type: LOG_OUT });
    dispatch({ type: CLEAR_MY_LIST });
    dispatch({ type: CLEAR_SESSION });
  }

  const handleSubMenu = (key) => {
    setSettings(false);
    switch(key){
      case 'logout':
        return handleLogOut();
      default:
        break;
    }
  }

  return (
    <nav className={classes.Sidebar}>
      <NavLink className={classes.Logo} exact to="/"><img src={logo} alt="Not Unavailable"/></NavLink>
      <div>
        {MENU.map(item => (
        <NavLink
          key={item.index}
          to={item.link}
          activeClassName="selected"
        >
          <i className={`fa ${item.icon} ${classes.Icon}`} aria-hidden="true" /> {item.name}
        </NavLink>
        ))}
        <Mobile>
          {!isLoggedIn
          ? <NavLink
              to="/login"
              activeClassName="selected"
            >
              <i className={`fa fa-sign-in-alt ${classes.Icon}`} aria-hidden="true" /> Login
            </NavLink>
          : <div onClick={() => setSettings(!settings)}>
              <p><i className={`fa fa-user ${classes.Icon}`} aria-hidden="true" /> Account</p>
              {settings && <SubMenu onclick={handleSubMenu} />}
            </div>}
        </Mobile>
      </div>
      <hr />
      {!isLoggedIn ?
      <NavLink to="/login" activeClassName="selected" className={`${classes.LoginContainer} no-link`}><i className={`fa fa-sign-out-alt ${classes.Icon}`} aria-hidden="true"></i> Log In</NavLink> :
      <div 
        className={classes.LoginContainer} 
        onMouseEnter={() => setSettings(true)} 
        onMouseLeave={() => setSettings(false)}
      >
        <p><i className={`fa fa-user-circle ${classes.Icon}`}></i>{`${userInfo.first_name} ${userInfo.last_name}`}</p>
        {settings && <SubMenu onclick={handleSubMenu} />}
      </div>}
      {/* // <p onClick={handleLogOut}><i className={`fa fa-sign-in ${classes.Icon}`} aria-hidden="true"></i>Log Out</p>} */}
      <div className={classes.BtnContainer}>
        { showDownload && <button onClick={handleDownload}>Download</button> }
        { showReportMistake && <IsNotMobile><button><NavLink to={`/report-mistake?store_id=${store_id}`}>Report Mistake</NavLink></button></IsNotMobile> }
      </div>
    </nav>
  )
}

export default Sidebar;