import React, { useRef, useState, Fragment } from 'react';
import classes from './style/RenderMobileSales.module.css';
import Spinner from '../../layout/Spinner';

const RenderMobileSales = ({ data, addRemove, onclick }) => {
  const [ loading, setLoading ] = useState(true);
  const counter = useRef(0);

  const handleImageLoaded = () => {
    counter.current += 1;
    if(counter.current === data.length) setLoading(false);
  }

  return (
    <Fragment>
      {loading && <Spinner />}
      {data.map(( item, index ) => {
        return (
          <div key={index} className={classes.SalesItem} >
            <img src={item.photo} onLoad={handleImageLoaded} alt="sale_item"/>
            <div>
              <h4>{item.title}</h4>
              <p>{item.description}</p>
              {addRemove[index] && 
              <button className={classes.AddToTripBtn} onClick={(e) => onclick(e, 'add', item, index)} >
                + Add
              </button>}
              {!addRemove[index] && 
              <button className={classes.RemoveBtn} onClick={(e) => onclick(e, 'remove', item, index)}>
                - Remove
              </button>}
            </div>
          </div>
        )
      })}
  </Fragment>
  )
}

export default RenderMobileSales;