import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { getStoreList, getBrandsList, getLatitude } from '../../api';
import Spinner from '../layout/Spinner';
import RenderProximityStores from './RenderProximityStores';
import classes from './style/SearchByMyLocation.module.css';
import { STORE_DATA, STORE_BRANDS } from '../../actions';


export class SearchByMyLoc extends Component {
  state = {
    maxDistance: 20000, // 20 km
    stores: null,
    loading: true,
  };

  async componentDidMount() {
    const { imp_data, imp_brands, storeData, storeBrands } = this.props;
    let res_data = [];
    let brands_data = {};

    if( imp_data.length === 0 ){
      const res = await getStoreList();
      res_data = res.data;
      storeData(res_data);
    } else { res_data = imp_data; }

    if( Object.keys(imp_brands).length === 0 ) {
      const res_brands = await getBrandsList();
      brands_data = res_brands.data;
      storeBrands(brands_data);
    } else { brands_data = imp_brands }

    // stores url param into object
    const searchObj = queryString.parse(this.props.location.search);
    if(searchObj.postal_code !== undefined){
      // if a postal code is detected
      const res_coordinates = await getLatitude({ postal_code: searchObj.postal_code });
      const stores = this.getStoreData(res_data, brands_data, res_coordinates.data.latitude, res_coordinates.data.longitude)
      this.setState({
        loading: false,
        stores,
      });
    } else {
      if(navigator.geolocation) {
        // Handler when location is successfully found
        const locSuccess = (location) => {
          const { latitude, longitude } = location.coords;
          const stores = this.getStoreData(res_data, brands_data, latitude, longitude);
          this.setState({
            stores,
            loading: false
          });
        };

        // Handler when an error occurred
        const locError = (error) => {
          alert('Unable to access your location. Please ensure you have location services turned on, and that this application has permissions to use them');
          this.props.history.push('/stores');
        };

        const options = {
          enableHighAccuracy: false,
          timeout: Infinity,
          maximumAge: 86400000
        }

        
        navigator.geolocation.getCurrentPosition(locSuccess, locError, options);
      } else {
        alert('Your Browser is not Supported');
      }
    }
  }

  getStoreData = (data, brands, my_latitude, my_longitude) => {
    let stores = [];
    data.forEach(store => {
      const { city, brand_id } = store;
      if(city !== "" && brands[brand_id]){
        const distance = this.getDistanceInMetres(
          my_latitude,
          my_longitude,
          store.latitude,
          store.longitude
        );
        if(distance <= this.state.maxDistance){
          // construct new object
          const obj = {
            brand_id: store.brand_id,
            city: store.city,
            name: store.name,
            postal_code: store.postal_code,
            store_id: store.store_id,
            street_address: store.street_address,
            logo: brands[store.brand_id].logo,
            brand_name: brands[store.brand_id].name,
            distance_km: distance / 1000,
            distance_miles: (distance / 1000) * 0.62137,
          }
          stores.push(obj);
        }
      }
    });
    // sort the city and then sort by closest
    stores.sort((a,b) => a.distance_km - b.distance_km);
    return stores;
  }
  
  // https://en.wikipedia.org/wiki/Haversine_formula
  // https://stackoverflow.com/questions/639695/how-to-convert-latitude-or-longitude-to-meters
  getDistanceInMetres = (lat1, lon1, lat2, lon2) => {
    var R = 6378.137; // Radius of earth in KM
    var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
    var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
    Math.sin(dLon/2) * Math.sin(dLon/2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c;
    return d * 1000; // meters
  }

  render() {
    const { stores, maxDistance, loading } = this.state;
    const { history } = this.props;
    if(loading) return <Spinner />;
    
    return (
      <div className={`container padded ${classes.ProximityContainer}`}>
        <button className="bt-back" onClick={() => history.goBack()}><i className="fa fa-angle-left"></i> Back</button>
        <h2>Stores within <strong>{`${maxDistance/1000}`}</strong> kms</h2>
        {stores.length === 0 
        ? <p><em>No stores were found in your location</em></p> 
        : (
        <div className={classes.SearchByLocation}>
          <RenderProximityStores stores={stores} />
        </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  imp_data: state.dataReducer.data,
  imp_brands: state.dataReducer.brands,
});

const mapDispatchToProps = dispatch => ({
  storeData: data => dispatch({ type: STORE_DATA, data }),
  storeBrands: brands => dispatch({ type: STORE_BRANDS, payload: brands }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchByMyLoc));
