import { SHOW_MODAL, HIDE_MODAL } from '../actions';

const initialState = {
  displayModal: false,
}

const modalReducer = (state = initialState, action) => {
  switch(action.type){
    case SHOW_MODAL:
      return { ...state, displayModal: true };
    case HIDE_MODAL:
      return { ...state, displayModal: false };
    default:
      return state;
  }
}

export default modalReducer;