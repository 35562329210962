import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getStoreList, getBrandsList } from '../../api';
import { convertToURLFormat } from '../../utils/helpers';
import Spinner from '../layout/Spinner';
import RenderStores from './RenderStores';
import TitleContainer from '../layout/TitleContainer';
import classes from './style/StoreByCity.module.css';
import { STORE_DATA, STORE_BRANDS } from '../../actions';

const StoreByCity = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cityParam = match.params.city;

  const imp_data = useSelector(state => state.dataReducer.data);
  const imp_brands = useSelector(state => state.dataReducer.brands);

  const [ storeObj, setStore ] = useState({});
  const [ loading, setLoading ] = useState(true);
  const [ title, setTitle ] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      let data = [];
      let brandsData = {};

      if(imp_data.length === 0) {
        const res = await getStoreList();
        data = res.data;
        dispatch({ type: STORE_DATA, data });
      } else { data = imp_data; }

      if(Object.keys(imp_brands).length === 0) {
        const res_brands = await getBrandsList();
        brandsData = res_brands.data;
        dispatch({ type: STORE_BRANDS, payload: brandsData });
      } else { brandsData = imp_brands; }

      data.forEach(store => {
        const { city, brand_id } = store;
        const modifiedCity = convertToURLFormat(store.city);
        if( city !== "" && modifiedCity === cityParam && brandsData[brand_id]){
          if(title === '') setTitle(store.city);
          storeObjects(store, brandsData);
        }
      });
      const sortedStores = sortObjects(storeObj);
      setStore(sortedStores);
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const storeObjects = (store, brandsData) => {
    // Object destructuring
    const { 
      name, 
      brand_id, 
      store_id, 
      postal_code, 
      street_address 
    } = store;
    const obj = {
      name: name,
      brand_name: brandsData[brand_id].name,
      short_name: convertToURLFormat(brandsData[brand_id].name),
      id: store_id,
      postal_code: postal_code,
      address: street_address,
      logo: brandsData[store.brand_id].logo
    }
    if(storeObj[brandsData[brand_id].name]) {
      storeObj[brandsData[brand_id].name].push(obj);
    } else {
      storeObj[brandsData[brand_id].name] = [ obj ];
    }
  }

  const sortObjects = (obj) => {
    let newObj = {};
    Object.keys(obj).sort().forEach(store => {
      newObj = {
        ...newObj,
        [store]: obj[store]
      }
    });
    return newObj;
  }


  if(loading) return <Spinner />;

  return (
    <div className="container padded">
      <TitleContainer title={title} />
      <button className="bt-back" onClick={() => history.goBack()}><i className="fa fa-angle-left"></i> Back</button>
      {/* <Link className="bt-back" to="/stores/city"><i className="fa fa-angle-left"></i> Back</Link> */}
      <div className={classes.StoreByCity}>
        { !loading && <RenderStores stores={storeObj} /> }
      </div>
    </div>
  )
}

StoreByCity.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      city: PropTypes.string,
    }).isRequired,
  }).isRequired
}

export default StoreByCity;