import {
  STORE_MY_LIST,
  CLEAR_MY_LIST,
} from '../actions';

const initialState = {
  my_list: {},
}

const listsReducer = ( state = initialState, action ) => {
  switch( action.type ) {
    case STORE_MY_LIST:
      return { ...state, my_list: action.payload };
    case CLEAR_MY_LIST:
      return { ...state, my_list: [] };
    default:
      return state;
  }
}

export default listsReducer;
