import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import { getProjectData, saveProject, likeProject, deleteProject } from '../../api';
import Modal from '../layout/modal/Modal';
import ModalHeader from './modal-trip/ModalHeader';
import AddToTrip from './modal-trip/AddToTrip';
import Header from './project-header/ProjectHeader';
import Description from './ProjectDescription';
import Components from './ProjectComponents';
import Actions from './project-header/ProjectActions';
import Instructions from './ProjectInstructions';
import TitleContainer from '../layout/TitleContainer';
import NoMatch from '../no-match/NoMatchPage';
import Spinner from '../layout/Spinner';
import { Mobile } from '../layout/MediaQueries';
import classes from './style/ProjectDetails.module.css';


export class ProjectDetails extends Component {
  state = {
    valid: true,
    loading: true,
    list_data: {},
    img_display: {},
    user_saved: 0,
    user_liked: 0,
    likes_count: -1,
    items: [],
  }
  // URL Parameters
  project_type_url = this.props.match.params.project_type;
  project_title_url = this.props.match.params.project;

  notificationSystem = createRef()

  // Global for Types Object
  typesObj = {
    1: "recipe",
    2: "home-project",
    3: "checklist",
  }
  
  async componentDidMount() {
    // extract project id from url;
    const list_id = this.project_title_url
                      .substring(
                        this.project_title_url.lastIndexOf('-')+1, 
                        this.project_title_url.length);
    
    // Retrieve data of project
    const res = await getProjectData(list_id, this.props.session_id);
    if(res.status === 200){
      if(this.typesObj[res.data.list.type_id] !== this.project_type_url){
        this.setState({ valid: false });
      } else {
        // If status 200 and url matches
        let img_display = {};
        for(let i=0; i < res.data.photos.length; i++){
          if(res.data.photos[i].is_main === 1){
            img_display = {
              type: 'image',
              src: res.data.photos[i].photo,
              id: res.data.photos[i].image_id,
            };
            break;
          }
        }

        // get items
        let items = res.data.components.map(item => {
          const { keyword, label } = item;
          return {keyword, label};
        });

        this.setState({
          list_data: res.data,
          img_display,
          items,
          user_liked: res.data.list.my_likes,
          user_saved: res.data.list.my_saves,
          likes_count: res.data.list.likes,
        });
      }
    } else {
      this.setState({ valid: false});
    }

    // checks whether the URL is valid
    this.setState({ loading: false });
  }

  // Handler for saving a project
  handleSave = async () => {
    const { list_id, title } = this.state.list_data.list;
    const { user_saved } = this.state;
    // check if the user has already saved this project
    if(user_saved === 1) {
      // if already saved, then will call unsaved
      const res = await saveProject('remove', list_id, this.props.session_id);
      if(res.status === 200) {
        this.addNotification(
          'Success',
          `Successfully unsaved "${title}" from your list`,
          'success',
        );
        this.setState({ user_saved: 0 });
      } else {
        this.addNotification(
          'Error',
          res.data.msg ? res.data.msg : 'An unexpected error occurred',
          'error'
        )
      }
    } else {
      // if project isn't already saved
      const res = await saveProject('add', list_id, this.props.session_id);
      if(res.status === 200) {
        this.addNotification(
          'Success',
          `Successfully saved "${title}" from your list`,
          'success'
        );
        this.setState({ user_saved: 1 })
      } else {
        this.addNotification(
          'Error',
          res.data.msg ? res.data.msg : 'An unexpected error occurred',
          'error'
        )
      }
    }
  }

  handleLike = async () => {
    const { list_id, title } = this.state.list_data.list;
    const { user_liked } = this.state;
    if( !this.props.isLoggedIn ) {
      return this.addNotification(
        'Error',
        'You must be logged in to perform this action',
        'error'
      );
    }
    if( user_liked === 1 ) {
      const res = await likeProject( 'remove', list_id, this.props.session_id );
      if(res.status === 200 ) {
        this.addNotification(
          'Successful',
          `Successfully removed thumbs up from "${title}"`,
          'success',
        );
        this.setState(prevState => ({ 
          user_liked: 0,
          likes_count: prevState.likes_count - 1,
        }));
      } else {
        this.addNotification(
          'Error',
          res.data.msg ? res.data.msg : 'An unexpected error occurred',
          'error'
        );
      }
    } else {
      const res = await likeProject( 'add', list_id, this.props.session_id );
      if( res.status === 200 ) {
        this.addNotification(
          'Successful',
          `Successfully thumbed up "${title}"`,
          'success',
        );
        this.setState(prevState => ({ 
          user_liked: 1,
          likes_count: prevState.likes_count + 1,
        }));
      } else {
        this.addNotification(
          'Error',
          res.data.msg ? res.data.msg : 'An unexpected error occurred',
          'error'
        );
      }
    }
  }

  handleEdit = () => {
    const { list } = this.state.list_data;
    const redirect = `/explore/create/${list.type.toLowerCase().replace(/\s+/g, '')}`;
    const url = `?list_id=${list.list_id}`;
    this.props.history.push(redirect + url);
  }

  handleDelete = async () => {
    const { list } = this.state.list_data;
    const validate = window.confirm(`Are you sure you want to remove the project ${list.title}? This action will permanently delete this project.`);
    if(validate) {
      const res = await deleteProject( list.list_id, this.props.session_id );

      if(res.status === 200) {
        this.props.history.push('/explore')
      } else {
        this.addNotification(
          'An Error Occurred',
          res.data.msg ? res.data.msg : 'Operation could not complete',
          'error'
        )
      }
    }
  }

  handleActions = (action) => {
    switch(action) {
      case 'like':
        return this.handleLike();
      case 'save':
        return this.handleSave();
      case 'edit':
        return this.handleEdit();
      case 'delete':
        return this.handleDelete();
      default: 
        break;
    }
  }

  // Handler for clicking on a sub image in the project's page
  handleImageClick = (type, src, id) => this.setState({ img_display: { type, src, id } });

  addNotification = (title, message, type) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title,
      message,
      level: type,
      position: 'tc',
      autoDismiss: 3,
    })
  }

  render() {
    const {
      list_data,
      loading,
      valid,
      img_display,
      user_liked,
      user_saved,
      likes_count,
      items,
    } = this.state;
    if( loading ) return <Spinner />;
    if( !valid ) return <NoMatch />;
    else return (
      <div className={`container padded ${classes.ProjectDetails}`}>
        <TitleContainer title={list_data.list.list_id.toString()} />
        <NotificationSystem ref={this.notificationSystem} />
        <Modal>
          <ModalHeader />
          <AddToTrip items={items} showNotification={() => this.addNotification('Trip Updated', 'New Trip has been added', 'success')} />
        </Modal>
        <button className="bt-back" onClick={() => this.props.history.goBack()}><i className="fa fa-angle-left"></i> Back</button>
        <Header
          list_data={list_data} 
          types={this.typesObj}
          img_display={img_display} 
          user_liked={user_liked}
          user_saved={user_saved}
          likes_count={likes_count}
          onImageClick={this.handleImageClick}
          onAction={this.handleActions}
        />
        <Description list={list_data.list} />
        <Mobile>
          <Actions
            list={list_data.list}
            user_saved={user_saved}
            user_liked={user_liked}
            likes_count={likes_count}
            onAction={this.handleActions}
          />
        </Mobile>
        <Components
          components={list_data.components}
          header={list_data.list.type_id === 1 ? 'Ingredients' : 'Items'}
        />
        {list_data.list.type !== "Checklist" && <Instructions list={list_data.list} />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  list: state.listsReducer.list_focused,
  isLoggedIn: state.authReducer.isLoggedIn,
  session_id: state.authReducer.session_id
})

export default connect(mapStateToProps)(ProjectDetails);