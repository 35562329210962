import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Mobile, IsNotMobile } from '../layout/MediaQueries';
import classes from './style/CurrentTrips.module.css';

const TripsDisplay = ({ trips, removeTrip }) => {
  return (
    <Fragment>
      <IsNotMobile>
        {trips.map((trip, index) => {
          return (
            <Link
              to={{
                pathname: `/stores/${trip.short_name}/${trip.store_id}`, 
                search: `?id=${trip.trip_id}`}}
                style={{textDecoration: 'none', color: '#000'}}
              key={trip.trip_id}
            >
              <div className={classes.Trips}>
                <div className={classes.TripDetails}>
                  <img src={trip.logo} alt="logo"/>
                  <div>
                    <strong>{trip.store_brand} </strong>
                    <IsNotMobile><span>{`. ${trip.store_address}`}</span></IsNotMobile>
                    <Mobile><p>{`${trip.store_address.substr(0, 20)}....`}</p></Mobile>
                    <p>{`${trip.shopping_list.length} items`}</p>
                  </div>
                </div>
                <button onClick={(e) => removeTrip(e, index)}>Remove</button>
                <div>
                  Edit
                  <i className="fa fa-angle-right" style={{marginLeft: '0.7rem'}}></i>
                </div>
              </div>
            </Link>
          )
        })}
      </IsNotMobile>
      <Mobile>
        <div className={classes.TripBox}>
          {trips.map((trip, index) => {
            return (
                <div key={trip.trip_id} className={classes.Trips}>
                  <div className={classes.TripDetails}>
                    <img src={trip.logo} alt="logo"/>
                    <div>
                      <strong>{trip.store_brand} </strong>
                      <IsNotMobile><span>{`. ${trip.store_address}`}</span></IsNotMobile>
                      <Mobile><p>{`${trip.store_address.substr(0, 20)}....`}</p></Mobile>
                      <p>{`${trip.shopping_list.length} items`}</p>
                    </div>
                  </div>
                  <button onClick={(e) => removeTrip(e, index)}>Remove</button>
                  <div>
                    <Link
                      to={{
                        pathname: `/stores/${trip.short_name}/${trip.store_id}`, 
                        search: `?id=${trip.trip_id}`}}
                        style={{textDecoration: 'none', color: '#000'}}
                    >
                      Edit
                      <i className="fa fa-angle-right" style={{marginLeft: '0.7rem'}}></i>
                    </Link>
                  </div>
                </div>
            )
          })}
          </div>
      </Mobile>
    </Fragment>
  )
}

TripsDisplay.propTypes = {
  trips: PropTypes.array.isRequired,
  removeTrip: PropTypes.func,
}

export default TripsDisplay;
