import { combineReducers } from 'redux';

// Reducers Imports
import storeReducer from './storeReducer';
import shopListReducer from './shopListReducer';
import dataReducer from './dataReducer';
import tripsReducer from './tripsReducer';
import quickAddReducer from './quickAddReducer';
import modalReducer from './modalReducer';
import salesReducer from './salesReducer';
import downloadReducer from './downloadReducer';
import authReducer from './authReducer';
import reportMistakeReducer from './reportMistakeReducer';
import listsReducer from './listsReducer';
import feedExplore from './feedExplore';

const rootReducer = combineReducers({
  storeReducer,
  shopListReducer,
  dataReducer,
  tripsReducer,
  quickAddReducer,
  modalReducer,
  salesReducer,
  downloadReducer,
  authReducer,
  reportMistakeReducer,
  listsReducer,
  feedExplore
});

export default rootReducer;