import React from 'react';
import { Link } from 'react-router-dom';
import classes from './style/Landing.module.css';

const Footer = () => {
  let language = localStorage.getItem('language');
  const refreshWindow = async () => {
    window.setTimeout(function() { window.location.reload(); }, 100);
  }
  return (
    <div className={classes.Footer}>
      <div className={`ld-container`}>
        <p>&copy;2021 SPOT IT INC</p>
        <a href="https://corporate.spotitmapping.com/" target="_blank" rel="noopener noreferrer">Corporate</a>
        <a href="https://www.facebook.com/spotitmapping" target="_blank" rel="noopener noreferrer">Facebook</a>
        <a href="https://www.instagram.com/spotitmapping" target="_blank" rel="noopener noreferrer">Instagram</a>
        <a href="https://www.twitter.com/spotitmapping" target="_blank" rel="noopener noreferrer">Twitter</a>
        <Link to="/request-store">Request a store</Link>
        {language=='en' ? 
          <Link to="?switchLanguage=fr" onClick={refreshWindow}>Francais</Link>
          :<Link to="?switchLanguage=en" onClick={refreshWindow}>English</Link>
        }
      </div>
    </div>
  )
}

export default Footer;