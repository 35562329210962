import React from 'react';
import PropTypes from 'prop-types';
import classes from './style/PastTrips.module.css';

const PastTripsDisplay = ({ archived, onclicked }) => {
  return (
    archived.map((list, index) => {
      return (
        <div key={index} className={classes.PastTrips} onClick={() => onclicked(list, index)}>
          <div>
            <h4>{list[0].archivedOn}</h4>
            <p>{`${list.length} Stores`}</p>
          </div>
          <i className="fa fa-angle-right" style={{marginLeft: '0.7rem'}}></i>
        </div>
      )
    })
  )
}

PastTripsDisplay.propTypes = {
  archived: PropTypes.array.isRequired,
  onclicked: PropTypes.func,
}

export default PastTripsDisplay;
