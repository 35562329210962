import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { IS_WEB_APP, RECAPTCHA_MOBILE_APP_CODE } from '../../constants/constants';
import NotificationSystem from 'react-notification-system';
import TitleContainer from '../layout/TitleContainer';
import FormGroup from '../layout/FormGroup';
import { resetPassword } from '../../api';
import classes from './style/ResetPassword.module.css';

class ResetPassword extends Component {
  notificationSystem = createRef();
  state = {
    password: '',
    password2: '',
    token: '',
    submitted: false,
  };

  componentDidMount() {
    const searchObj = queryString.parse(this.props.location.search);
    this.setState({ token: searchObj.token });
  }

  handleSubmit = async e => {
    e.preventDefault();
    const { password, password2, token } = this.state;
    
    const isMatch = this.isPasswordMatch(password, password2);

    if(!isMatch || (password === '' || password2 === '')) {
      this.addNotification(
        'Password Mismatch',
        'The passwords did not match',
        'error'
      );
    } else if (token === undefined) {
      this.addNotification(
        'Invalid Operation',
        'This Operation is Not Valid!',
        'error',
      );
    } else {
      var recaptcha = RECAPTCHA_MOBILE_APP_CODE;
      if( IS_WEB_APP ) {
        recaptcha = await this.props.googleReCaptchaProps.executeRecaptcha('login');
      }
      const res = await resetPassword({ password, token, recaptcha });

      if( res.data.success && !res.data.error ){
        this.addNotification(
          'Password Change Successfully',
          res.data.msg,
          'success',
        );
        setTimeout(() => {
          this.props.history.push('/login');
        }, 1000);
      } else {
        this.addNotification(
          'An Error Occurred',
          res.data.msg,
          'error',
        );
      }
    }
  }

  // handler for checking if submitted passwords are equal;
  isPasswordMatch = ( pass1, pass2 ) => pass1 === pass2;

  handleChange = e => this.setState({ [e.target.name] : e.target.value });

  // Handler for creating notification
  addNotification = (title, message, type) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title,
      message,
      level: type,
      position: 'tc',
      autoDismiss: 3,
    })
  }

  render(){
    const { password, password2 } = this.state;

    return (
      <div className={'container padded'}>
        <TitleContainer title="Reset Password" />
        <NotificationSystem ref={this.notificationSystem} />
        <div className={classes.ResetPassword}>
          <div className={classes.ModalHeader}>
            <h4>Change Your Password</h4>
          </div>
          <div className={classes.ModalBody}>
            <form id="reset-password-form">
              <FormGroup name="password" type="password" label="New Password" placeholder="" required={true} value={password} onchange={this.handleChange} />
              <FormGroup name="password2" type="password" label="Confirm New Password" placeholder="" required={true} value={password2} onchange={this.handleChange} />
            </form>
          </div>
          <div className={classes.ModalFooter}>
            <button onClick={() => this.props.history.goBack()}>Cancel</button>
            <button onClick={this.handleSubmit} form="reset-password-form">Submit</button>
          </div>
        </div>
      </div>
    )
  }
}

export default withGoogleReCaptcha(withRouter(ResetPassword));