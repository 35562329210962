import React from 'react';
import { Link } from 'react-router-dom';
import classes from './style/StoreHeader.module.css';

const StoreHeader = ({ store_name, tripButton, short_name, mobileAdd, mobileUpdate }) => {
  
  return (
    <div className={classes.StoreHeader}>
      <Link to={`/stores/${short_name}`} className={classes.CloseBtn}></Link>
      <p>{store_name}</p>
      <div
        className={classes.AddToTrip}
        onClick={tripButton ? e => mobileAdd(e) : e => mobileUpdate(e)}
      >
        <i className="fa fa-plus" aria-hidden="true"></i>
        {tripButton && <p>Add to Trip</p>}
        {!tripButton && <p>Update Trip</p>}
      </div>
    </div>
  )
}

export default StoreHeader;