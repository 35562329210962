import React, { useState } from 'react';
import classes from './style/WebsiteBox.module.css';

const WebsiteBox = ({ project, onchange }) => {
  const [ show, setShow ] = useState(false);

  const handleClick = () => setShow(true);
  return (
    <div className={classes.WebsiteBox}>
      <i className="fa fa-address-book-o" aria-hidden="true"></i>
      <div>
        {!show && <span onClick={handleClick}>Add Website</span>}
        {show && <input name="website_url" type="text" onChange={onchange} placeholder="Enter your Website URL" value={project.website_url} />}
      </div>
    </div>
  )
}

export default WebsiteBox;