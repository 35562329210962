import React from 'react';
import PropTypes from 'prop-types';
import classes from './style/PostalCodeContainer.module.css';

const PostalCodeContainer = ({ onclick, postal, onchange }) => {
  return (
    <div style={{width: '100%'}}>
      <h4>Find By Postal Code</h4>
      <div className={classes.PostalCodeContainer}>
        <input maxLength="7" type="text" value={postal} onChange={onchange} />
        <p>Or</p>
        <div className={classes.BtLoc} onClick={onclick}><i className="fa fa-compass" aria-hidden="true"></i>Use my location</div>
      </div>
    </div>
  )
}

PostalCodeContainer.propTypes = {
  onclick: PropTypes.func,
  postal: PropTypes.string.isRequired,
  onchange: PropTypes.func,
}

export default PostalCodeContainer
