import React from 'react';
import classes from './style/StoreTabs.module.css';

const StoreTabs = ({ activeTabs, onclick }) => {
  const TABS = [
    'Shop',
    'Sales',
  ];

  return (
    <div className={classes.StoreTabs}>
      {TABS.map(( tab, index ) => (
        <div
          className={`${classes.Tabs} ${activeTabs === index ? 'activeTab' : ''}`}
          onClick={() => onclick(index)}
          key={index}
        >
          {tab}
        </div>
      ))}
    </div>
  )
}

export default StoreTabs;