import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getBrandsList, getStoreList } from '../../api';
import { STORE_BRANDS, STORE_DATA } from '../../actions';
import Banner from './Banner';
import FindStores from './FindStores';
import Overview from './Overview';
import MapperApplication from './MapperApplication'
import MobileApp from './MobileApp';
import Spinner from '../layout/Spinner';
import Footer from './Footer';

import classes from './style/Landing.module.css';

class Landing extends Component {
  state = {
    postal_code: '',
    loading: true,
  }

  async componentDidMount() {
    // preloads api data on startup
    const { imp_data, imp_brands } = this.props;
    if(imp_data.length === 0) {
      const res = await getStoreList();
      this.props.storeData(res.data);
    }    

    if(Object.keys(imp_brands).length === 0) {
      const res_brands = await getBrandsList();
      this.props.storeBrands(res_brands.data);
    }

    this.setState({
      loading: false,
    })
  }

  // Handler for finding stores according to postal code
  handleSubmit = (e) => {
    e.preventDefault();
    const { postal_code } = this.state;
    const validPostal = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(postal_code);
    if(!validPostal) alert('Invalid Postal Code Format');
    else {
      this.props.history.push(`/stores/proximity?postal_code=${postal_code}`)
    }
  }

  // Handler for finding stores according to location
  handleClick = (e) => {
    e.preventDefault();
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        this.props.history.push('/stores/proximity');
      }, (error) => {
        console.log(error);
        alert('Unable to access your location. Please ensure you have location services turned on, and that this application has permissions to use them');
      },
      {enableHighAccuracy: false, timeout: Infinity, maximumAge: 86400000});
    } else {
      alert('Your Browser is not supported');
    }  
  }

  render() {
    const { postal_code, loading } = this.state;

    if( loading ) return <Spinner />

    return (
      <div className={classes.Landing}>
        <Banner />
        <FindStores 
          postal_code={postal_code}
          onchange={(e) => this.setState({ postal_code: e.target.value })}
          onsubmit={this.handleSubmit}
          onclick={this.handleClick}
        />
        <Overview />
        <MapperApplication />
        <MobileApp />
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  imp_data: state.dataReducer.data,
  imp_brands: state.dataReducer.brands,
});

const mapDispatchToProps = dispatch => ({
  storeData: data => dispatch({ type: STORE_DATA, data }),
  storeBrands: brands => dispatch({ type: STORE_BRANDS, payload: brands }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Landing));