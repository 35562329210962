import React from 'react';
import classes from './style/ProjectComponents.module.css';

const ProjectComponents = ({ components, header }) => (
  <div className={classes.ProjectComponents}>
    <h4>{header}</h4>
    {components.map(item => (
      <p key={item.component_id}>{item.label}</p>
    ))}
  </div>
)

export default ProjectComponents;