import React from 'react';
import PropTypes from 'prop-types';
import classes from './style/LocationContainer.module.css';

const BrandContainer = ({ children }) => {
  return (
    <div className={classes.LocationContainer}>
      <div className={classes.LocationDetails}>
        {children}
      </div>
      <i className="fa fa-angle-right"></i>
    </div>
  )
}

BrandContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BrandContainer;
