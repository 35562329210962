import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Mobile, IsNotMobile } from '../layout/MediaQueries';
import { mapEditor } from './mapGeneral';
import { getMappingInformation } from '../../utils/helpers';
import { fabric } from 'fabric';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../layout/Spinner';
import classes from './style/StoreMap.module.css';
import { 
  CLEAR_INPUT,
  ADD_ITEM,
  RESET_REMOVE_INDEX,
  ADD_SALE_ITEM,
  SET_FLOOR_COUNT,
} from '../../actions';

const StoreMap = ({ data }) => {
  const dispatch = useDispatch();
  const submittedInput = useSelector(state => state.storeReducer.text);
  const shoppingList = useSelector(state => state.shopListReducer.shopping_list);
  const loadedShopList = useSelector(state => state.shopListReducer.loaded_shopping_list);
  const removeIndex = useSelector(state => state.shopListReducer.removeIndex);
  const focusIndex = useSelector(state => state.shopListReducer.focusIndex);
  const saleItemAdded = useSelector(state => state.salesReducer.saleItemAdded);

  const [floorNumber, setFloorNumber] = useState(1);
  const [floors, setFloors] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initPosition, setPosition] = useState(null);

  // replicates ComponentDidMount Lifecycle Method
  // Loads Map and get Keywords of Items
  useEffect(() => {
    // Render Map on Layout View
    renderMap(4);
    dispatch({ type: CLEAR_INPUT });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handler for when Input is submitted;
  useEffect(() => {
    if(submittedInput.length === 0) return;
    let subInput = '';

    if(data.synonyms[submittedInput]){
      subInput = data.synonyms[submittedInput];
    } else {
      subInput = submittedInput;
    }

    let { aisleNumber, highlightedArea, categoryColor, floor } = getMappingInformation(data, subInput);
    
    const item = {
      name: submittedInput,
      keyword: subInput,
      aisle: aisleNumber === "" ? "-" : aisleNumber,
      completed: false,
      highlighted: highlightedArea,
      color: categoryColor,
      floor: floor === -1 ? '-' : floor,
      sales: false,
    }

    // Change floor if floor is detected
    if(floor > 0) {
      handleChangeFloor(floor);
    }
    dispatch({ type: ADD_ITEM, payload: item });
    dispatch({ type: CLEAR_INPUT });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedInput]);

  // Handler for when delete button is pressed;
  useEffect(() => {
    if(removeIndex === -1) return;

    dispatch({ type: RESET_REMOVE_INDEX });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeIndex]);

  // Handler for when shopping list is modified (completed, deleted, added)
  useEffect(() => {
    getHighlightMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoppingList]);

  // Handler for when store map is loaded
  useEffect(() => {
    // in the event store map has changed aisle, the code calls the get map info everytime it re-loads
    loadedShopList.forEach(list => {
      const { aisleNumber, highlightedArea, categoryColor, floor } = getMappingInformation(data, list.keyword);
      const item = {
        name: list.name,
        keyword: list.keyword,
        aisle: aisleNumber === "" ? "-" : aisleNumber,
        completed: list.completed,
        highlighted: highlightedArea,
        color: categoryColor,
        floor: floor === -1 ? '-' : floor,
        sales: list.sales,
      }
      dispatch({ type: ADD_ITEM, payload: item })
      handleChangeFloor(floor);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedShopList]);

  useEffect(() => {
    if(Object.keys(saleItemAdded).length === 0) return;

    let { aisleNumber, highlightedArea, categoryColor, floor } = getMappingInformation(data, saleItemAdded.keyword);
    const item = {
      name: saleItemAdded.title,
      keyword: saleItemAdded.keyword,
      aisle: aisleNumber === "" ? "-" : aisleNumber,
      completed: false,
      highlighted: highlightedArea,
      color: categoryColor,
      floor: floor === -1 ? '-' : floor,
      sales: true
    }
    dispatch({ type: ADD_ITEM, payload: item });
    dispatch({ type: ADD_SALE_ITEM, payload: {} });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleItemAdded]);

  // Handler for when shopping list item is on focus
  useEffect(() => {
    if(focusIndex > -1){
      let arr = [ shoppingList[focusIndex].highlighted ];
      mapEditor.highlightObjects(arr);
    } else {
      getHighlightMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusIndex]);

  // Function that handles how to display the map
  const renderMap = (viewType) => {
    var container = document.querySelector('#mapEditorCanvasContainer');
    var parentContainer = container.parentNode;
    let height = parentContainer.parentNode.offsetHeight + 200;
    let width = window.screen.width + 300;

    if(window.screen.width <= 767) {
      height = parentContainer.parentNode.offsetHeight * 2;
      width = window.screen.width * 3;
    }
    //console.info(width + 'x' + height);

    mapEditor.canvas = new fabric.StaticCanvas('mapEditorCanvas');
    mapEditor.canvas.setWidth(width);
    mapEditor.canvas.setHeight(height);
    mapEditor.setCurrentViewMode(viewType);
    mapEditor.setCategories(data.categories);
    var importRet = mapEditor.import(data.mapObjects);

    var ratioX = width / importRet.maxX;
    var ratioY = height / importRet.maxY;
    var ratio = Math.min(ratioX, ratioY);
    mapEditor.canvas.setZoom(ratio);

    // Set the number of floors
    setFloors(importRet.floors);
    dispatch({ type: SET_FLOOR_COUNT, payload: importRet.floors.length });
    setPosition(getPosition(document.querySelector('#mapEditorCanvasContainer')));
    setLoading(false);
  }

  // function that highlights aisle on map
  const getHighlightMap = () => {
    let arr = [];
    shoppingList.forEach(item => {
      if(!item.completed) arr.push(item.highlighted)
    });

    // Removes duplicate from array of objects
    const uniqueArray = arr.filter((row, index, self) => 
      index === self.findIndex(r => (
        r.map_object_id === row.map_object_id && r.side === row.side
      ))
    )
    mapEditor.highlightObjects(uniqueArray);
  }

  // Handler to when users changes floor
  const handleChangeFloor = floor => {
    setFloorNumber(floor);
    mapEditor.switchToFloor(floor, true);
  }

    // get position of an element according to x and y values
    const getPosition = element => {
      const el = element.getBoundingClientRect();
      return {
        x: el.left,
        y: el.top
      }
    }

  // Function that controls whether to make controller dim or visible
  // Only active when floor number is higher than 1
  const checkScrollPosition = () => {
    if(floors.length > 1) {
      const scrollContainer = document.querySelector('#mapEditorCanvasContainer');
      const position = getPosition(scrollContainer);
      
      if(position.x !== initPosition.x) {
        document.querySelector('#floor-container').style.opacity = "0.3";
      } else {
        document.querySelector('#floor-container').style.opacity = "1.0";
      }
    }
  }

  const renderFloorController = () => {
    if(floors.length > 1) {
      return (
        <ul className={classes.FloorController} id="floor-container">
          {[...floors].reverse().map((floor, index) => (
            <li
              key={index}
              className={floor === floorNumber ? classes.CurrentFloor : ''}
              onClick={() => handleChangeFloor(floor)}
            >
              {floor}
            </li>
          ))}
        </ul>
      )
    } else return;
  }

  return (
    <div className={classes.StoreMap}>
      <IsNotMobile>
        {loading ? <Spinner /> : renderFloorController()}
        <ScrollContainer 
          className={classes.ScrollContainer} 
          nativeMobileScroll={true}
          hideScrollbars={false}
          onStartScroll={checkScrollPosition}
          onEndScroll={checkScrollPosition}
        >
          <div id="mapEditorCanvasContainer" className={classes.Map}>
            <canvas id="mapEditorCanvas"></canvas>
          </div>
        </ScrollContainer>
      </IsNotMobile>
      <Mobile>
      <TransformWrapper
        defaultScale={1.1}
        options={{
          limitToBounds: false,
          transformEnabled: true,
          disabled: false,
          limitToWrapper: false,
        }}
        pan={{
          disabled: false,
          lockAxisX: false,
          lockAxisY: false,
          velocityEqualToMove: true,
          velocity: true,
        }}
        pinch={{ disabled: false }}
        doubleClick={{ disabled: false }}
        wheel={{
          wheelEnabled: true,
          touchPadEnabled: true,
          limitsOnWheel: false,
        }}
      >
        {({
          zoomIn,
          zoomOut,
          resetTransform,
          setDefaultState,
          positionX,
          positionY,
          scale,
          previousScale,
          options: { limitToBounds, transformEnabled, disabled },
          ...rest
        }) => (
          <React.Fragment>
            <div className={classes.Tools}>
              <div onClick={zoomIn}><i className="fa fa-plus" aria-hidden="true"></i></div>
              <div onClick={zoomOut}><i className="fa fa-minus" aria-hidden="true"></i></div>
              <div onClick={resetTransform}><i className="fa fa-times" aria-hidden="true"></i></div>
            </div>
            {loading ? <Spinner /> : renderFloorController() }
            <TransformComponent>
              <div id="mapEditorCanvasContainer" className={classes.Map}>
                <canvas id="mapEditorCanvas"></canvas>
              </div>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    );
      </Mobile>
    </div>
  )
}

StoreMap.propTypes = {
  data: PropTypes.object,
}

export default StoreMap;
