import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Spinner from '../../layout/Spinner';
import { getStoreList, getBrandsList, getStore } from '../../../api';
import { getMappingInformation } from '../../../utils/helpers';
import { convertToURLFormat } from '../../../utils/helpers';
import classes from './style/Trip.module.css';
import { HIDE_MODAL } from '../../../actions';

const AddToTrip = ({ items, showNotification }) => {
  const dispatch = useDispatch();
  const [ loading, setLoading ] = useState(false);
  const [ province, setProvince ] = useState([]);
  const [ provObj, setProvObj ] = useState({});
  const [ cityObj, setCityObj ] = useState({});
  const [ brands, setBrands ] = useState([]);

  const [ provId, setProvId ] = useState(0);
  const [ cityId, setCityId ] = useState(-1);
  const [ brandId, setBrandId ] = useState(-1);

  useEffect(() => {
    const fetchStoreList = async () => {
      setLoading(true);
      let brandsData = {};
      let provinceObj = {};
      const res = await getStoreList();
      const res_brands = await getBrandsList();
      if(res.status === 200 && res_brands.status === 200) {
        brandsData = res_brands.data;
        res.data.forEach(store => {
          const { city, brand_id } = store;
          if(city !== "" && brandsData[brand_id]) {
            const { province, city } = store;

            if(provinceObj[province]){ // if province has already been added
              if(!provinceObj[province].includes(city)) {
                provinceObj[province].push(city);
              }
            } else { // if province does not exist, add province with city set as 1
              provinceObj = {
                ...provinceObj, 
                [province]: [city]
              }
            }
          }
        });

        let data = res.data;
        let cityObj = {};
        Object.keys(provinceObj).forEach(province => {
          provinceObj[province].sort();
          provinceObj[province].forEach(city => {
            data.forEach((store, index) => {
              if(store.city === city && store.brand_id in brandsData) {
                const brands = brandsData[store.brand_id].name;
                if(cityObj[city]) {
                  if(cityObj[city][brands]) {
                    cityObj[city] = {
                      ...cityObj[city],
                      [brands]: [
                        ...cityObj[city][brands],
                        store
                      ]
                    }
                  } else {
                    cityObj[city] = {
                      ...cityObj[city],
                      [brands]: [store]
                    }
                  }
                } else {
                  cityObj[city] = { [brands]: [store] };
                }
                data.splice(index,1);
              }
            });
          })
        });

        setProvince(Object.keys(provinceObj));
        setProvObj(provinceObj);
        setCityObj(cityObj);
        setBrands(brandsData);
        
      } else {
        alert(res.data.msg);
      }
      setLoading(false);
    }
    fetchStoreList();
  }, []);

  const handleProvClick = (index) => {
    if(provId === -1) {
      setProvId(index);
    } else {
      setProvId(-1);
      setCityId(-1);
      setBrandId(-1);
    }
  }

  const handleCityClick = (index) => {
    if(cityId !== index) {
      setCityId(index);
      setBrandId(-1);
    }
    else if(cityId === index) {
      setCityId(-1);
      setBrandId(-1);
    }
  }

  const handleBrandClick = (index) => {
    if(brandId !== index) {
      setBrandId(index);
    }
    else if(brandId === index) {
      setBrandId(-1);
    }
  }

  const handleSubmit = async (store) => {
    const res = await getStore({ store_id: store.store_id });
    if(res.status === 200) {
      let shopping_list = [];
      let projItems = [];
      
      projItems = items.map(item => {
        const { keyword } = item;
        return {...item, keyword: res.data.synonyms[keyword] ? res.data.synonyms[keyword] : keyword }
      });

      projItems.forEach(item => {
        let { aisleNumber, highlightedAreas, categoryColor, floor } = getMappingInformation(res.data, item.keyword);
        if(aisleNumber.length === 0) aisleNumber = "-";
        const obj = {
          name: item.label,
          keyword: item.keyword,
          aisle: aisleNumber,
          completed: false,
          highlighted: highlightedAreas,
          color: categoryColor,
          floor,
          sales: false
        }
        shopping_list.push(obj);
      });

      const tripValid = checkTripExist(store);
      if(!tripValid) {
        const trip = {
          trip_id: uuidv4(),
          store_id: store.store_id,
          store_brand: brands[store.brand_id].name,
          store_name: store.name,
          store_address: `${store.street_address}, ${store.city}, ${store.postal_code}, ${store.province}`,
          logo: brands[store.brand_id].logo,
          short_name: convertToURLFormat(brands[store.brand_id].name),
          shopping_list,
          trip_name: moment().format('MMMM Do, YYYY'),
          added: moment().format('MMMM Do, YYYY'),
          archived: false,
          archivedOn: '',
        }
        if(localStorage.getItem('trips') !== null){
          const trips = JSON.parse(localStorage.getItem('trips'));
          const newTrip = [ ...trips, trip ];
          localStorage.setItem('trips', JSON.stringify(newTrip));
        } else {
          localStorage.setItem('trips', JSON.stringify([ trip ]));
        }        
      } else {
        // if trip already exist
        let newTrip = JSON.parse(localStorage.getItem('trips'));
        let overwrite = true;
        newTrip.forEach(trip => {
          if(trip.store_id === store.store_id){
            overwrite = window.confirm('A trip to this store already exist. Do you want to overwrite it?');
            if(overwrite) {
              trip.shopping_list = shopping_list;
            }
          }
        });
        if(!overwrite) return;
        localStorage.setItem('trips', JSON.stringify(newTrip));
      }
      setCityId(-1);
      setBrandId(-1);
      dispatch({ type: HIDE_MODAL });
      document.body.classList.remove('modal-open');
      showNotification();
    }
  }

  // returns whether an existing trip is found in local storage
  const checkTripExist = (store) => {
    const trips = JSON.parse(localStorage.getItem('trips'));
    let found = false;
    if(trips !== null) {
      trips.forEach(trip => {
        if(trip.store_id === store.store_id) {
          found = true;
        }
      })
    }
    return found;
  }

  if( loading ) return <Spinner />
  return (
    <div className={classes.Trip}>
      <ul className={classes.ProvBox}>
        {province.map((prov, prov_index) => {
          return (
            <Fragment key={prov}>
              <li onClick={() => handleProvClick(prov_index)}>
                <div className={provId === prov_index ? classes.Selected : ''}>{prov}</div>
              </li>
              <ul className={`${provId === prov_index ? classes.Block : classes.Hide} ${classes.CityBox}`}>
                {provObj[prov].map((city, city_index) => (
                  <Fragment key={city}>
                    <li onClick={() => handleCityClick(city_index)}>
                      <div className={cityId === city_index ? classes.Selected : ''}>{city}</div>
                    </li>
                    <ul className={`${cityId === city_index ? classes.Block : classes.Hide} ${classes.BrandBox}`}>
                      {Object.keys(cityObj[city]).map((brand, brand_index) => (
                        <Fragment key={brand}>
                          <li onClick={() => handleBrandClick(brand_index)}>
                            <div className={brandId === brand_index ? classes.Selected : ''}>{brand}</div>
                          </li>
                          <ul className={`${brandId === brand_index ? classes.Block : classes.Hide} ${classes.StoreBox}`}>
                            {cityObj[city][brand].map(store => (
                              <li onClick={() => handleSubmit(store)} key={store.store_id}>
                                <div>{store.name}</div>
                              </li>
                            ))}
                          </ul>
                        </Fragment>
                      ))}
                    </ul>
                  </Fragment>
                ))}
              </ul>
            </Fragment>
          )
        })}
      </ul>
    </div>
  )
}

export default AddToTrip