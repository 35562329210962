import React from 'react';
import classes from './style/TermsBox.module.css';

const PrivacyPolicy = () => {
  return (
    <div className={classes.TermsBox}>
      <div>
        <p>We at Spot It, value everyone’s right to privacy and aim to collect the bare minimum needed to provide the most convenient shopping experience. All information that we collect is in order to for us to provide the best experience possible. This information will also be to aid the retailers we work with to understand their shoppers on a macro level, to help improve the experience they provide to shoppers. This policy is set forth to help our users understand the information we collect, how it is used, protected and what recourse our users may have.</p>
        <p>To clarify,this policy is concerned with the protection of a user’s personal information only. Personal information includes all information that can be used to identify each user as an individual, including a name, income, personal opinion, interests, home contact information, identification numbers, user IDs, passwords, ethnic origin, and age.</p>
        <p>This policy includes descriptions of the following practices:</p>
        <ol>
          <li>The Information We Collect</li>
          <li>How Personal Information is Shared and Used</li>
          <li>How We Manage Information</li>
          <li>How To Contact Us About Privacy &#38; Information</li>
        </ol>
        <p>This is our current policy and it may change at anytime for a multitude of reasons. This may include but not limited to:</p>
        <ul>
          <li>Spot It’s internal working and policies</li>
          <li>Customer Feedback</li>
          <li>Applicable Privacy Legislation</li>
        </ul>
        <p>Privacy and one’s personal information are an important right so please familiarize yourself for our policy and check back regularly to see any changes.</p>
        <p>Please Note: Spot It’s software and products are not intended to be used by any children under the age of 13. We do not knowingly collect any information, whether personal or non personal from any user under the age of the 13. We do not seek outor aim to add any user that is under the age of 13. We will delete any and all information related to a user that is under the age of 13 as soon as it is brought to our attention.</p>
      </div>
      <div>
        <h3>1. The information we collect</h3>
        <p>You may use Spot It’s website and app without having to have an account, though having an account does provide a more comprehensive experience. There will be certain times that Spot It may request information for a user so that we may help find the most convenient stores to shop from. We will disclose when we are requesting information from a user so that they may be constantly informed when as to their privacy rights.</p>
        <h4>Information We Collect Automatically</h4>
        <p>Spot It’s app and website is designed to automatically collect some information such as the location of a device that the software is installed, items added to shopping lists, usage statistics, and other information designed to track a user’s interaction with the Spot It offerings. We use this to adapt our software to user needs, but this may also be shared a retailer so that they may understand how shoppers interact with their properties as well as what products and sales are important to shoppers.</p>
        <p>Anytime a user interacts with Spot It’s software or website, our software may collect the technical information such as a device’s IP address, browser information, referring source, date/time, operating system and cookies (which can be turned off using browser preferences when applicable). This information is once again used to understand our users and how we may be able to serve them better.</p>
        <h4>Making an Account</h4>
        <p>A user does not need to make an account in order to use Spot It’s software but doing so allows Spot It help with not only current shopping trips but future ones as well. Should a user elect to make an account they may do so through an external service or social media sites or a personalized account.</p>
        <p>Should a user use an external source such as a social media network, Spot It may collect information such as a user’s name, email address, gender, age, location information and photo. A user may be able to decide which information will be provide but Spot It reserves the right to require a certain amount of information to verify that a user is genuine. The amount of information sourced from a social media network will depend on a user’s privacy setting with said social media network.</p>
        <p>Should a user which to make a separate personalized account, the user will be required to provide a valid email address and some corresponding information. Spot It reserves the right for a user to then have to validate the email address to ensure the user is genuine.</p>
        <h4>Communicating with Spot It</h4>
        <p>Spot It may reach out to users for in-depth feedback in order to improve our offerings to consumers. Your communication with Spot It may require personal contact information to be shared with Spot It.</p>
        <p>If a user, store or customer reaches out to Spot It, they may need to disclose and consent to providing personal and contact information in order to verify who they are and how Spot It can provide what is being requested.</p>
      </div>
      <div>
        <h3>2. How Information is Shared and Used</h3>
        <p>With any exceptions stated above, Spot It will use the following practices for the use and distribution of any information collected.</p>
        <h4>How Information is Protected</h4>
        <p>Spot It will take every reasonable precaution to protect a user’s personal information along with its continued storage. Access to view or use a user’s personal information is restricted to only Spot It personal and access to an specific user’s information is limited to only when it is absolutely needed in order ensure the user and Spot It is protected from harm and exploitation. Spot It also takes all reasonable precautions to protect any personal information from external threats.</p>
        <p>Spot It will only disclose a user’s personal information in order to meet legal or government regulations, serve or improve our experience to users and also in very rare circumstances for our internal business needs. Spot It does not aim to use personal information that can be used to identify users such as by name in internal operations. Spot It aggregates personal information so that a user cannot be identified by external third parties. These third parties then do not know about any specific user but can use aggregate data to understand and view Spot It and the services it provides.</p>
        <h4>How Information is Shared</h4>
        <p>Spot It shares a user’s information for the previously stated reasons and only when consent has been given. For any third party to view this information, Spot It requires that these parties strictly adhere to policies that protect a user’s information to the same or better standards that Spot It holds itself to.</p>
        <p>Spot It may be compelled to share a user’s information without them knowing or providing consent. This may be done in the context of being required to do so by law or if we have reasonable belief that we need to:</p>
        <ul>
          <li>Comply with all and any legal process</li>
          <li>Protect against misuse of Spot It’s software, services and offerings</li>
          <li>Protect the personal safety of an individual</li>
          <li>Protect and defend Spot It’s rights and properties or the rights and property of a third party</li>
          <li>Allow for the change in ownership of Spot It and to transfer the personal information to a new owner of Spot It – this does not affect the protection of information of this Privacy Policy</li>
        </ul>
        <p>Spot It will take steps to provide users with prior notice of such disclosure, but this may not always be possible or reasonable given the circumstances.</p>
        <h4>How Information is Used</h4>
        <p>Spot It uses the information available to it so that it can improve its offerings, manage the use of its offerings and to deliver information and tools to all relevant parties. Spot It may provide aggregate data to service providers so that they may analyze our offerings. Spot It may also show data to partnered retailers so that they may understand how shoppers interact with their properties, which products are most searched, what promotions are most effective and which products are purchased together. Spot It may also use data to suggest items for a user to find or add. </p>
      </div>
      <div>
        <h3>How We Manage Information</h3>
        <h4>How to Request Information</h4>
        <p>If a user wants to view any information Spot It possesses about that user, they can contact Spot It or its Privacy Team at <a href="mailto:contact@spotitmapping.com">contact@spotitmapping.com</a> and Spot It will be happy to disclose all information that Spot It manages. In addition, California Civil Code Section 1798.83 permits users of our Services that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to our Privacy Team at <a href="mailto:contact@spotitmapping.com">contact@spotitmapping.com.</a></p>
        <h4>How to Request Changes to Information</h4>
        <p>As things change, a user or party may need to update information stored with Spot It. If a user or party contacts our Privacy Team, Spot It will be happy to help make changes or corrections to any relevant information. If this information pertains to billing, it is not Spot It’s responsibility to update such information. Failure to do so may result in suspension of services.</p>
        <h4>How to Request Information Be Destroyed</h4>
        <p>If a user or party desires to remove or destroy information stored with Spot It, Spot It will be happy to aid in that request. Any user or party can contact our Privacy Team, who will take all necessary steps in order to protect a user or party’s right to privacy. Spot It may, however, be required to keep an archival copy of information so that we may comply with relevant laws and legal proceedings. If that is required, Spot It will inform the needed parties and said information will be stored and used at the absolute minimum that is required.</p>
        <h4>How to Opt-Out of Contact with Spot It</h4>
        <p>Users of Spot It’s software have the ability to choose whether they would like to receive optional information and communications with/from Spot It. Doing so may result in a user not receiving all of the benefits or the full experience that Spot It intends for. Spot It will not be held liable for any loss of these benefits or any negative effects regarding Spot It’s services and offerings.</p>
      </div>
      <div>
        <h3>How to Contact Us About Privacy &#38; Information</h3>
        <p>Spot It regards one’s right to privacy as crucially important. Should any one wish to contact us or provide feedback about our policy, please feel free to reach out to our Privacy Team at <a href="mailto:contact@spotitmapping.com">contact@spotitmapping.com</a></p>
      </div>
    </div>
  )
}

export default PrivacyPolicy
