import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ render: Component, ...rest }) => {
  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn);
  return (
    <Route {...rest} render={props => (
      isLoggedIn
      ? <Component {...props} />
      : <Redirect to="/login" />
    )} />
  )
}

export default PrivateRoute;