import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getStore, getBrandsList } from '../../api';
import RenderSales from '../sales/RenderSales';
import RenderMobileSales from './mobile-store/RenderMobileSales';
import Spinner from '../layout/Spinner';
import { IsNotMobile, Mobile } from '../layout/MediaQueries';
import { STORE_SALES_STATE, ADD_SALE_ITEM, REMOVE_ITEM, RESET_REMOVE_INDEX, STORE_BRANDS } from '../../actions';
import classes from './style/TabSales.module.css';

const TabsSales = ({ id }) => {
  const dispatch = useDispatch();
  const [ salesData, setSalesData ] = useState([]);
  const [ addRemove, setAddRemove ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  const shoplist = useSelector(state => state.shopListReducer.shopping_list);
  const imp_brands = useSelector(state => state.dataReducer.brands);

  useEffect(() => {
    const fetchData = async () => {
      let brands_data = {};
      const res = await getStore({ store_id: id });

      if(Object.keys(imp_brands).length === 0){
        const res_brands = await getBrandsList();
        brands_data = res_brands.data;
        dispatch({ type: STORE_BRANDS, payload: brands_data });
      } else { brands_data = imp_brands; }

      const { brand_id } = res.data.store;

      const sales_array = brands_data[brand_id].sales;
      setSalesData(sales_array);
      getButtonStatus(sales_array);
      setLoading(false);

      // let maxHeight = -1;
      // const sales_list_dom = document.querySelectorAll('li h4');
      // for(let i = 0; i < sales_list_dom.length; i++){
      //   const indexHeight = document.querySelectorAll('li h4')[i].offsetHeight;
      //   if(indexHeight > maxHeight) maxHeight = indexHeight;
      // }

      // sales_list_dom.forEach(item => {
      //   item.style.height = `${maxHeight}px`;
      // })
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getButtonStatus = (array) => {
    let statusArray = [];
    array.forEach(item => {
      let status = true; // display add button
      shoplist.forEach(list => {
        if(item.title === list.name){
          status = false;
        }
      });
      statusArray.push(status);
    });
    setAddRemove(statusArray);
  }

  const handleAdd = ( data, index ) => {
    const arr = [...addRemove];
    arr[index] = !arr[index];
    setAddRemove(arr);
    dispatch({ type: STORE_SALES_STATE, payload: arr });
    dispatch({ type: ADD_SALE_ITEM, payload: data });
  }

  const handleRemove = ( data, index ) => {
    let removeIndex = -1;

    shoplist.forEach(( list, index ) => {
      if( list.name === data.title ) {
        removeIndex = index;
      }
    });

    const arr = [...addRemove];
    arr[index] = !arr[index];
    setAddRemove(arr);
    dispatch({ type: STORE_SALES_STATE, payload: arr });
    dispatch({ type: REMOVE_ITEM, id: removeIndex });
    dispatch({ type: RESET_REMOVE_INDEX });
  }

  // Handler that determines whether a add or remove was clicked
  const handleClick = (e, action, data, index) => {
    e.preventDefault();
    switch(action){
      case 'add':
        return handleAdd(data, index);
      case 'remove':
        return handleRemove(data, index);
      default:
        return;
    }
  }

  const displaySales = () => {
    if(salesData.length > 0) {
      return (
        <Fragment>
          <IsNotMobile>
            <ul>
              <RenderSales data={salesData} addRemove={addRemove} onclick={handleClick} />
            </ul>
          </IsNotMobile>
          <Mobile>
            <RenderMobileSales data={salesData} addRemove={addRemove} onclick={handleClick} />
          </Mobile>
        </Fragment>
      )
    } else {
      return <p><em>No sales found at this store</em></p>
    }
  }

  if ( loading ) return <Mobile><Spinner /></Mobile>

  return (
    <Fragment>
      {!loading &&
        <div className={classes.TabSales} id="sales-mobile" style={{ display: 'inline-block', width: '100%' }}>
          {displaySales()}
        </div>
      }
    </Fragment>
  )
}

export default TabsSales;