import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StoreContainer from './StoreContainer';
import classes from './style/FilterByStoreLocation.module.css';

const RenderStores = ({ location, store_name, urlParam }) => (
  Object.keys(location).map(city => (
    <div key={city} className={classes.StoreContainer}>
      <h3>{`${city}, ${location[city][0].province}`}</h3>
      {location[city].map((store, index) => {
        return (
          <Link 
            key={`${city}_${index}`} 
            to={{
              pathname: `/${urlParam}/${store_name}/${store.id}`,
              search: (store.trip_id !== null && urlParam !== 'sales') ? `?id=${store.trip_id}` : null
            }} 
            className={`${classes.StoreWrapper} no-link`}>
            <StoreContainer>
              <h4>{store.name}</h4>
              <p>{store.address}</p>
            </StoreContainer>
          </Link>)
        })}
      </div>
    )
  )
)


RenderStores.propTypes = {
  location: PropTypes.object.isRequired,
  store_name: PropTypes.string.isRequired,
}

export default RenderStores;
