import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { ARCHIVED_CLICKED } from '../../actions';
import CurrentTrips from './CurrentTrips';
import Spinner from './../layout/Spinner';
import PastTrips from './PastTrips';
import Advertisement from './Advertisement';
import TabsContainer from '../layout/TabsContainer';
import TripsHeader from './TripsHeader';
import TitleContainer from '../layout/TitleContainer';
import classes from './style/TripsContainer.module.css';

class Trips extends Component {
  state = {
    trips: [],
    archived: [],
    loading: true,
    show: true,
    display_name: '',
    showEdit: false,
    activeIndex: 0,
  };

  TABS = [ 'Current', 'Past' ];

  async componentDidMount(){
    let trips = [];
    let archived = [];
    let display_name = '';

    if(localStorage.getItem('trips') !== null){
      trips = JSON.parse(localStorage.getItem('trips'));
      display_name = trips[0].trip_name;
    }

    if(localStorage.getItem('archived-list') !== null){
      archived = JSON.parse(localStorage.getItem('archived-list'));
    }

    this.setState({
      trips,
      archived,
      loading: false,
      display_name,
    });
  }

  removeTrip = (e, index) => {
    e.preventDefault();
    let arr = [ ...this.state.trips ];
    const validate = window.confirm('Removing this store will permanently delete it. Are you sure you want to proceed?');
    if(validate) {
      this.setState({ loading: true });
      arr.splice(index, 1);
      this.setState({
        trips: arr
      });
      if(arr.length === 0) localStorage.removeItem('trips');
      else localStorage.setItem('trips', JSON.stringify(arr));
    }
    this.setState({ loading: false });
  }

  // Handler for clicking on Delete button
  deleteAll = () => {
    // delete all trips
    const validate = window.confirm('Do you want to delete this trip?');
    if( validate ){
      this.setState({ trips: [], loading: true });
      localStorage.removeItem('trips');
      // Delete from Database

      this.setState({ loading: false });
    }
  }

  // Handler for clicking on Archive button
  archiveTrip = () => {
    const validate = window.confirm('Do you want to archive this trip?');
    if(validate){
      let toArchive = [ ...this.state.trips ];
      toArchive.forEach((store, index) => {
        toArchive[index] = {
          ...store,
          archivedOn: moment().format('MMMM Do, YYYY'),
          archived: true
        }
      });
      let archived = JSON.parse(localStorage.getItem('archived-list'));
      // creates an object (index of date) of arrays (number of stores)
      if(archived !== null){
        archived.push(toArchive);
      } else {
        archived = [toArchive];
      }
      localStorage.setItem('archived-list', JSON.stringify(archived));
      localStorage.removeItem('trips');
      this.setState({ trips: [], archived });
    }
  }

  deleteArchive = (index) => {
    const validate = window.confirm('Do you want to delete this trip?');
    if(validate){
      this.setState({ loading: true });
      let archived = [...this.state.archived];
      archived.splice(index, 1);
      if( archived.length === 0 ) localStorage.removeItem('archived-list');
      else localStorage.setItem('archived-list', JSON.stringify(archived));
      this.props.archiveClicked(false);
      this.setState({ loading: false, archived });
    }
  }

  reviveTrip = (index) => {
    const validate = window.confirm('Please make sure no trips are active before reviving one');
    if(validate){
      // checks whether a trip is already active
      if(this.state.trips.length > 0){ alert('You already have one trip active. Archive or delete it first'); }
      else {
        this.setState({ loading: true });
        let archived = [...this.state.archived];
        const newTrip = archived[index];
        newTrip.forEach((store, id) => {
          newTrip[id] = {
            ...store,
            archived: false,
            archivedOn: ""
          }
        })
        archived.splice(index, 1);
        // saves new trip and archive in local storage
        if(archived.length === 0) localStorage.removeItem('archived-list');
        else localStorage.setItem('archived-list', JSON.stringify(archived));
        localStorage.setItem('trips', JSON.stringify(newTrip));
        this.props.archiveClicked(false);
        this.setState({ 
          loading: false,
          trips: newTrip,
          archived
        });
      }
    }
  }

  editTripName = () => {
    if(this.state.showEdit) {
      let trips = JSON.parse(localStorage.getItem('trips'));
      trips.forEach(trip => {
        trip.trip_name = this.state.display_name;
      });
      localStorage.setItem('trips', JSON.stringify(trips));
      this.setState({ trips });
    }
    this.setState({ showEdit: !this.state.showEdit });
  }

  handleFormChange = (e) => {
    this.setState({ display_name : e.target.value })
  }

  controlsClicked = (action, index, e) => {
    switch(action){
      case 'delete':
        return this.deleteAll();
      case 'archive':
        return this.archiveTrip();
      case 'edit':
        return this.editTripName();
      case 'delete-archive':
        return this.deleteArchive(index);
      case 'revive':
        return this.reviveTrip(index, e);
      default:
        break;
    }
  }
  
  render(){
    const { trips, archived, loading, show, display_name, showEdit, activeIndex } = this.state;
    if(loading) return <Spinner />
    return (
      <div className={`container padded ${classes.TripsContainer}`}>
        <TitleContainer title="Trips" />
        <div className={classes.MyTrip}>
          <TripsHeader />
          <TabsContainer tabs={this.TABS} active={activeIndex} onclick={id => this.setState({ activeIndex: id })} />
          {!loading && 
          <Fragment>
            {activeIndex === 0 && <CurrentTrips trips={trips} removeTrip={this.removeTrip} onclick={this.controlsClicked} displayName={display_name} onchange={this.handleFormChange} showEdit={showEdit} />}
            {activeIndex === 1 && <PastTrips archived={archived} onclick={this.controlsClicked}/>}
          </Fragment>}
        </div>
        {show && <Advertisement click={() => this.setState({ show: false })} />}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  archiveClicked: (click) => dispatch({ type: ARCHIVED_CLICKED, payload: click })
})


export default connect(null, mapDispatchToProps)(Trips);