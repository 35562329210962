import React from 'react';
import classes from './style/TermsBox.module.css';

const TermsConditions = () => {
  return (
    <div className={classes.TermsBox}>
      <div>
        <p>These Terms &#38; Conditions (the “Agreement”) is a legal agreement between you and Spot It ltd. (“Spot It’) in regards to your experience and use of Spot It’s website, application and services described within this agreement. By using any of Spot It’s website, application and services, you are bound and have agreed to all terms and conditions within this agreement. If you have any questions or concerns, please feel free to contact us at <a href="mailto:contact@spotitmapping.com">contact@spotitmapping.com</a>.</p>
      </div>
      <div>
        <h3>1. Definition</h3>
        <p>1.1	The definition of “Services” is laid out in Section 2</p>
        <p>1.2	“Software” is defined as Spot It’s application and website that use the Services provided by software. </p>
      </div>
      <div>
        <h3>2. The Services</h3>
        <h4>2.1 The Services, are defined as Spot It providing the ability to:</h4>
        <p>a) View the layout map of a store that has been mapped out by Spot It staff to the best of their ability. The user acknowledges that there may be some inaccuracies and cannot hold Spot It responsible for any consequences that may result.</p>
        <p>b) Create a shopping list based off of keywords that Spot It has tagged to a map. The user acknowledges that there may be some inaccuracies and cannot hold Spot It responsible for any consequences that may result.</p>
        <p>c) To navigate a store with the map that Spot It has generated. The user acknowledges that there may be some inaccuracies and cannot hold Spot It responsible for any consequences that may result.</p>
        <p>d) To view sales and/or promotions and their associated locations in a given store. The user acknowledges that there may be some inaccuracies and cannot hold Spot It responsible for any consequences that may result.</p>
        <p>Spot It will collect the necessary locational information from your internet supported device to provide these Services to you. You may also have an account with Spot It, to which Spot It may collect additional information set out in our Privacy Policy (spotitmapping.com/privacy). The Services provided by Spot It and subject to these Terms & Conditions, Spot It’s Privacy Policy (spotitmapping.com/privacy) and the fees charged by Spot It to provide the Services, may be changed at Spot It’s discretion at any time.</p>
        <h4>2.2 In order to utilize the Services, you must:</h4>
        <p>a) Provide current, accurate and complete registration information</p>
        <p>b) When obtaining Services that are subject to a fee, you must provide accurate billing information. You must also provide payment within 60 days of arrival of a bill unless otherwise stated.</p>
        <p>c) You must comply with all terms and conditions specified in this Agreement as well as any applicable law.</p>
        <p>You agree that Spot It may rely on the accuracy of the information you provide to Spot It, and that Spot It will have no liability whatsoever, whether to you or to any third party for any claims or damages resulting from inaccurate information provided to Spot It.</p>
        <h4>2.3 Discontinuing of Services</h4>
        <p>Spot It has the right to suspend, discontinue and change the Services at any time, including content, features, account access as well as any promotion that Spot It provides. Spot It may also impose or restrict access to the Services and/or terminate or limit your access to parts of the Services without liability or notice.</p>
        <h4>2.4 Data Collected</h4>
        <p>You agree and acknowledge that providing the Services involves the collection of information and data while respecting your use of the Services to help Spot It provide better Services to you and to provide aggregate information for analytical purposes while respecting user’s right to privacy using the Services including items added to lists, store selected, brands selected and promotions interacted with. Spot It may provide this information to third parties subject to Spot It’s Privacy Policy.</p>
        <h4>2.5 Third Parties</h4>
        <p>Using the services will result in references to outside third parties, links to third party websites, documents, and incorporates information provided by third parties such as promotions, pricing, store layout information and/or promotional information. All such information, links and references are provided as is and are not under the control of Spot It. Spot It is not liable in any way or responsible for the content including without limitation their accuracy, reliability, or legality. Under no circumstance will Spot It be liable for any loss or damage caused by reliance on information, products or services obtained through the Services, contained in any map through the Services, contained in any promotion in the Services, or obtained through any third party site. You are responsible for abiding and viewing by privacy statements and terms of use posted at any third party platform accessed through the Services and/or any agreements entered into between you and such third party.</p>
        <h4>2.6 Data You Enter</h4>
        <p>You acknowledge and agree that Spot It cannot guarantee data integrity or security, and that it is your sole responsibility to back up any of your information or data (including lists and trips created by you on the Services) that you create, obtain or use in conjunction with the Services. You agree and acknowledge that Spot It will not exercise control over your use of the Services, and that you are responsible for complying with all provisions of the Agreement  and all applicable laws respecting your use of the Services, including obtaining permissions, where applicable, to include data on trips created by you. Spot It reserves the right to monitor and review any material stored in files or programs on its servers (including trips), and has the right to change or remove any material that, in its sole discretion, believes is obscene, unlawful, abusive and/or otherwise objectionable and/or report such material to the appropriate authorities. Spot It also reserves the right to revoke services for any abusive conduct or fraudulent use of the Services and the cease the use of the Services for any period of time in the event that you use the Services or the provision of the Services constitutes, in Spot It’s opinion, a threat to Spot It’s or any third party’s computer systems, networks, files, intellectual property or other data.</p>
      </div>
      <div>
        <h3>3.	Use of the Services</h3>
        <h4>3.1 Restrictions of Use. You agree that:</h4>
        <p>a) You will only use the Services in accordance with this Agreement as well as any applicable law</p>
        <p>b) You are solely responsible for all activities and use of the Services</p>
        <p>c) You will not use the Services for any commercial purposes or for the benefit of a third party</p>
        <p>d) You represent and warrant that you have the right, ability and authority to accept this Agreement, to use the Services and will provide accurate information</p>
        <p>e) You will not use the Services in any way to attempt to commit a crime or facilitate the commission of a crime or any other illegal acts. You will not use the Services to transmit harassing, abusive, libelous, illegal or deceptive messages or information.</p>
        <p>f) You will not alter, delete, modify or interfere with or compromise the Services or Software in any way or any content available through the Services</p>
        <p>g) You will cooperate with Spot It and provide information needed by Spot It to assist Spot It and/or any relevant authorities in investigating or determining whether there has been a breach of this Agreement or any applicable law</p>
        <p>You agree not to violate any applicable laws, the rights of others, or any component of the Services.</p>
        <h4>3.2 Equipment</h4>
        <p>The selection, implementation, installation, maintenance and performance of any equipment, software and services used in the use of the Services (except for Spot It’s networks and computer systems) is your sole responsibility including but not limited to your computer(s), mobile device(s) and ISP.</p>
        <h4>3.3 Permission to Use Your Submissions</h4>
        <p>You agree that any suggestions, store requests, bug reports, store errors or any other communications with respect to the functionality of the Services that you send to Spot It by any means (a “Submission”), is considered non-confidential and may be used in part or in whole by Spot It and/or any third party without compensation or liability to you for any purpose whatsoever. By providing Spot It a Submission, whether for inclusion on the Services or for other purposes, you represent and warrant that you have all necessary permissions to grant the licenses herein to Spot It. You thus grant Spot It, its successors and affiliates a perpetual, worldwide, royalty-free, non-exclusive, sublicensable and transferable license to use, copy, distribute, transmit, modify, develop, prepare derivative works of any Submission on, through or in connection to the Services. This section of the Agreement does not apply to personal information that is subject to Spot It’s Privacy Policy (spotitmapping.com/privacy).</p>
      </div>
      <div>
        <h3>4.	The Software</h3>
        <h4>4.1 License Grant</h4>
        <p>Spot It grants you a no-exclusive, personal, revocable, non-transferable license to use the Software in accordance with the terms of this Agreement, for the sole purpose of using the Services for your direct benefit.</p>
        <h4>4.2 Restrictions on Use. You shall not:</h4>
        <p>a) Reproduce, copy, modify, improve, alter, enhance, reverse engineer, disassemble, deconstruct, translate, decrypt, reverse compile or convert into human readable form any part or whole of the Software</p>
        <p>b) Assign, license, distribute, sublicense, lease, rent, transfer, sell or otherwise provide access to the Software, in whole or in part, to any third party on a temporary or permanent basis</p>
        <p>c) Deface, remove, cover or otherwise obscure any proprietary rights identification or notice on the Software</p>
        <p>d) Copy any written materials associated to any portion of the Software unless authorized specifically in writing by Spot It</p>
        <p>e) Use the software in any way inconsistent with the standard parameters for use of the Services</p>
        <p>For the purposes of this section “copy or reproduce” shall not include copying of instructions and statements of the Software during its execution when used in accordance with and for the purposes described in any user documentation or in the course of creating backups of the device or system on which the Software is installed, in accordance with industry standard practices.</p>
        <h4>4.2 Legal Compliance</h4>
        <p>You attest and warrant that (i) you are not located in a country that is subject to a Canadian or U.S. Government embargo, or that has been designated by the Canadian or U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any Canadian or U.S. Government list of prohibited or restricted parties.</p>
      </div>
      <div>
        <h3>5.	Privacy </h3>
        <h4>5.1 Personal Information</h4>
        <p>All personal information will be handled in accordance with Spot It’s Privacy Policy (spotitmapping.com/privacy). Spot It reserves the right to disclose at any time, any information that it deems necessary to abide by any applicable law, regulation, legal process or governmental request.</p>
      </div>
      <div>
        <h3>6.	Intellectual Property</h3>
        <h4>6.1 Ownership of Intellectual Property</h4>
        <p>You agree that the Software is owned by Spot It, who retains all title, interest and right therein and is protected by Canadian, U.S. and international copyright laws. You do not acquire any intellectual property or any other proprietary rights under this agreement., including but not limited to any title,  right or interest in and to patents, copyrights, trade-marks, industrial designs, confidential information, or trade secrets, whether registered or unregistered, relating to the Services, the Software, or any party thereof. Your only rights to the Services, the Software and any part of them shall be those rights expressly licensed or granted to you under this agreement. Any rights not expressly stated or granted under this agreement are reserved.</p>
      </div>
      <div>
        <h3>7.	Disclaimer of Warranties</h3>
        <h4>7.1 No Warranty</h4>
        <p>The Services and Software are provided as is without warranty or conditions of any kind, whether express or implied, including, but not limited to, the implied conditions or warranties of merchantability and fitness for a specific purpose, title, non-infringement, security or accuracy. Spot It assumes absolutely no responsibility for any errors, omissions or inaccuracies whatsoever in the information provided through the Software or Services. Under absolutely no circumstances will Spot It be liable for any loss or damage caused by reliance on information obtained through the Software or Services. It is your explicit responsibility to determine the accuracy, usefulness and completeness of any information provided, and use of the Software and Services is solely at your own risk. Spot It has no fiduciary duty or special relationship to you, and you acknowledge that Spot It has no control over, and no responsibility to take any action regarding any acts or omissions taken by you or any retailer or advertiser using the services, including, but not limited to: a) what material you access through the Services, or b) how you analyze, interpret or use information through the Services, or c) the products, promotions or services offered by any retailer or advertiser providing a store layout or promotions through the Services, or d) the information you input or the lists or trips you create. Spot It makes no representations or warranties whatsoever concerning any information obtained through the Services, and Spot It will not be liable or responsible for the copyright compliance, accuracy, legality or decency of any material contained in or accessed through the Services. There are jurisdictions that do not allow the exclusions of certain warranties, so the above exclusions or limitations may not apply to you.</p>
      </div>
      <div>
        <h3>8.	Liability Limitations</h3>
        <h4>8.1 No Liability</h4>
        <p>Other than for the limited direct damages specified above, to the maximum extent permitted by law, in no event shall Spot It be liable for any damages whatsoever (including, without limitation, special, incidental, indirect, exemplary, consequential or punitive damages) whether or not such damages were unforeseen or foreseen including without limitation the use of or inability to use the Software or Services, even if Spot It has been advised of the possibility of such damages.</p>
        <h4>8.2 Damages</h4>
        <p>The sole type of damages that can be recovered against Spot It arising from or related to this Agreement including but not limited in relation to the provision of the Services, shall be your direct damages, if any, caused by gross negligence or wilful misconduct on the part of Spot It. Without limiting the foregoing, your only right in relation to any problems or dissatisfaction with the Software is to uninstall and terminate use of the Software.</p>
      </div>
      <div>
        <h3>9.	Indemnification</h3>
        <h4>9.1 Responsibility for Claims</h4>
        <p>You hereby agree to indemnify, defend and hold harmless Spot It, its subsidiaries, parents, affiliates, employees and officers, including costs and attorney fees, from any demand or claim made by any third party due to or arising out of: a) your access to the Software, the Services, and an content obtained through the Software or Services, b) your misuse of the Software and/or the Services, c) any beach of this Agreement by you, d) your violation of any third-party rights or any applicable laws. Spot It reserves the right, at your expense, to assume the exclusive defence and control of any issue otherwise subject to indemnification by you, in which event you will cooperate with Spot It, at your expense, in asserting any defenses.</p>
      </div>
      <div>
        <h3>10.	Termination</h3>
        <h4>10.1 Termination of Agreement</h4>
        <p>You may terminate this Agreement at any time with notice to Spot It. Upon any termination of this Agreement for any reason by you or by Spot It, you must cease all use of the Software and Services and destroy and/or permanently delete all copies of the Software in your possession.</p>
        <h4>10.2 Termination by Spot It</h4>
        <p>Spot It reserves the right, in its complete and sole discretion, to revoke your ability to use the Services at any time with or without cause, for any or no reason. Upon termination, your right to use the Services and/or access any content through the Services will immediately end. All restrictions contained in this Agreement shall survive the termination of your right to use the Services.</p>
        <h4>10.3 Spot It’s Rights on Termination</h4>
        <p>Without creating limitations on other remedies, Spot It may warn others of your actions, limit your activity, issue warnings, temporarily or permanently suspend, terminate your account and refuse to provide Services to you if: a) you breach this agreement; b) Spot It is unable to authenticate or verify any information you provide; or c) Spot It believes that your use may cause financial loss or legal liability for Spot It, you or others.</p>
        <h4>10.4 No Exclusive Remedy</h4>
        <p>The previously described actions are not Spot It’s exclusive remedies and Spot It reserves the right to take any other equitable, legal or technical action it determines appropriate in the circumstances. Spot It will not be liable for any damage caused by the termination of this agreement.</p>
      </div>
      <div>
        <h3>11.	Third Party Application Stores, Services and Servers</h3>
        <h4>11.1 Third Party Application Stores and Their Terms and Conditions</h4>
        <p>If you have obtained the Software through a third party application store, such as Google Play store or Apple App Store (a “Third Party App Store”), you are subject to all terms and conditions involved by such third parties in relation to your download of the Software and/or use of the Services as applicable. Such Third Party App store my have additional rights or impose additional restrictions, in relation to your use of the Services or Software, such as the removal of the Software from your devices. It is your sole responsibility to ensure that you are aware of and in compliance of the terms and conditions imposed by the Third Party App Store from which you downloaded the Software. To the extent that this Agreement is less restrictive than the usage rules or terms and conditions for software downloaded from a Third Party App Store, or is direct conflict with, the Third Party App Store terms of service or like agreement as they relate to any obligations to the Third Party App store and Spot It, the usage rules of the Third Party App Store shall apply.</p>
        <h4>11.2 Third Party Liability</h4>
        <p>Even though the Software is made available to you through Third Party App Stores, you acknowledge that this Agreement is concluded solely between you and Spot It, and not with the Third Party App Store, and the Third Party App Store shall have no responsibility whatsoever in regards to the Software, Services and the related content (including, but not limited in relation to the distribution, sale or use thereof, or the performance or non-performance thereof).</p>
        <h4>11.3 Third Party Beneficiaries </h4>
        <p>Any Third Party Application Store from whom you have downloaded the software is a third party beneficiary of this Agreement, and, upon your acceptance of terms and conditions of this Agreement, such Third Party App Store will have the right (and will have deemed to have accepted the right) to enforce this Agreement against you as a third party beneficiary thereof. </p>
        <h4>11.4 Third Party, Extra-Jurisdictional Servers</h4>
        <p>You hereby acknowledge that, due to the inherent nature of the Services, information or data uploaded to the Services may be hosted on servers residing in jurisdictions outside of Canada, over which Spot It has no direct control. By using the Services, you acknowledge that its data and information may become, during the period that they are hosted on such servers, subject to the laws of the jurisdiction in which the servers reside and/or to the terms of agreement respecting the hosting of data on such servers. Although Spot It has made all reasonable efforts to verify that its agreements with such server providers are reasonably protective of any data, you acknowledge that Spot It is not liable for any acts or omissions of third parties in relation to such servers and the data stored on them. You therefore release Spot It from any and all liability for any governmental or third party action taken in such jurisdictions with respect to such data and/or the servers on which such data resides.</p>
      </div>
      <div>
        <h3>12.	General Provisions</h3>
        <h4>12.1 Whole Agreement</h4>
        <p>This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and cancels and supersedes any prior interactions and agreements between the parties. There are no undertakings, representations, provisions, agreements, or associated agreements between the parties other than as set out in this Agreement.</p>
        <h4>12.2 Governing Law</h4>
        <p>This Agreement is governed by the laws of the Province of Ontario, Canada, without regard to conflict of laws provisions, and you agree to submit to the exclusive jurisdiction of the courts located in the Province of Ontario, Canada. The parties expressly agree that neither the United Nations Convention on Contracts for the International Sale of Goods nor the Uniform Computer Information Transactions Act shall apply to this Agreement or to any contracts relating to goods or services obtained through this site.</p>
        <h4>12.3 Severability</h4>
        <p>If any part of this Agreement is determines to be unenforceable or invalid pursuant to applicable law including, but not limited to, the liability limitations and warranty disclaimers set forth above, then the unenforceable or invalid provision will be deemed superseded by a valid, enforceable provision that most closely matches the intended original provision and the rest of this Agreement shall continue in full force.</p>
        <h4>12.4 Intended Language</h4>
        <p>It is the express will of the parties that this Agreement and all related documents have been drawn up in English. C'est la volonté expresse des parties que la présente convention ainsi que les documents qui s'y rattachent soient rédigés en anglais.</p>
        <h4>12.5 Age Restriction</h4>
        <p>If you reside in a jurisdiction which prohibits or restricts the use of internet-based services according to age, or which limits the ability to enter into agreements such as this Agreement according to age and you are under such a jurisdiction and under such age limit, you may not enter into this Agreement and install, download or use the Software. Thus, if you reside in a jurisdiction where it is forbidden to use Software or Services of this type, you may not enter into this Agreement and install, download or use the Software. By entering into this Agreement, you explicitly state that you have verified in the jurisdiction you reside in if your use of the Service and Software is permitted.</p>
        <h4>12.6 Electronic Agreement</h4>
        <p>You acknowledge that by selecting the “I Agree” button (or any other link or button that is designated by Spot It to show your acceptance of this Agreement and/or your agreement to install, download, or otherwise access and use the Software), you entering into a legally binding contract. You thus agree to the use of electronic communication in order to enter into contracts and create other records and to the electronic delivery of any policies, notices and records of transactions initiated or completed through the Software. Furthermore, you hereby waive any requirements or rights under any laws or regulations in any jurisdiction which require an original (non-electronic) signature, delivery or retention of non-electronic records, to the extent permitted under any applicable mandatory law. A printed version of this Agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this Agreement to the same extent and subject to the same conditions as any other business documents and records originally generated and maintained in printed form.</p>
      </div>
      <div>
        <h3>13.	Contact</h3>
        <h4>13.1 Contact</h4>
        <p>If you have any questions regarding this Agreement, or if you have any complaints, questions claims or other legal concerns relating to Spot It or its business, please contact Spot It via email at <a href="mailto:contact@spotitmapping.com">contact@spotitmapping.com</a></p>
      </div>
    </div>
  )
}

export default TermsConditions
