import { useMediaQuery } from 'react-responsive';

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minDeviceWidth: 992 });
  return isDesktop ? children : null; 
}

export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minDeviceWidth: 768, maxDeviceWidth: 991 })
  return isTablet ? children : null
}

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxDeviceWidth: 767 })
  return isMobile ? children : null
}

export const IsNotMobile = ({ children }) => {
  const isNotMobile = useMediaQuery({ minDeviceWidth: 768 })
  return isNotMobile ? children : null
}
